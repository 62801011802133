import React, { useEffect, useState } from "react";
import Select from "react-select";
import { translate } from "react-switch-lang";
const KPSearchSelect = ({
  options = {},
  setValue = () => { },
  style = {},
  loading = false,
  t,
}) => {
  const basicStyle = {
    container: () => ({
      width: "100%",
    }),
  };
  const basicValue = {
    value: "",
    label: t("selectPlaceholder"),
  };

  const [optionsState, setOptionsState] = useState({});
  const [selectedValue, setSelectedValue] = useState(basicValue);
  const [styles] = useState({
    ...basicStyle,
    ...style,
  });

  useEffect(() => {
    setOptionsState(options);
    setSelectedValue(basicValue);
    setValue({});
    //eslint-disable-next-line
  }, [options]);

  return (
    <Select
      styles={styles}
      options={optionsState}
      onChange={(value) => {
        setValue(value);
        setSelectedValue(value);
      }}
      isLoading={loading}
      key={options.value}
      value={selectedValue}
      className="theme"
    ></Select>
  );
};

export default translate(KPSearchSelect);
