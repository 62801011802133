import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { translate } from "react-switch-lang";
import { withRouter, useParams } from "react-router-dom";
import { updateRole, getRole } from "../../services/role.service";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import Loader from "../partials/Loader";

const EditRole = (props) => {
  const { roleId } = useParams();
  const [editData, setEditData] = useState([]);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    props.history.push(`${process.env.PUBLIC_URL}/roles`);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      name: event.target.name.value,
      id: editData.id,
    };
    (async () => {
      const { ok } = await updateRole(requestBody);
      if (ok) {
        setTimeout(() => {
          toast.success(props.t("roleUpdatedSuccessfuly"));
        }, 200);
        props.history.push(`${process.env.PUBLIC_URL}/roles`);
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  const getData = () => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getRole(roleId);
      if (ok) {
        setEditData(body);
      }

      setLoading(false);
    })();
  };
  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("roles")}
        title={props.t("editRole")}
        backTo="true"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {loading ? (
                <Loader />
              ) : (
                <Form className="form theme-form" onSubmit={handleSubmit}>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("name")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              type="text"
                              name="name"
                              defaultValue={editData.name}
                              required
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="submit-footer">
                    <Button color="primary" className="mr-1" type="submit">
                      {props.t("save")}
                    </Button>
                    <Button color="light" type="reset" onClick={handleCancel}>
                      {props.t("cancel")}
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(EditRole));
