import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";


import {
  getNeighborhoodById,
  updateNeighborhood,
} from "../../services/neighborhood.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import { setNeighborhoodIdToEdit } from "../../store/slices/neighborhoodSlice";
import Loader from "../partials/Loader";

const EditNeighborhood = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshTable = () => {},
  languages = [],
  cities = [],
}) => {
  const [neighborhood, setNeighborhood] = useState({});

  // Store
  const dispatch = useDispatch();
  const neighborhoodId = useSelector((state) => state.neighborhood.id);

  // UI/UX
  const [loading, setLoading] = useState(false);

  // Generate Select input with the name of the available cities
  let citiesSelector;
  if (cities.length > 0) {
    if (Object.keys(neighborhood).length > 0) {
      citiesSelector = (
        <FormGroup className="row d-flex align-items-center">
          <Label htmlFor="city" className="col-4 mb-0">
            {t("city")}
          </Label>
          <Input
            type="select"
            id="city"
            name="city"
            className="col-6 mb-0"
            defaultValue={neighborhood.city.localizedName
              .toLowerCase()
              .replace(/\s+/g, "_")}
          >
            {cities.map((city, index) => (
              // We turn city name to underscored lowercase
              <option
                value={city.name.toLowerCase().replace(/\s+/g, "_")}
                key={index}
              >
                {city.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      );
    } else {
      citiesSelector = <div></div>;
    }
  } else {
    citiesSelector = <div></div>;
  }
  //END

  // Dynamic generation for Name inputs (Equal to the number of published languages)
  let languagesForm;
  if (languages.length > 0) {
    if (Object.keys(neighborhood).length > 0) {
      languages.forEach((language) => {
        const value = neighborhood.translations.filter(
          (translation) => translation.languageId === language.id
        )[0];

        language.value = value ? value.name : "";
      });
    }
    languagesForm = languages.map((language) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.code}
        >
          <Label htmlFor={`name_${language.code}`} className="col-4 mb-0">{`${t(
            "name"
          )} (${language.code.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            defaultValue={language.value}
            required
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <div></div>;
  }
  //END

  const editNeighborhood = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const translations = [];

    languages.forEach((language, index) => {
      translations.push({
        name: event.target[index + 1].value,
        languageId: language.id,
      });
    });

    const cityId = cities.filter(
      (city) =>
        city.name.toLowerCase().replace(/\s+/g, "_") === event.target[0].value
    )[0].id;

    const requestBody = {
      translations: translations,
      id: neighborhood.id,
      cityId: cityId,
    };

    (async () => {
      const { ok } = await updateNeighborhood(requestBody);

      if (ok) {
        toast.success(t("editNeighborhoodSuccess"));
      }

      toggleRefreshTable();
      dispatch(setGlobalLoading({ status: false }));
      dispatch(setNeighborhoodIdToEdit({ id: null }));
    })();
  };

  useEffect(() => {
    if (neighborhoodId) {
      (async () => {
        setLoading(true);
        const { body, ok } = await getNeighborhoodById(neighborhoodId);
        if (ok) {
          setNeighborhood(body);
        }
        setLoading(false);
      })();
    }
  }, [neighborhoodId]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("editNeighborhood")}</ModalHeader>
        {loading ? (
          <Loader />
        ) : (
          <Form className="form theme-form" onSubmit={editNeighborhood}>
            <ModalBody>
              {citiesSelector}
              {languagesForm}
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button color="primary" type="submit">
                {t("save")}
              </Button>
              <Button color="light" onClick={toggleModal} type="button">
                {t("cancel")}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default translate(EditNeighborhood);
