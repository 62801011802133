import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.SETTINGS_ENDPOINT;


export const getSettings = async () => {
    const response = await Get(`${_endpoint}/Get`, true);
    return response;
};

export const UpdateVisitPeriod = async (requestBody) => {
    const response = await Post(`${_endpoint}/UpdateVisitPeriod`, requestBody, true);
    return response;
};
