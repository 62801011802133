import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { getBranchById, updateBranch } from "../../services/branch.service";
import { setBranchIdToEdit } from "../../store/slices/branchSlice";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import Loader from "../partials/Loader";

const EditClient = ({
  isOpen,
  toggleModal = () => { },
  t,
  toggleRefreshTable = () => { },
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [code, setCode] = useState("");
  const [smsBranchName, setSmsBranchName] = useState("");

  // Store
  const branchId = useSelector((state) => state.branch.id);
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);

  const editClientSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));

    const requestBody = {
      id: branchId,
      name,
      address,
      code,
      smsBranchName
    };

    (async () => {
      const { ok } = await updateBranch(requestBody);

      if (ok) {
        toast.success(t("branchEditedSuccess"));
        toggleRefreshTable();
      }

      dispatch(setGlobalLoading({ status: false }));
      dispatch(setBranchIdToEdit({ id: null }));
    })();
  };

  const isSaveButtonDisabled = () => {
    return name.length > 0
      && code.length > 0
      && smsBranchName?.length > 0;
  };

  useEffect(() => {
    if (branchId) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getBranchById(branchId);
        if (ok) {
          setName(body.name);

          setAddress(body.address);
          setCode(body.code);
          setSmsBranchName(body.smsBranchName);
        }
        setLoading(false);
      })();
    }
  }, [branchId]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("editBranch")}</ModalHeader>
        {loading ? (
          <Loader />
        ) : (
          <Form className="form theme-form" onSubmit={editClientSubmit}>
            <ModalBody>
              <FormGroup className="row d-flex align-items-center">
                <Label htmlFor="name" className="col-4 mb-0 label-required">
                  {t("name")}
                </Label>
                <Input
                  className="col-6 mb-0 "
                  type="text"
                  id="name"
                  name="name"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  value={name}
                  required
                ></Input>
              </FormGroup>
              <FormGroup className="row d-flex align-items-center">
                <Label htmlFor="address" className="col-4 mb-0 ">
                  {t("address")}
                </Label>
                <Input
                  className="col-6 mb-0"
                  type="text"
                  id="address"
                  name="address"
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  value={address}
                ></Input>
              </FormGroup>
              <FormGroup className="row d-flex align-items-center">
                <Label htmlFor="code" className="col-4 mb-0 label-required">
                  {t("code")}
                </Label>
                <Input
                  className="col-6 mb-0 form-control"
                  type="text"
                  id="code"
                  name="code"
                  onChange={(event) => {
                    setCode(event.target.value);
                  }}
                  value={code}
                ></Input>
              </FormGroup>
              <FormGroup className="row d-flex align-items-center">
                <Label htmlFor="smsBranchName" className="col-4 mb-0 label-required">
                  {t("smsBranchName")}
                </Label>
                <Input
                  className="col-6 mb-0 form-control"
                  type="text"
                  id="smsBranchName"
                  name="smsBranchName"
                  onChange={(event) => {
                    setSmsBranchName(event.target.value);
                  }}
                  value={smsBranchName}
                  required
                ></Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                color="primary"
                type="submit"
                disabled={!isSaveButtonDisabled()}
              >
                {t("save")}
              </Button>
              <Button color="light" onClick={toggleModal} type="button">
                {t("cancel")}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default translate(EditClient);
