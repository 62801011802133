const config = {
  //ENDPOINTS
  CLIENT_ENDPOINT: "api/admin/v1/Client",
  BRANCH_ENDPOINT: "api/admin/v1/Branch",
  CITY_ENDPOINT: "api/admin/v1/City",
  LANGUAGE_ENDPOINT: "api/admin/v1/Language",
  NEIGHBORHOOD_ENDPOINT: "api/admin/v1/Neighborhood",
  ORDER_ENDPOINT: "api/admin/v1/Order",
  PRODUCT_ENDPOINT: "api/admin/v1/Product",
  LOOKUP_ENDPOINT: "api/admin/v1/Lookup",
  USER_ENDPOINT: "api/admin/v1/User",
  ACCOUNT_ENDPOINT: "api/admin/v1/Account",
  ROLE_ENDPOINT: "api/admin/v1/Role",
  CLAIM_ENDPOINT: "api/admin/v1/Claim",
  SETTINGS_ENDPOINT: "api/admin/v1/Settings",
  AUTH_ENDPOINT: "api/v1/Auth",
  LOCALE_RESOURCE_ENDPOINT: "api/admin/v1/LocaleResource",
  MAINTENANCE_ENDPOINT: "api/admin/v1/MaintenanceRequest",
  MAINTENANCE_REQUEST_PUBLIC_ENDPOINT: "api/v1/MaintenanceRequest",
  MESSAGE_TEMPLATE_ENDPOINT: "api/admin/v1/MessageTemplate",
  ACCOUNT_PUBLIC_ENDPOINT: "api/v1/AccountPublic",
  LOGS_ENDPOINT: "api/admin/v1/Logs",
  //LOCALSTORAGE KEYS
  TOKEN_KEY: "token",
  LANGUAGE_KEY: "lang",
  CLIENT_KEY: "client_to_edit",
  BRANCH_KEY: "branch_to_edit",
  CITY_KEY: "city_to_edit",
  NEIGHBORHOOD_KEY: "neighborhood_to_edit",
  THEME_KEY: "theme",
  DIRECTION_KEY: "dir",
  ORDER_KEY: "order_to_edit",
  CLAIMS_KEY: "newClaims",

  //CONSTANT
  PAGINATION_PAGE_SIZE: 10,
  NUMBERS_WITH_TWO_DECIMALS_REGEX: /^\d{1,}(\.\d{0,2})?$/,

  customerRedirection: {
    HasNoOrders: `${process.env.PUBLIC_URL}/customer/orders/create`,
    HasOneOrder: `${process.env.PUBLIC_URL}/customer/orders`,
    HasManyOrders: `${process.env.PUBLIC_URL}/customer/home`
  },
};

const dev = {
  API_URL: "https://localhost:44340",
};

const acc = {
  API_URL: "https://api-acceptance.barkakitchens.com",
};

const loc = {
  API_URL: "https://localhost:44340/",
};

const pro = {
  API_URL: "https://api.barkakitchens.com",
};

const test = {
  API_URL: "https://kpm-api-test.trendy-tech.co",
};

const envs = {
  dev,
  acc,
  loc,
  pro,
  test,
};

const environment = {
  ...config,
  ...envs.dev,
  ...envs[`${process.env.REACT_APP_ENV}`],
};

export default environment;
