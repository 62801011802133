import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import environment from "../../environment";
import Breadcrumb from "../../layout/breadcrumb";
import { getPublishedLanguages } from "../../services/language.service";
import { setMessageTemplateIdToEdit } from "../../store/slices/messageTemplateSlice";
import { KPTable } from "../partials/KPTable";
import EditMessageTemplate from "./EditMessageTemplate";

const ViewMessageTemplates = ({ t }) => {
  const [languages, setLanguages] = useState([]);

  // Modals
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const toggleEditModal = () => {
    setIsEditModalOpen((prev) => !prev);
  };

  // UI/UX
  const [refreshTable, setRefreshTable] = useState(false);

  const toggleRefreshTable = () => {
    setRefreshTable((prev) => !prev);
  };

  // store
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: t("name"),
      selector: "localizedName",
      center: true,
    },
    {
      cell: (row, cell) => (
        <Button
          color="light"
          onClick={() => {
            dispatch(setMessageTemplateIdToEdit({ id: row.id }));
            toggleEditModal();
          }}
        >
          {t("edit")}
        </Button>
      ),
      name: t("actions"),
      center: true,
      width: "30ch",
    },
  ];
  useEffect(() => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        setLanguages(body);
      }
    })();
  }, []);

  return (
    <>
      <EditMessageTemplate
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        toggleRefreshData={toggleRefreshTable}
        languages={languages}
      />
      <Breadcrumb
        parent={t("messageTemplates")}
        title={t("viewMessageTemplates")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <KPTable
                  endpoint={`${environment.MESSAGE_TEMPLATE_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                  requestOptions={{
                    templateType: "SMS",
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(ViewMessageTemplates);
