import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getClientById, updateClient } from "../../services/client.service";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../partials/Loader";

import { setGlobalLoading } from "../../store/slices/loadingSlice";

const EditClient = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshTable = () => {},
}) => {
  const id = useSelector((state) => state.client.id);

  // Form Control
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // End Form Control

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(true);
  const languageKey = useSelector((state) => state.language.key);

  const editClientSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      id,
      firstName,
      sureName: surName,
      phoneNumber,
    };

    (async () => {
      const { ok } = await updateClient(requestBody);

      if (ok) {
        toast.success(t("clientEditedSuccessfully"));
        toggleRefreshTable();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  const isSaveButtonDisabled = () => {
    return (
      firstName.length > 0 &&
      surName.length > 0 &&
      phoneNumber.length > 0 &&
      !phoneNumber.includes("_")
    );
  };

  useEffect(() => {
    if (id !== "" && id) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getClientById(id);
        if (ok) {
          setFirstName(body.firstName);
          setPhoneNumber(body.phoneNumber);
          setSurName(body.sureName);
        }
        setLoading(false);
      })();
    }
  }, [id]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("editClient")}</ModalHeader>
        <Form className="form theme-form" onSubmit={editClientSubmit}>
          <ModalBody>
            {loading ? (
              <Loader loadingMessage={t("loadingMessage")}></Loader>
            ) : (
              <>
                <FormGroup className="row d-flex align-items-center">
                  <Label htmlFor="firstName" className="col-4 mb-0">
                    {t("firstName")}
                  </Label>
                  <Input
                    className="col-7 mb-0"
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    value={firstName}
                  ></Input>
                </FormGroup>
                <FormGroup className="row d-flex align-items-center">
                  <Label htmlFor="sureName" className="col-4 mb-0">
                    {t("surname")}
                  </Label>
                  <Input
                    className="col-7 mb-0"
                    type="text"
                    id="sureName"
                    name="sureName"
                    onChange={(event) => {
                      setSurName(event.target.value);
                    }}
                    value={surName}
                  ></Input>
                </FormGroup>
                <FormGroup className="row d-flex align-items-center">
                  <Label htmlFor="phoneNumber" className="col-4 mb-0">
                    {t("phoneNumber")}
                  </Label>
                  <InputMask
                    style={{
                      direction: "ltr",
                      textAlign: languageKey === "ar" ? "end" : "start",
                    }}
                    className="col-7 mb-0 form-control"
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={(event) => {
                      setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                    }}
                    mask="05 999 999 99"
                    value={phoneNumber}
                  ></InputMask>
                </FormGroup>
              </>
            )}
          </ModalBody>
          {!loading && (
            <ModalFooter className="justify-content-center">
              <Button
                color="primary"
                type="submit"
                disabled={!isSaveButtonDisabled()}
              >
                {t("save")}
              </Button>
              <Button color="light" onClick={toggleModal} type="button">
                {t("cancel")}
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default translate(EditClient);
