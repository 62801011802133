import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.CLIENT_ENDPOINT;


export const getAllClients = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};

export const getClientById = async (clientId) => {
  const response = await Get(`${_endpoint}/Get?id=${clientId}`, true);
  return response;
};

export const createClient = async (body) => {
  const response = await Post(`${_endpoint}/Create`, body, true);
  return response;
};

export const updateClient = async (body) => {
  const response = await Post(`${_endpoint}/Update`, body, true);
  return response;
};

export const deleteClient = async (clientId) => {
  const requestBody = {
    id: clientId,
  };
  const response = await Post(`${_endpoint}/Delete`, requestBody, true);
  return response;
};
