import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createBranch } from "../../services/branch.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const CreateBranch = ({
  isOpen,
  toggleModal = () => { },
  t,
  setSubmitting = () => { },
  toggleRefreshTable = () => { },
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [code, setCode] = useState("");
  const [smsBranchName, setSmsBranchName] = useState("");

  // Store
  const dispatch = useDispatch();

  const addNewBranch = (e) => {
    e.preventDefault();
    toggleModal();
    setSubmitting(true);
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      name,
      address,
      code,
      smsBranchName,
    };

    (async () => {
      const { ok } = await createBranch(requestBody);

      if (ok) {
        toast.success(t("branchCreatedSuccess"));
        toggleRefreshTable();
      }

      // Clear Values After Submission
      setName("");
      setAddress("");
      setCode("");
      setSmsBranchName("");

      setSubmitting(false);
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  const isSaveButtonDisabled = () => {
    return name.length > 0
      && code.length > 0
      && smsBranchName.length > 0;
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("createNewBranch")}</ModalHeader>
        <Form className="form theme-form" onSubmit={addNewBranch}>
          <ModalBody>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="name" className="col-4 mb-0 label-required">
                {t("name")}
              </Label>
              <Input
                className="col-6 mb-0"
                type="text"
                id="name"
                name="name"
                onChange={(event) => {
                  setName(event.target.value);
                }}
                value={name}
              ></Input>
            </FormGroup>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="address" className="col-4 mb-0">
                {t("address")}
              </Label>
              <Input
                className="col-6 mb-0"
                type="text"
                id="address"
                name="address"
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
                value={address}
              ></Input>
            </FormGroup>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="code" className="col-4 mb-0 label-required">
                {t("code")}
              </Label>
              <Input
                className="col-6 mb-0 form-control"
                type="text"
                id="code"
                name="code"
                onChange={(event) => {
                  setCode(event.target.value);
                }}
                value={code}
                required
              ></Input>
            </FormGroup>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="smsBranchName" className="col-4 mb-0 label-required">
                {t("smsBranchName")}
              </Label>
              <Input
                className="col-6 mb-0 form-control"
                type="text"
                id="smsBranchName"
                name="smsBranchName"
                onChange={(event) => {
                  setSmsBranchName(event.target.value);
                }}
                value={smsBranchName}
                required
              ></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              color="primary"
              type="submit"
              disabled={!isSaveButtonDisabled()}
            >
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default translate(CreateBranch);
