import React from "react";
import { t } from "react-switch-lang";
import Dropzone from "react-dropzone-uploader";
import { useState } from "react";
import { createMaintenanceRequest } from "../../../services/maintenance-public.service";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

const AddMaintenanceRequest = ({
  isOpen = false,
  refreshData = () => {},
  setIsOpen = () => {},
}) => {
  const [details, setDetails] = useState("");
  const [files, setFiles] = useState([]);

  // Routing
  const { id } = useParams();

  // Store
  const dispatch = useDispatch();

  // Submit
  const handleSubmit = (event) => {
    const formData = new FormData();
    event.preventDefault();
    

    if (id !== "") {
      formData.append("orderId", id);
    }

    if (files.length > 0) {
      files.forEach((item) => {
        formData.append(`requestIssueImages`, item.file, item.file.name);
      });
    }
    if (details !== "") {
      formData.append("requestIssueDetails", details);
    }

    (async () => {
      dispatch(setGlobalLoading({ status: true }));
      const { ok } = await createMaintenanceRequest(formData);
      if (ok) {
        toast.success(t("requestSuccess"));
      }
      setDetails("");
      setIsOpen(false);
      setFiles([]);
      refreshData();
      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  const sendButtonDisabled = () => {
    return files.length === 0;
  };
  return (
    <>
      {isOpen && (
        <div className="maintenance-form">
          <form onSubmit={handleSubmit}>
            <h3 className="title">{t("addMaintenanceRequest")}</h3>
            <div className="input-group">
              <label htmlFor="requestIssueDetails">{t("problemDetails")}</label>
              <textarea
                name="requestIssueDetails"
                id="requestIssueDetails"
                value={details}
                onChange={(event) => setDetails(event.target.value)}
                className="theme-secondary-background"
              />
            </div>
            <div className="input-group">
              <label htmlFor="requestIssueImages" className="label-required">
                {t("problemImages")}
              </label>
              <div className="light-dzu">
              <Dropzone
                accept="image/*"
                inputContent={t("dropContent")}
                inputWithFilesContent={t("add")}
                onChangeStatus={(fileWithMeta, status, filesWithMeta) => {
                  if (status === "done" || status === "removed") {
                    setFiles(filesWithMeta);
                  }
                  if (status === "removed")
                    if (filesWithMeta.length === 1) {
                      setFiles([]);
                    }
                }}
              />
              </div>
            </div>
            <Button color="light"  disabled={sendButtonDisabled()}>
              {t("send")}
            </Button>
          </form>
        </div>
      )}
    </>
  );
};

export default AddMaintenanceRequest;
