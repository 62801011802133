import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { Row } from "reactstrap";
import RightHeader from "../layout/header/rightbar";
import { LoadingModal } from "../KPComponents/partials/LoadingModal";

const CustomerApp = ({ children }) => {
  return (
    <Fragment>
      <LoadingModal />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div
          className="page-header"
          style={{ marginRight: "0", marginLeft: "0", width: "100%" }}
        >
          <Row className="header-wrapper m-0 py-0 d-flex justify-content-between hei-80">
            <div className="float-left">
              <div className="logo-icon-wrapper">
                <img
                  className="img-fluid"
                  src={require("../assets/images/logo/secondLogo.jpeg")}
                  alt="Logo"
                  width={44}
                  height={41}
                />
              </div>
            </div>

            <RightHeader customer />
          </Row>
        </div>
        <div className="page-body-wrapper">
          <div
            className="page-body"
            style={{ marginRight: "0", marginLeft: "0" }}
          >
            {children}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CustomerApp;
