import React from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { approveOrderForPreparation } from "../../../services/order.service";

import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const ApproveOrderForPreprationModal = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshData = () => {},
  orderId,
}) => {
  // Store
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      id: orderId,
    };
    (async () => {
      const { ok } = await approveOrderForPreparation(requestBody);
      if (ok) {
        setTimeout(() => {
          toast.success(t("ApproveOrderForPreparationDone"));
        }, 200);
        toggleRefreshData();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("ApproveOrderForPrepration")}</ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <ModalBody>
            <p>{t("ApproveOrderForPreprationMsg")}</p>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="danger" type="submit">
              {t("confirm")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(ApproveOrderForPreprationModal));
