import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import environment from "../../environment";
import Breadcrumb from "../../layout/breadcrumb";

import { KPTable } from "../partials/KPTable";

const ViewEmailTemplates = ({ t, history }) => {
  // UI/UX
  // eslint-disable-next-line
  const [refreshTable, setRefreshTable] = useState(false);

  const tableColumns = [
    {
      name: t("name"),
      selector: "localizedName",
      center: true,
    },
    {
      cell: (row, cell) => (
        <Button
          color="light"
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/email-templates/edit/${row.id}`);
          }}
        >
          {t("edit")}
        </Button>
      ),
      name: t("actions"),
      center: true,
      width: "30ch",
    },
  ];

  return (
    <>
      <Breadcrumb
        parent={t("emailTemplates")}
        title={t("viewEmailTemplates")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <KPTable
                  endpoint={`${environment.MESSAGE_TEMPLATE_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                  requestOptions={{
                    templateType: "email",
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(translate(ViewEmailTemplates));
