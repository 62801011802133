import React from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Container,
    Card,
    Form,
    CardBody
} from 'reactstrap';
import TimePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization';


const BasicRequestInformation = (props) => {
    const data = props.data;
    const clientData = props.data.client || {};

    let statusComponent;
    if (data.requestStatusId === 'Rejected')
        statusComponent =
            <h5><span class="badge badge-danger">
                <p style={{ fontSize: "10px" }}>
                    {data.requestStatusLocalized || props.t("notEntered")}
                </p>
            </span>
            </h5>

    else if (data.requestStatusId === 'UpdatedRequest ' || data.requestStatusId === 'InstallingProcessing')
        statusComponent =
            <h5><span class="badge badge-warning">
                <p style={{ fontSize: "10px" }}>
                    {data.requestStatusLocalized || props.t("notEntered")}
                </p>
            </span>
            </h5>
    else statusComponent =
        <h5><span class="badge badge-success">
            <p style={{ fontSize: "10px" }}>
                {data.requestStatusLocalized || props.t("notEntered")}
            </p>
        </span>
        </h5>

    return (
        <div>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form className="form theme-form">
                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("city")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.cityLocalized || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("neighborhood")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.neighborhoodLocalized ||
                                                            props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("measureTechnician")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.measureTechnician || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>

                                        </Col>
                                        <Col sm="6">
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("visitDate")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.visitDate || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("visitTime")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.visitTime || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row mb-0">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("requestIssueDetails")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {data.requestIssueDetails || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true}>
                <Card>
                    <Form className="form theme-form">
                        <CardBody>
                            <Row >
                                <Col sm="6">
                                    <FormGroup className="row ">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("orderStatus")} </Label>
                                        <Col sm="8 d-flex">
                                            {statusComponent}
                                        </Col>

                                    </FormGroup>
                                </Col>
                                {
                                    (data.orderStatus === 'Rejected') ?
                                        (
                                            <FormGroup className="row ">
                                                <Label className="col-sm-4 col-form-label">
                                                    {props.t("rejectionReason")} </Label>
                                                <Col sm="8 d-flex">
                                                    <Label className="col-form-label font-weight-bold text-danger">
                                                        {data.rejectionReason || props.t("notEntered")}
                                                    </Label>
                                                </Col>

                                            </FormGroup>
                                        )
                                        :
                                        (<div></div>)
                                }
                                {/* <Col sm="6">
                                    <FormGroup className="row">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("createdBy")}
                                        </Label>
                                        <Col sm="8">
                                            <Label className="col-form-label font-weight-bold">
                                                {data.branchManager || props.t("notEntered")}
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup className="row">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("contractNumber")}
                                        </Label>
                                        <Col sm="8">
                                            <Label className="col-form-label font-weight-bold">
                                                {data.contractNumber || props.t("notEntered")}
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("customerName")}
                                        </Label>
                                        <Col sm="8">
                                            <Label className="col-form-label font-weight-bold">
                                                {clientData.firstName + " " + clientData.sureName || props.t("notEntered")}
                                            </Label>
                                        </Col>
                                    </FormGroup>

                                </Col>
                                <Col sm="6">
                                    <FormGroup className="row">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("orderDate")}
                                        </Label>
                                        <Col sm="8">
                                            <Label className="col-form-label font-weight-bold">
                                                {(data.createdOn !== null) ?
                                                    (data.createdOn.substring(0, data.createdOn.length - 3) + " " +
                                                        TimePeriodLocalization(data.createdOn.substring(data.createdOn.length - 2, data.createdOn.length))
                                                    )
                                                    :
                                                    (props.t("notEntered")
                                                    )}
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-4 col-form-label">
                                            {props.t("phoneNumber")}
                                        </Label>
                                        <Col sm="8">
                                            <Label className="col-form-label font-weight-bold ">
                                                {clientData.phoneNumber || props.t("notEntered")}
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            </Container>
        </div>
    )
}

export default withRouter(translate(BasicRequestInformation));