import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    id: "",
  },
  reducers: {
    setEditOrderId: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

export const { setEditOrderId } = orderSlice.actions;

export default orderSlice.reducer;
