import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import { getPublishedLanguages } from "../../services/language.service";
import { createLookup } from "../../services/lookup.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const CreateLookup = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshTable = () => {},
}) => {
  const [languages, setLanguages] = useState([]);
  const [constantType, setConstantType] = useState("");
  const [translations, setTranslations] = useState([]);
  const typeOptions = [
    {
      type: "MarbleCompany",
      name: t("MarbleCompany"),
    },
  ];

  // Store
  const dispatch = useDispatch();

  let languagesForm;
  if (languages.length > 0) {
    languagesForm = languages.map((language, index) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.culture}
        >
          <Label
            htmlFor={`name_${language.culture}`}
            className="col-4 mb-0"
          >{`${t("name")} (${language.culture.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.culture}`}
            name={language.culture}
            onChange={(event) =>
              handleTranslationChange(event.target.value, index)
            }
            required
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <></>;
  }

  const handleTranslationChange = (value, index) => {
    setTranslations((prev) => {
      prev[index].name = value;
      return [...prev];
    });
  };

  const addLookup = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    toggleModal();

    const requestBody = {
      type: constantType,
      sorting: 1,
      isAvailable: event.target.isAvailable.checked,
      translations,
    };

    (async () => {
      const { ok } = await createLookup(requestBody);
      if (ok) {
        toast.success(t("constantCreateSuccess"));
        toggleRefreshTable();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        setLanguages(body);
        setTranslations(
          body.map((language) => ({
            languageId: language.id,
            name: "",
          }))
        );
      }
    })();
  }, []);

  const isSaveButtonDisabled = () => {
    if (constantType === "") {
      return true;
    }
    for (const index in translations) {
      if (!translations[index].name || translations[index].name === "") {
        return true;
      }
    }
    return false;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader>{t("addConstant")}</ModalHeader>
      <Form className="form theme-form" onSubmit={addLookup}>
        <ModalBody>
          <FormGroup className="row d-flex align-items-center">
            <Label htmlFor="type" className="col-4 mb-0">
              {t("lookupType")}
            </Label>
            <Typeahead
              className="col-6 mb-0 p-0"
              id="type"
              name="type"
              options={typeOptions}
              labelKey={"name"}
              onChange={(value) => {
                setConstantType(value.length > 0 ? value[0].type : "");
              }}
              clearButton
            ></Typeahead>
          </FormGroup>
          {languagesForm}
          <FormGroup className="row">
            <Label className="col-4 col-form-label">{t("active")}</Label>
            <div className="col-6 d-flex align-items-center">
              <input type="checkbox" name="isAvailable" defaultChecked />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="primary"
            type="submit"
            disabled={isSaveButtonDisabled()}
          >
            {t("save")}
          </Button>
          <Button color="light" onClick={toggleModal} type="button">
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default translate(CreateLookup);
