import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";

import { translate } from "react-switch-lang";
import { withRouter, useParams } from "react-router-dom";
import {
  getResourceById,
  updateResource,
} from "../../services/locale-resource.service";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const EditResource = (props) => {
  const [editData, setEditData] = useState([]);
  const dispatch = useDispatch();
  const languageName = localStorage.getItem("langugaeName");
  const { resourceId } = useParams();

  const handleCancel = () => {
    props.history.push(`${process.env.PUBLIC_URL}/resources`);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(setGlobalLoading({ status: true }));
    const apiData = {
      value: event.target.key.value,
      id: editData.id,
    };
    console.log(apiData);

    (async () => {
      const { ok } = await updateResource(apiData);
      if (ok) {
        toast.success(props.t("resourceUpdatedSuccessfully"));
        props.history.push(`${process.env.PUBLIC_URL}/resources`);
      }
    })();
  };

  useEffect(() => {
    if (resourceId !== "" && resourceId) {
      (async () => {
        const { body, ok } = await getResourceById(resourceId);
        if (ok) {
          setEditData(body);
        }
      })();
    }
  }, [resourceId]);

  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("resources")}
        title={props.t("editResource")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={handleSubmit}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("name")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="name"
                            disabled
                            defaultValue={languageName}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("resourceName")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="resource"
                            disabled
                            defaultValue={editData.name}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("value")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="key"
                            defaultValue={editData.value}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label"></Label>
                        <Col sm="9"></Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button color="primary" className="mr-1" type="submit">
                    {props.t("save")}
                  </Button>
                  <Button color="light" type="reset" onClick={handleCancel}>
                    {props.t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(EditResource));
