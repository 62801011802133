// dashbaord
import Default from "../components/dashboard/default";
import Ecommerce from "../components/dashboard/ecommerce";

// widgets
import GeneralWidget from "../components/widgets/general";
import ChartsWidget from "../components/widgets/charts";

// ui-kits
import StateColor from "../components/ui-kits/statecolor";
import Typography from "../components/ui-kits/typography";
import Avatars from "../components/ui-kits/avatars";
import HelperClasses from "../components/ui-kits/helperClasses";
import Grid from "../components/ui-kits/grid";
import TagAndPills from "../components/ui-kits/tagAndPills";
import Progress from "../components/ui-kits/progress";
import Modal from "../components/ui-kits/modal";
import Alert from "../components/ui-kits/alert";
import Popover from "../components/ui-kits/popover";
import Tooltip from "../components/ui-kits/tooltip";
import Spinner from "../components/ui-kits/spinner";
import Dropdown from "../components/ui-kits/dropDown";
import TabBootstrap from "../components/ui-kits/tabs/tabBootstrap";
import TabLine from "../components/ui-kits/tabs/tabLine";
import Accordian from "../components/ui-kits/accordian";
import Shadow from "../components/ui-kits/shadow";
import List from "../components/ui-kits/list";

// bonus-ui
import Scrolling from "../components/bonus_ui/scrolling";
import BootstrapNotify from "../components/bonus_ui/bootstrapNotify";
import Rating from "../components/bonus_ui/rating";
import Dropzone from "../components/bonus_ui/dropzone";
import SweetAlert from "../components/bonus_ui/sweetAlert";
import Tour from "../components/bonus_ui/tour";
import Sticky from "../components/bonus_ui/sticky";
import Breadcrumb from "../components/bonus_ui/breadcrumb";
import Imagecrop from "../components/bonus_ui/imagecrop";
import RangeSlider from "../components/bonus_ui/rangeSlider";
import Carousel from "../components/bonus_ui/carousel";
import Ribbons from "../components/bonus_ui/ribbons";
import Pagination from "../components/bonus_ui/pagination";
import DragAndDrop from "../components/bonus_ui/dragAndDrop";
import UploadImage from "../components/bonus_ui/uploadImage";

// Cards
import BasicCards from "../components/bonus_ui/cards/basicCards";
import ThemeCards from "../components/bonus_ui/cards/themeCards";
import TabCard from "../components/bonus_ui/cards/tabCard";
import DraggingCards from "../components/bonus_ui/cards/draggingCards";

// Timeline
import Timeline1 from "../components/bonus_ui/timelines/timeline";

// Icons
import FlagIcons from "../components/icons/flagIcons";
import FontAwsomeIcon from "../components/icons/fontAwsomeIcon";
import IcoIcons from "../components/icons/icoIcons";
import ThemifyIcons from "../components/icons/themifyIcons";
import FeatherIcons from "../components/icons/featherIcons";
import WeatherIcons from "../components/icons/weatherIcons";

// Buttons
import DefaultButton from "../components/buttons/default-Button";
import EdgeButton from "../components/buttons/edgeButton";
import FlatButton from "../components/buttons/flatButton";
import GroupButton from "../components/buttons/groupButton";
import RaisedButton from "../components/buttons/raisedButton";

// Forms
import FormValidation from "../components/forms/form-control/form-validation";
import BaseInput from "../components/forms/form-control/baseInput";
import InputGroup from "../components/forms/form-control/inputGroup";
import MegaOption from "../components/forms/form-control/megaOption";
import CheckboxandRadio from "../components/forms/form-control/checkboxandRadio";

// Form Layout
import FormDefault from "../components/forms/form-layout/formDefault";
import FormWizard1 from "../components/forms/form-layout/form-wizard-1/formwizard1";

// Forms widgets
import Datepicker from "../components/forms/form-widget/datepicker";
import Timepicker from "../components/forms/form-widget/timepickerComponent/timepicker";
import Typeahead from "../components/forms/form-widget/typeaheadComponent/typeahead";

// Tabels
import BasicTabels from "../components/tables/basicTable";
import BorderTable from "../components/tables/borderTable";
import SizingTable from "../components/tables/sizingTable";
import StylingTable from "../components/tables/stylingTable";
import DataTable from "../components/tables/dataTable";

// Charts
import Apexcharts from "../components/charts/apexCharts";
import GoogleCharts from "../components/charts/googleCharts";
import KnobChart from "../components/charts/knobChart";
import Chartsjs from "../components/charts/chartsjs";
import Chartist from "../components/charts/chartistCharts";

// Gallary
import ImageGallery from "../components/gallery/imageGallery";
import ImageWithDesc from "../components/gallery/imageWithDesc";
import MesonryGallery from "../components/gallery/mesonryGallery";
import MesonryDesc from "../components/gallery/mesonryDesc";
import ImageHover from "../components/gallery/imageHover";

// Blog
import BlogDetail from "../components/blog/blogDetail";
import BlogSingle from "../components/blog/blogSingle";
import BlogPost from "../components/blog/blogPost";

// Job Search
import CardView from "../components/jobSearch/cardView";
import JobList from "../components/jobSearch/job-list";
import JobDetail from "../components/jobSearch/job-detail";
import JobApply from "../components/jobSearch/job-apply";

// Learning
import Learninglist from "../components/learning/learning-list";
import LearningDeatil from "../components/learning/learning-deatil";

// Calendar
import BasicCalender from "../components/calendar/basicCalendar";
import DraggableCalendar from "../components/calendar/draggableCalendar";

// Users
import UserProfile from "../components/users/userProfile";
import UserEdit from "../components/users/userEdit";
import UserCards from "../components/users/userCards";

// Maps
import GoogleMap from "../components/map/googleMap";

// Editor
import CkEditor from "../components/editor/ckEditor";
import MdeEditor from "../components/editor/mdeEditor";

// Social App
import SocialApp from "../components/social-app";

// FAQ page
import FaqComponent from "../components/faq";

// knowledgebase page
import KnowledgebaseComponent from "../components/knowledgebase";

// Support Ticket
import SupportTicket from "../components/support-ticket";

// Sample page
import Sample from "../components/sample";

// Search page
import Search from "../components/search";

// E-commerce-app
import Product from "../components/application/ecommerce-app/product";
import ProductDetail from "../components/application/ecommerce-app/productpage";
import Cart from "../components/application/ecommerce-app/cart";
import Wishlist from "../components/application/ecommerce-app/wishlist";
import Productlist from "../components/application/ecommerce-app/productlist";
import Paymentdetails from "../components/application/ecommerce-app/paymentdetails";
import OrderHistory from "../components/application/ecommerce-app/orderHistory";
import Checkout from "../components/application/ecommerce-app/checkout";
import Invoice from "../components/application/ecommerce-app/invoice";
import Pricing from "../components/application/ecommerce-app/pricing";

// Email
import Email from "../components/application/email-app/emailDefault";

// Chat
import Chat from "../components/application/chat-app";

// Bookmark
import Bookmark from "../components/application/bookmark";

// Project app
import ProjectList from "../components/application/project/project";
import NewProject from "../components/application/project/new-project";

// File Manager App
import FileManager from "../components/application/file-manager/file-manager";

// Kanban Board
import kanbanBoard from "../components/application/kanban-board/kanbanBoard";

// Task
import TaskApp from "../components/application/task-app";

// Contact app
import ContactApp from "../components/application/contact-app";

// todo-app
import Todo from "../components/application/todo-app";

// todo-firebase-app
import TodoFirebase from "../components/application/todo-firebase-app";


// Ahamd imports
import TestKP from '../KPComponents/kp/TestKP'
import SiteSetting from '../KPComponents/siteSetting/SiteSetting'
import Try from '../components/1111/Try'
import ViewLanguages from '../KPComponents/languages/ViewLanguages'
import AddLanguage from '../KPComponents/languages/AddLanguage'
import EditLanguage from '../KPComponents/languages/EditLangugae'
import ViewResource from '../KPComponents/resources/ViewResource'
import EditResource from '../KPComponents/resources/EditResource'
import ViewRoles from '../KPComponents/roles/ViewRoles'
import AddRole from '../KPComponents/roles/AddRole'
import EditRole from '../KPComponents/roles/EditRole'
import Claims from '../KPComponents/roles/Claims'
import ViewUsers from '../KPComponents/users/ViewUsers'
import AddUser from '../KPComponents/users/AddUser'
import EditUser from '../KPComponents/users/EditUser'
import Vieworders from '../KPComponents/orders/Vieworders'
import AddOrder from "../KPComponents/orders/AddOrder";
import AddContractDe from "../KPComponents/orders/AddContrcatDE";
import HomePage from "../KPComponents/homePage/HomePage";
import OrderTabs from "../KPComponents/orderTabs/OrderTabs";
import ViewProducts from '../KPComponents/products/ViewProducts';
import AddProduct from '../KPComponents/products/AddProduct';
import EditProduct from '../KPComponents/products/EditProduct';
import MaintenanceOrders from '../KPComponents/maintenance/MaintenanceOrders';
import MaintenanceDetails from '../KPComponents/maintenance/MaintenanceDetails';
import ViewLogs from "../KPComponents/logs/ViewLogs";








// End Ahmad Imports
// Mayyas Imports
import ViewClients from "../KPComponents/client/ViewClients";
import ViewBranches from "../KPComponents/branch/ViewBranches";
import ViewCities from "../KPComponents/city/ViewCities";
import ViewNeighborhoods from "../KPComponents/neighborhood/ViewNeighborhoods";
import ContractRegistration from "../KPComponents/orders/ContractRegistration";
import Profile from "../KPComponents/users/Profile";
import ViewLookups from "../KPComponents/lookup/ViewLookups";
import ViewMessageTemplates from "../KPComponents/messageTemplate/ViewMessageTemplates";
import ViewEmailTemplates from "../KPComponents/emailTemplate/ViewEmailTemplates";
import EditEmailTemplate from "../KPComponents/emailTemplate/EditEmailTemplate";

import PrintCard from "../KPComponents/orderTabs/card/PrintCard";































// End Mayyas Imports
// import TestAssign from "../KPComponents/orders/TestAssign";
export const routes = [







  //  Ahmad Routes
  { path: "/kp/", Component: TestKP },
  { path: "/homePage", Component: HomePage },
  { path: "/settings", Component: SiteSetting, claim: "Settings.Manage" },
  { path: "/languages", Component: ViewLanguages, claim: "Languages.Manage" },
  { path: "/languages/add", Component: AddLanguage, claim: "Languages.Manage" },
  { path: "/editLanguage/:languageId", Component: EditLanguage, claim: "Languages.Manage" },
  { path: "/resources", Component: ViewResource, claim: "Languages.Manage" },
  { path: "/resources/edit/:resourceId", Component: EditResource, claim: "Languages.Manage" },
  { path: "/roles", Component: ViewRoles, claim: "Roles.Manage" },
  { path: "/roles/add", Component: AddRole, claim: "Roles.Manage" },
  { path: "/roles/edit/:roleId", Component: EditRole, claim: "Roles.Manage" },
  { path: "/users", Component: ViewUsers, claim: "Users.Manage" },
  { path: "/users/add", Component: AddUser, claim: "Users.Manage" },
  { path: "/users/edit/:userId", Component: EditUser, claim: "Users.Manage" },
  { path: "/claims", Component: Claims, claim: "Roles.Manage" },
  { path: "/orders/tabs/:orderId", Component: OrderTabs, claim: "Orders.Get" },
  { path: "/products", Component: ViewProducts, claim: "Products.Manage" },
  { path: "/products/add", Component: AddProduct, claim: "Products.Manage" },
  { path: "/products/edit/:productId", Component: EditProduct, claim: "Products.Manage" },
  { path: "/profile", Component: Profile, claim: "Users.Manage" },
  { path: "/maintenanceOrders", Component: MaintenanceOrders, claim: "Maintenance.Get" },
  { path: "/orders/contract/details/:requestId", Component: MaintenanceDetails, claim: "Maintenance.ViewDetails" },
  // { path: "/editProduct/:productId", Component: EditProduct, claim: "Products.Manage" },

  { path: "/orders/card/:orderId", Component: PrintCard, claim: "Orders.Print" },

  { path: "/logs", Component: ViewLogs, claim: "Admin" },

















  // { path: "/viewRoles", component: ViewRoles },
  // End Ahmad Routes
  // Mayyas Routes
  { path: "/clients", Component: ViewClients, claim: "Clients.Manage" },
  { path: "/branches", Component: ViewBranches, claim: "Branches.Manage" },
  { path: "/cities", Component: ViewCities, claim: "Lookups.Manage" },
  { path: "/neighborhoods", Component: ViewNeighborhoods, claim: "Lookups.Manage" },
  { path: "/orders/contract/register/:orderId", Component: ContractRegistration, claim: "Orders.OrderContractRegistration" },
  { path: "/constants", Component: ViewLookups, claim: "Lookups.Manage" },
  { path: "/message-templates", Component: ViewMessageTemplates, claim: "Settings.Manage" },
  { path: "/email-templates", Component: ViewEmailTemplates, claim: "Settings.Manage" },
  { path: "/email-templates/edit/:templateId", Component: EditEmailTemplate, claim: "Settings.Manage" },





























  // End Mayyas Routes
  { path: "/dashboard/try/:layout/", Component: Try, claim: "" },




  // Orders
  { path: "/orders", Component: Vieworders, claim: "Orders.Get" },
  { path: "/orders/add", Component: AddOrder, claim: "Orders.Create" },
  { path: "/orders/addContract", Component: AddContractDe, claim: "DataEntry.InsertContract" },
  // End Orders  insert.Contract

  // { path: "/dashboard/default/:layout/", Component: Default },
  // { path: "/dashboard/default/:layout/", Component: SiteSetting },
  { path: "/dashboard/ecommerce/:layout/", Component: Ecommerce },

  { path: "/widgets/general/:layout", Component: GeneralWidget },
  { path: "/widgets/chart/:layout", Component: ChartsWidget },

  { path: "/ui-kits/statecolor/:layout", Component: StateColor },
  { path: "/ui-kits/typography/:layout", Component: Typography },
  { path: "/ui-kits/avatar/:layout", Component: Avatars },
  { path: "/ui-kits/helperclass/:layout", Component: HelperClasses },
  { path: "/ui-kits/grid/:layout", Component: Grid },
  { path: "/ui-kits/tagsandpills/:layout", Component: TagAndPills },
  { path: "/ui-kits/progress-bar/:layout", Component: Progress },
  { path: "/ui-kits/modal/:layout", Component: Modal },
  { path: "/ui-kits/alert/:layout", Component: Alert },
  { path: "/ui-kits/popover/:layout", Component: Popover },
  { path: "/ui-kits/tooltips/:layout", Component: Tooltip },
  { path: "/ui-kits/spinner/:layout", Component: Spinner },
  { path: "/ui-kits/dropdown/:layout", Component: Dropdown },
  { path: "/ui-kits/tab-bootstrap/:layout", Component: TabBootstrap },
  { path: "/ui-kits/tab-line/:layout", Component: TabLine },
  { path: "/ui-kits/accordion/:layout", Component: Accordian },
  { path: "/ui-kits/shadow/:layout", Component: Shadow },
  { path: "/ui-kits/list/:layout", Component: List },

  { path: "/bonus-ui/scrollable/:layout", Component: Scrolling },
  { path: "/bonus-ui/bootstrap-notify/:layout", Component: BootstrapNotify },
  { path: "/bonus-ui/rating/:layout", Component: Rating },
  { path: "/bonus-ui/dropzone/:layout", Component: Dropzone },
  { path: "/bonus-ui/sweetAlert/:layout", Component: SweetAlert },
  { path: "/bonus-ui/tourComponent/:layout", Component: Tour },
  { path: "/bonus-ui/stickyNotes/:layout", Component: Sticky },
  { path: "/bonus-ui/breadcrumb/:layout", Component: Breadcrumb },
  { path: "/bonus-ui/imageCropper/:layout", Component: Imagecrop },
  { path: "/bonus-ui/rangeSlider/:layout", Component: RangeSlider },
  { path: "/bonus-ui/carousel/:layout", Component: Carousel },
  { path: "/bonus-ui/ribbons/:layout", Component: Ribbons },
  { path: "/bonus-ui/pagination/:layout", Component: Pagination },
  { path: "/bonus-ui/dragNDropComp/:layout", Component: DragAndDrop },
  { path: "/bonus-ui/image-upload/:layout", Component: UploadImage },

  { path: "/bonus-ui/card/basicCards/:layout", Component: BasicCards },
  { path: "/bonus-ui/card/creativeCards/:layout", Component: ThemeCards },
  { path: "/bonus-ui/card/tabCard/:layout", Component: TabCard },
  { path: "/bonus-ui/card/draggingCards/:layout", Component: DraggingCards },

  { path: "/bonus-ui/timelines/timeline1/:layout", Component: Timeline1 },

  { path: "/icons/flagIcons/:layout", Component: FlagIcons },
  { path: "/icons/fontAwsomeIcon/:layout", Component: FontAwsomeIcon },
  { path: "/icons/icoIcons/:layout", Component: IcoIcons },
  { path: "/icons/featherIcons/:layout", Component: FeatherIcons },
  { path: "/icons/themifyIcons/:layout", Component: ThemifyIcons },
  { path: "/icons/weatherIcons/:layout", Component: WeatherIcons },
  { path: "/buttons/default-btn/:layout", Component: DefaultButton },
  { path: "/buttons/flatBtn/:layout", Component: FlatButton },
  { path: "/buttons/edgeBtn/:layout", Component: EdgeButton },
  { path: "/buttons/raisedBtn/:layout", Component: RaisedButton },
  { path: "/buttons/groupBtn/:layout", Component: GroupButton },

  { path: "/forms/form-validation/:layout", Component: FormValidation },
  { path: "/forms/baseInput/:layout", Component: BaseInput },
  { path: "/forms/inputGroup/:layout", Component: InputGroup },
  { path: "/forms/megaOptions/:layout", Component: MegaOption },
  { path: "/forms/radio-checkbox/:layout", Component: CheckboxandRadio },
  { path: "/form-layout/formDefault/:layout", Component: FormDefault },
  { path: "/form-layout/formWizard/:layout", Component: FormWizard1 },

  { path: "/form-widget/datepicker/:layout", Component: Datepicker },
  { path: "/form-widget/timepicker/:layout", Component: Timepicker },
  { path: "/form-widget/typeahead/:layout", Component: Typeahead },

  { path: "/table/basic/:layout", Component: BasicTabels },
  { path: "/table/border/:layout", Component: BorderTable },
  { path: "/table/sizing/:layout", Component: SizingTable },
  { path: "/table/styling/:layout", Component: StylingTable },
  { path: "/table/datatable/:layout", Component: DataTable },

  { path: "/charts/apexCharts/:layout", Component: Apexcharts },
  { path: "/charts/googleChart/:layout", Component: GoogleCharts },
  { path: "/charts/knobChart/:layout", Component: KnobChart },
  { path: "/charts/chartJs/:layout", Component: Chartsjs },
  { path: "/charts/chartistComponent/:layout", Component: Chartist },

  { path: "/app/gallery/imageGallery/:layout", Component: ImageGallery },
  { path: "/app/gallery/imageWithDesc/:layout", Component: ImageWithDesc },
  { path: "/app/gallery/mesonryGallery/:layout", Component: MesonryGallery },
  { path: "/app/gallery/mesonryDesc/:layout", Component: MesonryDesc },
  { path: "/app/gallery/imageHover/:layout", Component: ImageHover },

  { path: "/app/blog/blogDetail/:layout", Component: BlogDetail },
  { path: "/app/blog/blogSingle/:layout", Component: BlogSingle },
  { path: "/app/blog/blogPost/:layout", Component: BlogPost },

  { path: "/app/jobSearch/cardView/:layout", Component: CardView },
  { path: "/app/jobSearch/job-list/:layout", Component: JobList },
  { path: "/app/jobSearch/job-detail/:layout", Component: JobDetail },
  { path: "/app/jobSearch/job-apply/:layout", Component: JobApply },

  { path: "/app/learning/learning-list/:layout", Component: Learninglist },
  { path: "/app/learning/learning-detail/:layout", Component: LearningDeatil },

  { path: "/app/users/userProfile/:layout", Component: UserProfile },
  { path: "/app/users/userEdit/:layout", Component: UserEdit },
  { path: "/app/users/userCards/:layout", Component: UserCards },

  { path: "/app/calendar/basic-calendar/:layout", Component: BasicCalender },
  {
    path: "/app/calendar/draggable-calendar/:layout",
    Component: DraggableCalendar,
  },

  { path: "/app/map/googleMap/:layout", Component: GoogleMap },

  { path: "/app/editor/ckEditor/:layout", Component: CkEditor },
  { path: "/app/editor/mdeEditor/:layout", Component: MdeEditor },

  { path: "/app/social-app/:layout", Component: SocialApp },
  { path: "/app/faq/:layout", Component: FaqComponent },
  { path: "/app/knowledgebase/:layout", Component: KnowledgebaseComponent },
  { path: "/app/support-ticket/:layout", Component: SupportTicket },

  { path: "/pages/samplepage/:layout", Component: Sample },
  { path: "/pages/searchpage/:layout", Component: Search },

  { path: "/app/ecommerce/product/:layout", Component: Product },
  { path: "/app/ecommerce/product-page/:id/:layout", Component: ProductDetail },
  { path: "/app/ecommerce/cart/:layout", Component: Cart },
  { path: "/app/ecommerce/wishlist/:layout", Component: Wishlist },
  { path: "/app/ecommerce/checkout/:layout", Component: Checkout },
  { path: "/app/ecommerce/invoice/:layout", Component: Invoice },
  { path: "/app/ecommerce/product-list/:layout", Component: Productlist },
  { path: "/app/ecommerce/payment-details/:layout", Component: Paymentdetails },
  { path: "/app/ecommerce/orderhistory/:layout", Component: OrderHistory },
  { path: "/app/ecommerce/pricing/:layout", Component: Pricing },

  { path: "/app/email-app/:layout", Component: Email },
  { path: "/app/chat-app/:layout", Component: Chat },
  { path: "/app/bookmark/:layout", Component: Bookmark },
  { path: "/app/task/:layout", Component: TaskApp },

  { path: "/app/project/project-list/:layout", Component: ProjectList },
  { path: "/app/project/new-project/:layout", Component: NewProject },

  { path: "/app/file-manager/:layout", Component: FileManager },
  { path: "/app/kanban-board/:layout", Component: kanbanBoard },

  { path: "/app/contact/:layout", Component: ContactApp },

  { path: "/app/todo-app/todo/:layout", Component: Todo },
  { path: "/app/todo-app/todo-firebase/:layout", Component: TodoFirebase },
];
