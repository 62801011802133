import { createSlice } from "@reduxjs/toolkit";

const citySlice = createSlice({
  name: "city",
  initialState: {
    id: null,
  },
  reducers: {
    setCityIdToEdit: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

export const { setCityIdToEdit } = citySlice.actions;

export default citySlice.reducer;
