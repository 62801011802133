import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Loader from "../partials/Loader";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

import { getPublishedLanguages } from "../../services/language.service";
import { getLookupById, updateLookup } from "../../services/lookup.service";
import { toast } from "react-toastify";

const EditLookup = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshTable = () => {},
}) => {
  const [languages, setLanguages] = useState([]);
  const [constantType, setConstantType] = useState([
    {
      name: "",
      type: "",
    },
  ]);
  const [translations, setTranslations] = useState([]);
  const [isAvailable, setIsAvailable] = useState(true);

  // Store
  const id = useSelector((state) => state.lookup.id);
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);
  const typeOptions = [
    {
      type: "MarbleCompany",
      name: t("MarbleCompany"),
    },
  ];

  let languagesForm;
  if (languages.length > 0) {
    let valuedLanguages = [];
    if (translations.length > 0) {
      valuedLanguages = languages.map((language) => ({
        ...language,
        value: translations.filter(
          (translation) => language.id === translation.languageId
        )[0].name,
      }));
    }

    languagesForm = valuedLanguages.map((language, index) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.culture}
        >
          <Label
            htmlFor={`name_${language.culture}`}
            className="col-4 mb-0"
          >{`${t("name")} (${language.culture.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.culture}`}
            name={language.culture}
            value={language.value}
            required
            onChange={(event) =>
              handleTranslationChange(event.target.value, index)
            }
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <></>;
  }

  const handleTranslationChange = (value, index) => {
    setTranslations((prev) => {
      prev[index].name = value;
      return [...prev];
    });
  };

  const editLookup = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    toggleModal();

    const requestBody = {
      id,
      type: constantType[0].type,
      sorting: 1,
      isAvailable: isAvailable,
      translations,
    };

    (async () => {
      const { ok } = await updateLookup(requestBody);
      if (ok) {
        toast.success(t("updatedConstantSuccess"));
        toggleRefreshTable();
      } 
      
      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        setLanguages(body);
      } 
      
    })();
  }, []);

  const isSaveButtonDisabled = () => {
    if (constantType.length < 1 || constantType[0].type === "") {
      return true;
    }
    for (const index in translations) {
      if (!translations[index].name || translations[index].name === "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (id !== "" && id) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getLookupById(id);
        if (ok) {
          setConstantType(
            typeOptions.filter((option) => option.type === body.type)
          );
          setIsAvailable(body.isAvailable);
          setTranslations(body.translations);
        } 
        setLoading(false);
      })();
    }
    //eslint-disable-next-line
  }, [id]);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader>{t("addConstant")}</ModalHeader>
      {loading ? (
        <Loader />
      ) : (
        <Form className="form theme-form" onSubmit={editLookup}>
          <ModalBody>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="type" className="col-4 mb-0">
                {t("lookupType")}
              </Label>
              <Typeahead
                className="col-6 mb-0 p-0"
                id="type"
                name="type"
                options={typeOptions}
                labelKey={"name"}
                selected={constantType}
                onChange={(value) => {
                  setConstantType(value);
                }}
                clearButton
              ></Typeahead>
            </FormGroup>
            {languagesForm}
            <FormGroup className="row">
              <Label className="col-4 col-form-label">{t("active")}</Label>
              <div className="col-6 d-flex align-items-center">
                <input
                  type="checkbox"
                  name="isAvailable"
                  checked={isAvailable}
                  onChange={(event) => {
                    setIsAvailable(event.target.checked);
                  }}
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              color="primary"
              type="submit"
              disabled={isSaveButtonDisabled()}
            >
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default translate(EditLookup);
