import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import { translate } from "react-switch-lang";

import { withRouter } from "react-router-dom";
import { deleteUser } from "../../services/users.srvices";
import ConfirmationModal from "../partials/ConfirmationModal";
import environment from "../../environment";
import KPTable from "../partials/KPTable/KPTable";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

const ViewUsers = (props) => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [role, setRole] = useState();

  const languageKey = useSelector((state) => state.language.key);

  // After API
  const [requestOptions, setRequestOptions] = useState(
    {
      userType: "System",
      orderBy: "",
      searchText: ""
    }
  );
  // UI/UX
  const [isConfirm, setIsConfirm] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);
  const toggleRefreshTableData = () => {
    setRefreshTable(!refreshTable);
  };

  const tableColumns = [
    {
      name: props.t("userName"),
      selector: "userName",

      center: true,
    },
    {
      name: props.t("email"),
      selector: "email",

      center: true,
    },
    {
      name: props.t("phoneNumber"),
      selector: "phoneNumber",

      center: true,
    },
    {
      name: props.t("displayName"),
      selector: "displayName",

      center: true,
    },
    {
      name: props.t("role"),
      selector: "roleName",

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            localStorage.setItem(
              "branchesIds",
              JSON.stringify(row.branchesIds)
            );
            props.history.push(`${process.env.PUBLIC_URL}/users/edit/${row.id}`);
          }}
        >
          {props.t("edit")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setIsConfirm(true);
            setUserId(row.id);
          }}
        >
          {" "}
          <i className="fa fa-trash" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getFilteredData = (value) => {
    if (value === "reset") {
      // After API
      setRequestOptions(
        {
          productName: "",
          barcode: "",
          productType: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          userName: userName,
          phoneNumber: phoneNumber,
          role: role,
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTableData();
  };
  const resetData = () => {
    setUserName("");
    setPhoneNumber("");
    setRole("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  return (
    <Fragment>
      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={userId}
        toggleRefreshData={toggleRefreshTableData}
        apiToCall={deleteUser}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb parent={props.t("users")} title={props.t("viewUsers")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{props.t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {props.t("userName")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="productName"
                              max="10"
                              value={userName}
                              onChange={(event) => {
                                setUserName(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {props.t("role")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="productName"
                              max="10"
                              value={role}
                              onChange={(event) => {
                                setRole(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>

                      <Col sm="6">

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {props.t("phoneNumber")}
                          </Label>
                          <Col sm="8">
                            <InputMask
                              style={{
                                direction: "ltr",
                                textAlign: languageKey === "ar" ? "end" : "start",
                              }}
                              className="form-control"
                              type="tel"
                              id="phoneNumber"
                              name="phoneNumber"
                              onChange={(event) => {
                                setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                              }}
                              mask="05 999 999 99"
                              value={phoneNumber}
                            ></InputMask>
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {props.t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {props.t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={() => {
                      props.history.push(`${process.env.PUBLIC_URL}/users/add`);
                    }}
                    size="sm"
                  >
                    {props.t("addUserbtn")}
                  </Button>
                  {/* <RefreshCw
                    className="m-2"
                    color="#4433FF"
                    onClick={resetData}
                    role="button"
                  /> */}
                </CardTitle>

                <KPTable
                  endpoint={`${environment.USER_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                // waiting for API
                // requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(ViewUsers));
