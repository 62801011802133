import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    id: "",
  },
  reducers: {
    setEditClientId: (state, action) => {
      state.id = action.payload.id;
    },
  },
});

export const { setEditClientId } = clientSlice.actions;

export default clientSlice.reducer;
