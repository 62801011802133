import React from "react";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createCity } from "../../services/city.service";

import { setGlobalLoading } from "../../store/slices/loadingSlice";

const CreateCity = ({
  isOpen,
  toggleModal = () => {},
  t,
  setSubmitting = () => {},
  toggleRefreshTable = () => {},
  languages = [],
}) => {
  // Store
  const dispatch = useDispatch();

  // Dynamic generation for Name inputs (Equal to the number of published languages)
  let languagesForm;
  if (languages.length > 0) {
    languagesForm = languages.map((language) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.code}
        >
          <Label htmlFor={`name_${language.code}`} className="col-4 mb-0">{`${t(
            "name"
          )} (${language.code.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            required
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <div></div>;
  }
  //END

  const addNewCity = (event) => {
    event.preventDefault();
    setSubmitting(true);
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const translations = [];

    languages.forEach((language, index) => {
      translations.push({
        name: event.target[index].value,
        languageId: language.id,
      });
    });

    const requestBody = {
      translations: translations,
    };
    (async () => {
      const { ok } = await createCity(requestBody);

      if (ok) {
        toast.success(t("addCitySuccess"));
      }
      setSubmitting(false);
      toggleRefreshTable();
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("addNewCity")}</ModalHeader>
        <Form className="form theme-form" onSubmit={addNewCity}>
          <ModalBody>{languagesForm}</ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" type="submit">
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default translate(CreateCity);
