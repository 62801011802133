import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import BasicInformation from "./BasicInformation";
import timePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization'
const OrderDetails = (props) => {
  const data = props.data;

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("city")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.cityLocalized || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("neighborhood")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.neighborhoodLocalized ||
                              props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("branch")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.branch || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("notes")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.notes || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("orderType")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.orderTypeLocalized || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("visitPeriod")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.visitPeriodLocalized || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("visitDate")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.visitDate || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("visitTime")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {(data.visitTime !== null) ?
                              (data.visitTime.substring(0, 5) + " " +
                                timePeriodLocalization(data.visitTime.substring(6, 8)))
                              :
                              (props.t("notEntered"))
                            }
                          </Label>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-5 col-form-label">
                          {props.t("measureTechnician")}
                        </Label>
                        <Col sm="7">
                          <Label className="col-form-label font-weight-bold">
                            {data.measureTechnician || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardBody>
                  <BasicInformation data={data} clientData={props.client} />
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withRouter(translate(OrderDetails));
