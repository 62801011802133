import React, { Fragment, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,

    Nav, NavItem, NavLink,
    TabContent, TabPane
} from 'reactstrap';
import Accessories from './contract/Accessories';
import Attachments from './contract/Attachments';
import ContractBasicInformation from './contract/ContractBasicInformation';
import Devices from './contract/Devices';
import MeasuringKitchen from './contract/MeasuringKitchen';
const Contract = (props) => {

    const [VerticalTab, setVerticalTab] = useState('1');
    const [styles, setStyles] = useState({});
    const [priceStyles, setPriceStyles] = useState({});
    const ss = () => {
        (localStorage.getItem("lang") === 'ar') ?
            setStyles({
                "text-align": "right",
                "font-size": "12px"
            })
            :
            setStyles({
                "text-align": "left",
                "font-size": "12px"
            })
    }
    const s = () => {
        (localStorage.getItem("lang") === 'ar') ?
            setPriceStyles({
                float: "left"
            })
            :
            setPriceStyles({
                float: "right"
            })
    }
    useEffect(() => {
        ss()
        s()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("lang")]);

    return (
        <Fragment>

            <Container fluid={true}>
                <Row>
                    <Col sm="3" xs="12">
                        <Nav className="nav-dark flex-column nav-pills">
                            <NavItem                            >
                                <NavLink href="#javascript"
                                    className={VerticalTab === '1' ? 'active' : ''}
                                    onClick={() => setVerticalTab('1')}
                                    style={styles}                                >
                                    {props.t("basicInformation")}
                                </NavLink>
                            </NavItem>
                            <NavItem                            >
                                <NavLink href="#javascript"
                                    className={VerticalTab === '2' ? 'active' : ''}
                                    onClick={() => setVerticalTab('2')}
                                    style={styles}                                >
                                    {props.t("attachments")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '3' ? 'active' : ''}
                                    onClick={() => setVerticalTab('3')}>
                                    <span style={styles}> {props.t("measuringKitchen")}</span>
                                    <span style={priceStyles}>
                                        ({
                                            (props.data.totalMeterPrice === 0 || props.data.totalMeterPrice === null) ?
                                                0
                                                :
                                                props.data.totalMeterPrice
                                        }
                                        {props.t("rs")})
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '4' ? 'active' : ''}
                                    onClick={() => setVerticalTab('4')}                                >
                                    <span style={styles}> {props.t("accessories")}</span>
                                    <span style={priceStyles}>
                                        ({
                                            (props.data.accessorieSubTotal === 0 || props.data.accessorieSubTotal === null) ?
                                                0
                                                :
                                                props.data.accessorieSubTotal
                                        }
                                        {props.t("rs")})
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '5' ? 'active' : ''}
                                    onClick={() => setVerticalTab('5')}                                    >
                                    <span style={styles}> {props.t("devices")}</span>
                                    <span style={priceStyles}>
                                        ({
                                            (props.data.devicesSubTotal === 0 || props.data.devicesSubTotal === null) ?
                                                0
                                                :
                                                props.data.devicesSubTotal
                                        }
                                        {props.t("rs")})
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript">
                                    <span style={styles}> {props.t("total")}</span>
                                    <span style={priceStyles}>
                                        ({
                                            (props.data.orderTotal === 0 || props.data.orderTotal === null) ?
                                                0
                                                :
                                                props.data.orderTotal
                                        }
                                        {props.t("rs")})
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm="9" xs="12">
                        <TabContent activeTab={VerticalTab}>
                            <TabPane className="fade show" tabId="1">
                                <ContractBasicInformation
                                    data={props.data}
                                />
                            </TabPane>
                            <TabPane className="fade show" tabId="2">
                                <Attachments
                                    kitchenManualDesign={props.kitchenManualDesign}
                                    kitchen3DDesign={props.kitchen3DDesign}
                                    contractImageFile={props.contractImageFile}
                                    doorsHandleImagesFiles={props.doorsHandleImagesFiles}
                                    washtubImagesFiles={props.washtubImagesFiles}
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <MeasuringKitchen data={props.data} />
                            </TabPane>
                            <TabPane tabId="4">
                                <Accessories data={props.data} />
                            </TabPane>
                            <TabPane tabId="5">
                                <Devices data={props.data} />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withRouter(translate(Contract));