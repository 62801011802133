import { useSelector } from "react-redux";

const TimePeriodLocalization = (period) => {
    const languageKey = useSelector((state) => state.language.key);
    if (languageKey === "ar") {
        if (period === "AM")
            return "ص";
        else return "م";
    }
    else return period;
}
export default TimePeriodLocalization;

