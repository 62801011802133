import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.BRANCH_ENDPOINT;


export const getAllBranches = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};

export const getBranchById = async (branchId) => {
  const response = await Get(`${_endpoint}/Get?id=${branchId}`, true);
  return response;
};

export const createBranch = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const updateBranch = async (requestBody) => {
  const response = await Post(`${_endpoint}/Update`, requestBody, true);
  return response;
};

export const deleteBranch = async (id) => {
  const requestBody = {
    id,
  };
  const response = await Post(`${_endpoint}/Delete`, requestBody, true);
  return response;
};
