import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileName: localStorage.getItem("profileName") || "...",
    role: localStorage.getItem("role") || "...",
  },
  reducers: {
    setProfileName: (state, action) => {
      state.profileName = action.payload.profileName;
      state.role = action.payload.role;
      localStorage.setItem("profileName", state.profileName);
      localStorage.setItem("role", state.role);
    },
  },
});

export const { setProfileName } = profileSlice.actions;

export default profileSlice.reducer;
