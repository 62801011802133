import { createSlice } from "@reduxjs/toolkit";
import environment from "../../environment";
const themeSlice = createSlice({
  name: "theme",
  initialState: {
    appliedTheme: localStorage.getItem(environment.THEME_KEY)
      ? localStorage.getItem(environment.THEME_KEY)
      : "light",
  },
  reducers: {
    switchTheme: (state, action) => {
      if (action.payload.appliedTheme === "light") {
        state.appliedTheme = "dark-only";
        localStorage.setItem(environment.THEME_KEY, "dark-only");
        document.body.className = "dark-only";
        document.documentElement.style.setProperty(
          "--theme-color",
          "#ffffffb3"
        );
        document.documentElement.style.setProperty(
          "--theme-background",
          "#262932"
        );
        document.documentElement.style.setProperty(
          "--theme-secondary-background",
          "#1d1e26"
        );
      } else {
        localStorage.setItem(environment.THEME_KEY, action.payload.appliedTheme);
        state.appliedTheme = "light";
        localStorage.setItem(environment.THEME_KEY, "light");
        document.body.className = "light";
        document.documentElement.style.setProperty("--theme-color", "#2b2b2b");
        document.documentElement.style.setProperty(
          "--theme-background",
          "#fff"
        );
        document.documentElement.style.setProperty(
          "--theme-secondary-background",
          "#f8f9fa"
        );
      }
    },
  },
});

export const { switchTheme } = themeSlice.actions;

export default themeSlice.reducer;
