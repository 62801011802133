import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { translate } from "react-switch-lang";
import { Container, Row, Col, Button } from "reactstrap";
import Loader from "../../../KPComponents/partials/Loader";
import Breadcrumb from "../../../layout/breadcrumb";
import { getClientOrders } from "../../../services/maintenance-public.service";
const ViewCustomerOrders = ({ t }) => {
  const [data, setData] = useState([]);

  // Routing
  const history = useHistory();

  // Store
  const languageKey = useSelector((state) => state.language.key);
  // UI/UX
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    (async () => {
      const REQUEST_BODY = {
        pageIndex: 0,
        pageSize: 1000,
      };
      const { body, ok } = await getClientOrders(REQUEST_BODY);
      if (ok) {
        setData(body.items);
      }
      setLoading(false);
    })();
  }, [languageKey]);

  return (
    <Fragment>
      <Breadcrumb parent={t("customer")} title={t("ordersList")} />
      <Container fluid={true}>
        <Row>
          {!loading ? (
            <>
              <Col
                sm="6"
                lg="4"
                className="mt-4 d-flex flex-column justify-content-center align-items-center"
              >
                <button
                  className="action-container"
                  onClick={() =>
                    history.push(
                      `${process.env.PUBLIC_URL}/customer/orders/create`
                    )
                  }
                >
                  <i className="fa fa-plus add"></i>
                </button>
              </Col>
              {data.map((item, i) => (
                <Col sm="6" lg="4" className="mt-4 " key={item.id}>
                  <div className="project-box p-3 theme-background">
                    <h4>{`${t("order")} - ${item.orderCode}`}</h4>
                    <p>{item.orderDate.substring(
                      0,
                      item.orderDate.length - 3
                    ) +
                      " " +
                      t(
                        `${item.orderDate.substring(
                          item.orderDate.length - 2,
                          item.orderDate.length
                        )}`
                      )
                    }</p>
                    <h6 className="pt-2">{t("orderInformation")}</h6>
                    <Row className="details">
                      <Col className="d-flex align-items-center">
                        <i className="fa fa-map-marker icon"></i>
                        {item.address}
                      </Col>
                    </Row>
                    <Row className="actions">
                      <Button
                        color="light"
                        onClick={() => {
                          history.push(
                            `${process.env.PUBLIC_URL}/customer/orders/${item.id}`
                          );
                        }}
                      >
                        {t("details")}
                      </Button>
                    </Row>
                  </div>
                </Col>
              ))}
            </>
          ) : (
            <Loader></Loader>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default translate(ViewCustomerOrders);
