import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { approveRequest, getAssignInstallingTechnical } from "../../../services/maintenance.service";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { TextField } from "@mui/material";

import Loader from "../../partials/Loader";
const ApproveModal = ({
    isOpen,
    toggleModal = () => { },
    t,
    toggleRefreshData = () => { },
}) => {
    const [techniciansOption, setTechniciansOption] = useState([]);
    const [technicianId, setTechnicianId] = useState("");
    const [visitTime, setVisitTime] = useState();
    const [visitDate, setVisitDate] = useState(new Date());

    const [timeRange, setTimeRange] = useState({});
    const [fromTime, setFromTime] = useState({});
    const [toTime, setToTime] = useState({});
    // TimePicker
    const languageKey = useSelector((state) => state.language.key);

    // Store
    const requestId = useParams().requestId;
    const dispatch = useDispatch();
    // UI/Ux
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timeError, setTimeError] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const timeLocaleMap = {
        ar: "ar-SA",
        en: "en-US",
    };
    const timeDisplayOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    };

    const handleTechnicianSelect = (value) => {
        if (value.length) {
            setTechnicianId(value[0].id);
        } else {
            setTechnicianId("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        toggleModal();
        dispatch(setGlobalLoading({ status: true }));
        const requestBody = {
            id: requestId,
            installingTechnicianId: technicianId,
            visitDate: visitDate.toLocaleDateString("en-GB"),
            visitTime: visitTime,
        };
        console.log(requestBody);
        (async () => {
            const { ok } = await approveRequest(requestBody);

            if (ok) {
                setTimeout(() => {
                    toast.success(t("orderAccepted"));
                }, 200);
                toggleRefreshData();
            }

            dispatch(setGlobalLoading({ status: false }));
        })();
    };
    useEffect(() => {
        if (requestId !== "" && requestId) {
            setLoading(true);
            let fromHours, fromMinutes;
            let toHours, toMinutes;
            (async () => {
                const { body, ok } = await getAssignInstallingTechnical(requestId);
                if (ok) {
                    setTechniciansOption(body.technichians);
                    if (body.visitPeriods.fromTime.hours > 9)
                        fromHours = body.visitPeriods.fromTime.hours;
                    else
                        fromHours = "0" + body.visitPeriods.fromTime.hours;
                    if (body.visitPeriods.fromTime.minutes > 9)
                        fromMinutes = body.visitPeriods.fromTime.minutes;
                    else
                        fromMinutes = body.visitPeriods.fromTime.minutes + "0";
                    setFromTime(fromHours + ":" + fromMinutes);
                    if (body.visitPeriods.toTime.hours > 9)
                        toHours = body.visitPeriods.toTime.hours;
                    else
                        toHours = "0" + body.visitPeriods.toTime.hours;
                    if (body.visitPeriods.toTime.minutes > 9)
                        toMinutes = body.visitPeriods.toTime.minutes;
                    else
                        toMinutes = body.visitPeriods.toTime.minutes + "0";
                    setToTime(toHours + ":" + toMinutes);
                    setTimeRange({
                        from: new Date(
                            2022,
                            1,
                            1,
                            body.visitPeriods.fromTime.hours,
                            body.visitPeriods.fromTime.minutes
                        ),
                        to: new Date(
                            2022,
                            1,
                            1,
                            body.visitPeriods.toTime.hours,
                            body.visitPeriods.toTime.minutes
                        ),
                    });
                }
                setLoading(false);
            })();
        }
        console.log(requestId);
    }, [requestId]);
    const handleTime = (value) => {
        if (value >= fromTime && value <= toTime) {
            setTimeError(false);
            setVisitTime(value);
            setIsSubmitting(true);
        }
        else {
            setTimeError(true);
            setIsSubmitting(false);
        }
    }
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggleModal} centered>
                <ModalHeader>{t("acceptOrder")}</ModalHeader>
                <Form className="form theme-form" onSubmit={handleSubmit}>
                    {submitLoading ? (
                        <ModalBody>
                            <Loader />
                        </ModalBody>
                    ) : (
                        <ModalBody>
                            <FormGroup className="row d-flex align-items-center">
                                <Label className="col-4 mb-0">{t("technical")}</Label>
                                <Typeahead
                                    className="col-7 p-0"
                                    id="select-technician"
                                    options={techniciansOption}
                                    labelKey="displayName"
                                    onChange={handleTechnicianSelect}
                                    placeholder={t("selectPlaceholder")}
                                    isLoading={loading}
                                    clearButton
                                    key={"id"}
                                ></Typeahead>
                            </FormGroup>
                            <FormGroup className="form-row">
                                <label className="col-4 mb-0">
                                    {t("date")}</label>
                                <div className="col-7 p-0 mb-0">
                                    <DatePicker
                                        name="date"
                                        className="form-control digits"
                                        selected={visitDate}
                                        onChange={setVisitDate}
                                        minDate={new Date()}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="col-4 mb-0">
                                    {t("time")}</label>
                                <div className="col-7 p-0">
                                    <TextField
                                        id="time"
                                        label={
                                            <i className="fa fa-clock-o"></i>
                                        }
                                        type="time"
                                        value={visitTime}
                                        onChange={(value) => handleTime(value.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            inputProps: {
                                                step: 300, // 5 min
                                            }
                                        }}
                                        error={timeError}
                                        sx={{ width: 290 }}
                                    />
                                    {!loading && (
                                        <p
                                            style={{
                                                color: "var(--theme-default)",
                                                fontSize: languageKey === "en" ? "0.7rem" : "0.9rem",
                                            }}
                                        >{`${t("selectTimeNote", {
                                            from: timeRange.from?.toLocaleTimeString(
                                                timeLocaleMap[languageKey],
                                                timeDisplayOptions
                                            ),
                                            to: timeRange.to?.toLocaleTimeString(
                                                timeLocaleMap[languageKey],
                                                timeDisplayOptions
                                            ),
                                        })}`}</p>
                                    )}
                                </div>
                            </FormGroup>
                        </ModalBody>
                    )
                    }
                    <ModalFooter className="justify-content-center">
                        <Button
                            color="primary"
                            type="submit"
                            disabled={technicianId === "" || !isSubmitting}>

                            {t("confirm")}
                        </Button>
                        <Button color="light" onClick={toggleModal} type="button">
                            {t("cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
export default withRouter(translate(ApproveModal));
