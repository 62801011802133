import React, {
  Fragment,
  useEffect,
  useState,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";


import DataTable from "react-data-table-component";

import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import { translate } from "react-switch-lang";


import { withRouter } from "react-router-dom";


import Loader from "../partials/Loader";
import NoData from "../partials/NoData";

import { getLanguages } from "../../services/language.service";
import environment from "../../environment";

const ViewLanguages = (props) => {
  // STATES Declaration
  const [data, setData] = useState();

  // UI/UX
  const [loading, setLoading] = useState(true);


  // Data Table data configuration
  const tableColumns = [
    {
      name: props.t("name"),
      selector: "name",

      center: true,
    },
    {
      name: props.t("culture"),
      selector: "culture",

      center: true,
    },
    {
      name: props.t("icon"),

      cell: (row) => (
        <img
          height="30px"
          width="30px"
          style={{ borderRadius: 50 }}
          src={row.logo}
          alt={row.name}
        />
      ),

      sortable: false,
      center: true,
    },
    {
      name: props.t("rtl"),
      // selector: 'rtl',
      cell: (row) =>
        row.rtl ? (
          <i className="fa fa-circle font-success f-12" />
        ) : (
          <i className="fa fa-circle font-danger f-12" />
        ),
      sortable: false,
      center: true,
    },
    {
      name: props.t("published"),
      // selector: 'published',
      cell: (row) =>
        row.published ? (
          <i className="fa fa-circle font-success f-12" />
        ) : (
          <i className="fa fa-circle font-danger f-12" />
        ),

      center: true,
    },
    {
      name: props.t("default"),
      // selector: 'defaut',
      cell: (row) =>
        row.default ? (
          <i className="fa fa-circle font-success f-12" />
        ) : (
          <i className="fa fa-circle font-danger f-12" />
        ),

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            props.history.push(
              `${process.env.PUBLIC_URL}/languages/edit/${row.id}`
            );
          }}
        >
          {props.t("edit")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            localStorage.setItem("languageId", row.id);
            localStorage.setItem("langugaeName", row.name);
            props.history.push(`${process.env.PUBLIC_URL}/resources/`);
          }}
        >
          {props.t("resources")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "130px",
    },
  ];



  useEffect(() => {
    setLoading(true);
    (async () => {
      const { body, ok } = await getLanguages();
      if (ok) {
        setData(body);
      } 
      
      setLoading(false);
    })();
  }, []);
  const RTLPaginationComponentOptions = {
    rowsPerPageText: 'عدد السجلات في الصفحة',
    rangeSeparatorText: 'من',
  };
  const LTRPaginationComponentOptions = {
    rowsPerPageText: 'Rows per page',
    rangeSeparatorText: 'of',
  };
  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("languages")}
        title={props.t("languagesText")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={() => {
                      props.history.push(
                        `${process.env.PUBLIC_URL}/languages/add`
                      );
                    }}
                    size="sm"
                  >
                    {props.t("addLanguagebtn")}
                  </Button>
                </CardTitle>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped
                  center
                  persistTableHead
                  pagination
                  paginationComponentOptions={
                    (localStorage.getItem(environment.LANGUAGE_KEY) === 'ar') ?
                      RTLPaginationComponentOptions
                      :
                      LTRPaginationComponentOptions
                  }
                  progressPending={loading}
                  progressComponent={<Loader table />}
                  noDataComponent={
                    <NoData message={props.t("noDataMessage")} />
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(ViewLanguages));
