import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Breadcrumb from "../../layout/breadcrumb/index";
import { deleteCity } from "../../services/city.service";
import { getPublishedLanguages } from "../../services/language.service";
import CreateCity from "./CreateCity";
import EditCity from "./EditCity";
import environment from "../../environment";
import ConfirmationModal from "../partials/ConfirmationModal";
import { KPTable } from "../partials/KPTable";
import { useDispatch } from "react-redux";
import { setCityIdToEdit } from "../../store/slices/citySlice";

const ViewCities = ({ t }) => {
  //Data from Backend
  const [cityId, setCityId] = useState("");
  const [city, setCity] = useState("");
  const [languages, setLanguages] = useState([]);
  const [requestOptions, setRequestOptions] = useState(
    {
      name: "",
      phoneNumber: "",
      pageIndex: 0,
      pageSize: 0,
      orderBy: "string",
      searchText: "string"
    }
  );
  //END

  // Modals
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  //END

  //UX/UI
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  //END

  // Store
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: t("city"),
      selector: "localizedName",

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            dispatch(setCityIdToEdit({ id: row.id }));
            toggleEditModal();
          }}
        >
          {t("edit")}
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setIsConfirm(true);
            setCityId(row.id);
          }}
          className="px-3"
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      button: true,
    },
  ];
  const getFilteredData = (value) => {
    if (value === "reset") {
      setRequestOptions(
        {
          name: "",
          phoneNumber: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          city: city,
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTableData();
  };
  const resetData = () => {
    setCity("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  useEffect(() => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        const languagesInfo = body.map((language) => {
          return {
            id: language.id,
            code: language.culture,
          };
        });
        setLanguages(languagesInfo);
      }
    })();
  }, []);

  //Modals Toggles
  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const toggleRefreshTableData = () => {
    setRefreshTableData(!refreshTableData);
  };
  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);

  return (
    <>
      <CreateCity
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModal}
        languages={languages}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshTableData}
      ></CreateCity>

      <EditCity
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        languages={languages}
        toggleRefreshTable={toggleRefreshTableData}
      ></EditCity>

      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={cityId}
        toggleRefreshData={toggleRefreshTableData}
        apiToCall={deleteCity}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb parent={t("cities")} title={t("viewCities")}></Breadcrumb>
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("city")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="city"
                              value={city}
                              onChange={(event) => {
                                setCity(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={toggleCreateModal}
                    size="sm"
                    disabled={submitting || languages.length === 0}
                  >
                    {t("addCity")}
                  </Button>
                </CardTitle>

                <KPTable
                  endpoint={`${environment.CITY_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTableData}
                // waiting for API
                // requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(ViewCities);
