import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { translate } from "react-switch-lang";
import { withRouter, useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import BasicRequestInformation from "./BasicRequestInformation";
import Tickets from "./Tickets";

import { getRequestById } from '../../services/maintenance.service';
import Loader from '../partials/Loader'
import ApproveModal from "./modals/ApproveModal";
import RejectModal from "./modals/RejectModal";
import Attachments from "./Attachments";
const MaintenanceDetails = (props) => {
    const [activeTab, setActiveTab] = useState("1");
    const [requestDetailsData, setRequestDetailsData] = useState([]);
    const { requestId } = useParams();
    const [actions, setActions] = useState([]);
    const { orderId } = useParams();
    // Claims
    const claimsData = JSON.parse(localStorage.getItem("newClaims"));

    // UI/UX
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setOpen] = useState(false);

    // Actions Modals
    const [isApprove, setIsApprove] = useState(false);
    const [isReject, setIsReject] = useState(false);
    // Functions
    const handleClaims = (claim) => {
        if (claimsData.claims.indexOf(claim) > -1) {
            return true;
        } else return false;
    };

    const handleAction = (actionId) => {
        console.log(actionId);
        switch (actionId) {
            case "Approve":
                setIsApprove(!isApprove);
                break;

            case "Reject":
                setIsReject(!isReject);
                break;
            default:
                return "state";
        }
    };
    const toggleApprove = () => setIsApprove(!isApprove);
    const toggleReject = () => setIsReject(!isReject);
    const [refreshData, setRefreshData] = useState([]);
    const toggleRefreshData = () => {
        setRefreshData(!refreshData);
    };
    const getData = () => {
        (async () => {
            setLoading(true);
            const { body, ok } = await getRequestById(requestId);
            if (ok) {
                console.log(body);
                setRequestDetailsData(body);
                setActions(body.actions);
            }
            else {
                console.log(body);

            }
            setLoading(false);
        })();
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshData, localStorage.getItem("lang")]);
    return (
        <Fragment>
            <ApproveModal
                isOpen={isApprove}
                toggleModal={toggleApprove}
                orderId={orderId}
                toggleRefreshData={toggleRefreshData}
            ></ApproveModal>
            <RejectModal
                isOpen={isReject}
                toggleModal={toggleReject}
                orderId={orderId}
                toggleRefreshData={toggleRefreshData}
            ></RejectModal>
            <Breadcrumb parent={props.t("orders")} title={props.t("maintenanceRequestDetails")}
                backTo="true"
            />
            <Container fluid={true}>
                <div className="user-profile social-app-profile">
                    <Row>
                        <Col sm="12">
                            <Card className="hovercard text-center">
                                <div className="d-flex info p-1">
                                    <Nav
                                        tabs
                                        className="justify-content-start border-tab tabs-scoial col-sm-7"
                                        type="button"
                                    >
                                        <NavItem className="nav" id="myTab" role="tablist">
                                            <NavLink
                                                tag="a"
                                                className={activeTab === "1" ? "active" : ""}
                                                onClick={() => setActiveTab("1")}
                                            >
                                                {props.t("basicInformation")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="nav" id="myTab" role="tablist">
                                            <NavLink
                                                tag="a"
                                                className={activeTab === "2" ? "active" : ""}
                                                onClick={() => setActiveTab("2")}
                                            >
                                                {props.t("attachments")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="nav" id="myTab" role="tablist">
                                            <NavLink
                                                tag="a"
                                                className={activeTab === "3" ? "active" : ""}
                                                onClick={() => setActiveTab("3")}
                                            >
                                                {props.t("tickets")}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <Nav
                                        className="justify-content-end border-tab tabs-scoial col-sm-5 p-2"
                                    >
                                        {actions.map((item, index) =>
                                            handleClaims(item.actionId) ? (
                                                item.platform === "Web" &&
                                                    (item.actionId === "Maintenance.Approve" ||
                                                        item.actionId === "Maintenance.Reject")
                                                    ? (
                                                        item.isActive ? (
                                                            <NavItem
                                                                className="nav"
                                                                id="myTab"
                                                                role="tablist"
                                                                key={index}>
                                                                <Button
                                                                    color="light"
                                                                    className="mr-1 btn-sm"
                                                                    onClick={() =>
                                                                        handleAction(item.actionId.substring(12))
                                                                    }
                                                                // onClick={`toggles${item.actionId}()`}
                                                                >
                                                                    {props.t(item.actionId.substring(12))}
                                                                </Button>
                                                            </NavItem>
                                                        ) : (
                                                            <div></div>
                                                        )
                                                    ) : (
                                                        <div></div>
                                                    )
                                            ) : (
                                                <div></div>
                                            )
                                        )}
                                    </Nav>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {/* Second Scenario */}
                    {!loading ? (
                        <TabContent activeTab={activeTab} className="tab-content">
                            <TabPane tabId="1">
                                <BasicRequestInformation data={requestDetailsData} />
                            </TabPane>
                            <TabPane tabId="2">
                                <Attachments
                                    data={requestDetailsData}

                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <Tickets
                                    data={requestDetailsData}
                                    toggleRefreshData={toggleRefreshData}
                                />
                            </TabPane>
                        </TabContent>
                    ) : (
                        <Card>
                            <Loader />
                        </Card>
                    )}
                </div>
            </Container>
        </Fragment>

    )
}

export default withRouter(translate(MaintenanceDetails)); 