import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.LOOKUP_ENDPOINT;


export const getAllLookups = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};

export const getLookupById = async (lookupId) => {
  const response = await Get(`${_endpoint}/Get?id=${lookupId}`, true);
  return response;
};

export const getMarbleCompanies = async () => {
  const response = await Get(
    `${_endpoint}/GetAll?lookupType=MarbleCompany`,
    true
  );
  return response;
};

export const searchForMarbleCompanies = async (requestBody) => {
  const response = await Post(`${_endpoint}/Search`, requestBody, true);
  return response;
};

export const createLookup = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const updateLookup = async (requestBody) => {
  const response = await Post(`${_endpoint}/Update`, requestBody, true);
  return response;
};

export const deleteLookup = async (id) => {
  const requestBody = { id };
  const response = await Post(`${_endpoint}/Delete`, requestBody, true);
  return response;
};
