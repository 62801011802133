import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createClient } from "../../services/client.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const CreateClient = ({
  isOpen,
  toggleModal = () => {},
  t,
  setSubmitting = () => {},
  toggleRefreshTable = () => {},
}) => {
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // Store
  const languageKey = useSelector((state) => state.language.key);
  const dispatch = useDispatch();

  const addNewClient = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const requestBody = {
      firstName,
      sureName: surName,
      phoneNumber,
    };

    (async () => {
      toggleModal();
      dispatch(setGlobalLoading({ status: true }));
      const { ok } = await createClient(requestBody);

      if (ok) {
        toast.success(t("clientCreatedSuccessfully"));
        toggleRefreshTable();
      }

      // Clear Values After Submission
      setFirstName("");
      setSurName("");
      setPhoneNumber("");

      setSubmitting(false);
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  const isSaveButtonDisabled = () => {
    return (
      firstName.length > 0 &&
      surName.length > 0 &&
      phoneNumber.length > 0 &&
      !phoneNumber.includes("_")
    );
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("createNewClient")}</ModalHeader>

        <Form className="form theme-form" onSubmit={addNewClient}>
          <ModalBody>
            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="firstName" className="col-4 mb-0">
                {t("firstName")}
              </Label>
              <Input
                className="col-6 mb-0"
                type="text"
                id="firstName"
                name="firstName"
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                value={firstName}
              ></Input>
            </FormGroup>

            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="sureName" className="col-4 mb-0">
                {t("surname")}
              </Label>
              <Input
                className="col-6 mb-0"
                type="text"
                id="sureName"
                name="sureName"
                onChange={(event) => {
                  setSurName(event.target.value);
                }}
                value={surName}
              ></Input>
            </FormGroup>

            <FormGroup className="row d-flex align-items-center">
              <Label htmlFor="phoneNumber" className="col-4 mb-0">
                {t("phoneNumber")}
              </Label>
              <InputMask
                className="col-6 mb-0 form-control"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                onChange={(event) => {
                  setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                }}
                mask="05 999 999 99"
                value={phoneNumber}
                style={{
                  direction: "ltr",
                  textAlign: languageKey === "ar" ? "end" : "start",
                }}
              ></InputMask>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              color="primary"
              type="submit"
              disabled={!isSaveButtonDisabled()}
            >
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default translate(CreateClient);
