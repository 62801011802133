import React from "react";

const NoData = ({ message = "" }) => {
  return (
    <div className="d-flex justify-content-center align-items-center py-4">
      <p className="fs-4">{message}</p>
    </div>
  );
};

export default NoData;
