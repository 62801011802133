import React from "react";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { rejectRequest } from "../../../services/maintenance.service";

import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const RejectModal = ({
    isOpen,
    toggleModal = () => { },
    t,
    toggleRefreshData = () => { },
}) => {
    // Store
    const requestId = useParams().requestId;
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        // toggleModal();
        dispatch(setGlobalLoading({ status: true }));

        const requestBody = {
            id: requestId,
            // id: localStorage.getItem("maintenanceId"),
            rejectReason: event.target.rejectionReason.value,
        };
        console.log(requestBody);
        (async () => {
            const { ok } = await rejectRequest(requestBody);
            if (ok) {
                setTimeout(() => {
                    toast.success(t("orderRejected"));
                }, 200);
                toggleModal();
                toggleRefreshData();
                localStorage.removeItem("maintenanceId")
            }

            dispatch(setGlobalLoading({ status: false }));
        })();
    };
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggleModal} centered>
                <ModalHeader>{t("rejectOrder")}</ModalHeader>
                <Form className="form theme-form" onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label className="col-sm-12 col-form-label">
                                {t("rejectionReasonMsg")}
                            </Label>
                            <textarea
                                className="form-control col-sm-12 col-form-label"
                                name="rejectionReason"
                                placeholder={t("rejectionReasonPlaceholder")}
                                required
                            ></textarea>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button
                            color="danger"
                            type="submit">
                            {t("confirm")}
                        </Button>
                        <Button color="light"
                            onClick={toggleModal}
                            type="button">
                            {t("cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
export default withRouter(translate(RejectModal));
