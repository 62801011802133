import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Popover,
  PopoverBody,
} from "reactstrap";
import { t } from "react-switch-lang";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "../../../KPComponents/partials/Loader";
import { getAllCities } from "../../../services/city.service";
import { getCityNeighborhoods } from "../../../services/neighborhood.service";
import { useSelector } from "react-redux";
import { getAllBranches } from "../../../services/branch.service";
import { isContractNumberExist } from "../../../services/order.service";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone-uploader";
import { createMaintenanceRequest } from "../../../services/maintenance-public.service";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";
import { toast } from "react-toastify";

const CreateCustomerOrder = () => {
  // Form Options
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  // Form Values
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({});
  const [selectedNeighborhood, setSelectedNeighborhood] = useState({});
  const [contractNumber, setContractNumber] = useState("");
  const [contractDate, setContractDate] = useState(new Date());
  const [contractImage, setContractImage] = useState([]);
  const [details, setDetails] = useState("");
  const [requestIssueImages, setRequestIssueImages] = useState([]);

  // UI/UX
  const [loading, setLoading] = useState(false);
  const [neighborhoodLoading, setNeighborhoodLoading] = useState(false);
  const [validContractNumber, setValidContractNumber] = useState(false);
  const [contractNumberCheckLoading, setContractNumberCheckLoading] =
    useState(false);

  // Store
  const dispatch = useDispatch();
  const languageKey = useSelector((state) => state.language.key);

  // Routing
  const history = useHistory();

  const handleCitySelect = (selection) => {
    if (selection.length > 0) {
      setSelectedCity(selection[0]);
    } else {
      setSelectedCity({});
      setNeighborhoods([]);
      setSelectedNeighborhood({});
    }
  };

  const handleTypeaheadChange = (selection, setFunction) => {
    if (selection.length > 0) {
      setFunction(selection[0]);
    } else {
      setFunction({});
    }
  };

  const checkContractNumber = (value) => {
    const REQUEST_PARAMS = {
      contractNumber: value,
    };
    setContractNumberCheckLoading(true);
    (async () => {
      const { body, ok } = await isContractNumberExist(REQUEST_PARAMS);
      if (ok) {
        setValidContractNumber(!body);
      }

      setContractNumberCheckLoading(false);
    })();
  };

  const createOrder = (event) => {
    event.preventDefault();

    const formData = new FormData();

    const formObject = {
      cityId: selectedCity.id,
      neighborhoodId: selectedNeighborhood.id,
      branchId: selectedBranch.id,
      contractNumber: `${selectedBranch.code}-${contractNumber}`,
      details,
      contractDate: contractDate
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("/"),
      contractImage: contractImage[0].file,
    };
    

    for (const [key, value] of Object.entries(formObject)) {
      formData.append(key, value);
    }

    if (requestIssueImages.length > 0) {
      requestIssueImages.forEach((item) => {
        formData.append("requestIssueImages", item.file, item.name);
      });
    } else {
      formData.append("requestIssueImages", "");
    }

    (async () => {
      dispatch(setGlobalLoading({ status: true }));
      const { ok } = await createMaintenanceRequest(formData);
      if (ok) {
        toast.success(t("orderAddedSuccessfuly"));
        dispatch(setGlobalLoading({ status: false }));
        history.push(`${process.env.PUBLIC_URL}/customer/home`);
      }
    })();
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      setSelectedCity({});
      const { body, ok } = await getAllCities();
      if (ok) {
        setCities(body);
      }

      (async () => {
        const { body, ok } = await getAllBranches();
        if (ok) {
          setBranches(body);
        }
        setLoading(false);
      })();
    })();
  }, [languageKey]);

  useEffect(() => {
    if (Object.keys(selectedCity).length > 0) {
      setNeighborhoodLoading(true);
      (async () => {
        const { body, ok } = await getCityNeighborhoods(selectedCity.id);
        if (ok) {
          setNeighborhoods(body);
        }
        setNeighborhoodLoading(false);
      })();
    }
  }, [selectedCity]);

  const createButtonDisabled = () => {
    return (
      Object.keys(selectedCity).length === 0 ||
      Object.keys(selectedNeighborhood).length === 0 ||
      Object.keys(selectedBranch).length === 0 ||
      contractNumber === "" ||
      !validContractNumber ||
      contractImage.length === 0 ||
      requestIssueImages.length === 0
    );
  };

  return (
    <Row className="position-relative">
      {loading ? (
        <Loader />
      ) : (
        <>
          <i
            className="fa fa-arrow-left back-icon"
            onClick={() => history.push(`${process.env.PUBLIC_URL}/customer/home`)}
          ></i>
          <Col sm={12} md={9} lg={7} className="mx-auto mt-5 p-5">
            <Card>
              <CardHeader>
                <h3 className="text-center">{t("addOrder")}</h3>
              </CardHeader>
              <form onSubmit={createOrder}>
                <CardBody>
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="city-typeahead"
                    >
                      {t("city")}
                    </Label>
                    <Typeahead
                      className="col-9 px-0"
                      id="city-typeahead"
                      name="city-typeahead"
                      options={cities}
                      placeholder={t("selectPlaceholder")}
                      labelKey={"localizedName"}
                      onChange={handleCitySelect}
                      clearButton
                    />
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="neighborhood-typeahead"
                    >
                      {t("neighborhood")}
                    </Label>
                    <Typeahead
                      className="col-9 px-0"
                      id="neighborhood-typeahead"
                      name="neighborhood-typeahead"
                      options={neighborhoods ?? []}
                      placeholder={
                        Object.keys(selectedCity).length > 0
                          ? t("selectPlaceholder")
                          : t("pleaseSelectCity")
                      }
                      labelKey={"localizedName"}
                      selected={
                        Object.keys(selectedNeighborhood).length > 0
                          ? [selectedNeighborhood]
                          : []
                      }
                      disabled={
                        Object.keys(selectedCity).length === 0 ||
                        neighborhoodLoading
                      }
                      onChange={(selection) => {
                        handleTypeaheadChange(
                          selection,
                          setSelectedNeighborhood
                        );
                      }}
                      clearButton
                      isLoading={neighborhoodLoading}
                    />
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="branches-typeahead"
                    >
                      {t("branch")}
                    </Label>
                    <Typeahead
                      className="col-9 px-0"
                      id="branches-typeahead"
                      name="branches-typeahead"
                      options={branches}
                      placeholder={t("selectPlaceholder")}
                      labelKey={"name"}
                      onChange={(selection) => {
                        handleTypeaheadChange(selection, setSelectedBranch);
                        if (selection.length === 0) {
                          setContractNumber("");
                        }
                      }}
                      clearButton
                    />
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="contractNumber"
                      className="col-3 col-from-label label-required"
                    >
                      {t("contractNumber")}
                    </Label>

                    <InputGroup className="col-9 p-0">
                      <Input
                        className={`form-control ${
                          !validContractNumber &&
                          contractNumber !== "" &&
                          !contractNumberCheckLoading
                            ? "is-invalid"
                            : ""
                        } ${
                          validContractNumber &&
                          contractNumber !== "" &&
                          !contractNumberCheckLoading
                            ? "is-valid"
                            : ""
                        }`}
                        id="contractNumber"
                        name="contractNumber"
                        type="text"
                        onChange={(event) => {
                          setContractNumber(event.target.value);
                          setContractNumberCheckLoading(true);
                        }}
                        value={contractNumber}
                        onBlur={(event) =>
                          checkContractNumber(
                            `${selectedBranch.code}-${event.target.value}`
                          )
                        }
                        placeholder={
                          Object.keys(selectedBranch).length > 0
                            ? ""
                            : t("pleaseSelectBranch")
                        }
                        disabled={Object.keys(selectedBranch).length === 0}
                        style={
                          Object.keys(selectedBranch).length > 0
                            ? {
                                direction: "ltr",
                              }
                            : {}
                        }
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ direction: "ltr" }}>
                          {Object.keys(selectedBranch).length > 0
                            ? `${selectedBranch.code}-`
                            : "XX-"}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Popover
                      className="bg-danger"
                      target="contractNumber"
                      isOpen={
                        !validContractNumber &&
                        contractNumber !== "" &&
                        !contractNumberCheckLoading
                      }
                      placement="top"
                    >
                      <PopoverBody className="bg-danger">
                        {t("contractNumberExist")}
                      </PopoverBody>
                    </Popover>
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="contractNumber"
                      className="col-3 col-from-label label-required"
                    >
                      {t("contractDate")}
                    </Label>
                    <div className="col-9 p-0 mb-0">
                      <DatePicker
                        name="date"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={contractDate}
                        onChange={(value) => {
                          setContractDate(value);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="contractImage"
                      className="col-3 col-from-label label-required"
                    >
                      {t("contractImage")}
                    </Label>
                    <div className="col-9 p-0 m-0 light-dzu">
                      <Dropzone
                        accept="image/*, application/pdf"
                        name="contractImage"
                        id="contractImage"
                        inputContent={t("dropContent")}
                        inputWithFilesContent={t("add")}
                        multiple={false}
                        maxFiles={1}
                        onChangeStatus={(
                          fileWithMeta,
                          status,
                          filesWithMeta
                        ) => {
                          if (status === "done" || status === "removed") {
                            setContractImage(Array.from(filesWithMeta));
                          }
                          if (status === "removed")
                            if (filesWithMeta.length === 1) {
                              setContractImage([]);
                            }
                        }}
                      />
                    </div>
                  </FormGroup>
                </CardBody>
                <CardHeader>
                  <h3 className="text-center">{t("addMaintenanceRequest")}</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="requestIssueDetails"
                      className="col-3 col-from-label"
                    >
                      {t("problemDetails")}
                    </Label>

                    <Input
                      className="col-9 theme-secondary-background"
                      type="textarea"
                      name="requestIssueDetails"
                      id="requestIssueDetails"
                      value={details}
                      onChange={(event) => setDetails(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="problemImages"
                      className="col-3 col-from-label label-required"
                    >
                      {t("problemImages")}
                    </Label>
                    <div className="col-9 p-0 m-0 light-dzu">
                      <Dropzone
                        accept="image/*"
                        inputContent={t("dropContent")}
                        inputWithFilesContent={t("add")}
                        onChangeStatus={(
                          fileWithMeta,
                          status,
                          filesWithMeta
                        ) => {
                          if (status === "done" || status === "removed") {
                            setRequestIssueImages(filesWithMeta);
                          }
                          if (status === "removed")
                            if (filesWithMeta.length === 1) {
                              setRequestIssueImages([]);
                            }
                        }}
                      />
                    </div>
                  </FormGroup>
                </CardBody>
                <CardFooter className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={createButtonDisabled()}
                  >
                    {t("add")}
                  </Button>
                  <Button
                    color="light"
                    type="reset"
                    onClick={() => history.goBack()}
                  >
                    {t("cancel")}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CreateCustomerOrder;
