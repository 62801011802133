import { createSlice } from "@reduxjs/toolkit";
import { setLanguage } from "react-switch-lang";
import environment from "../../environment";
const languageSlice = createSlice({
  name: "language",
  initialState: { key: localStorage.getItem(environment.LANGUAGE_KEY) ?? "ar" },
  reducers: {
    switchLanguage: (state, action) => {
      state.key = action.payload.key;
      localStorage.setItem(environment.LANGUAGE_KEY, action.payload.key);

      setLanguage(action.payload.key);

      if (action.payload.key === "ar") {
        document.body.setAttribute("dir", "rtl");
        localStorage.setItem(environment.DIRECTION_KEY, "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
        localStorage.setItem(environment.DIRECTION_KEY, "ltr");
      }
    },
  },
});

export const { switchLanguage } = languageSlice.actions;

export default languageSlice.reducer;
