import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.MAINTENANCE_ENDPOINT;


export const getAllMaintenanceRequests = async () => {
    const response = await Get(
        `${_endpoint}/GetAll`,
        true
    );
    return response;
};
export const getRequestById = async (requestId) => {
    const response = await Get(
        `${_endpoint}/Get?id=${requestId}`,
        true
    );
    return response;
};
export const getAssignInstallingTechnical = async (orderId) => {
    const response = await Get(
        `${_endpoint}/LoadAssignInstallingVisitPage?requestId=${orderId}`,
        true
    );
    return response;
};
export const approveRequest = async (requestBody) => {
    const response = await Post(
        `${_endpoint}/ApproveRequest`,
        requestBody,
        true
    );
    return response;
};
export const rejectRequest = async (requestBody) => {
    const response = await Post(
        `${_endpoint}/RejectRequest`,
        requestBody,
        true);
    return response;
};
export const searchMaintenanceRequests = async (requestBody) => {
    const response = await Post(
        `${_endpoint}/Search`,
        requestBody,
        true
    );
    return response;
};
export const closeMaintenanceTicket = async (ticketId) => {
    const data = {
        id: ticketId,
    };
    const response = await Post(
        `${_endpoint}/CloseMaintenanceTicket`,
        data,
        true
    );
    return response;
};
export const getMaintenanceRequestStatus = async (orderId) => {
    const response = await Get(
        `${_endpoint}/GetMaintenanceRequestStatus`,
        true
    );
    return response;
};