import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.NEIGHBORHOOD_ENDPOINT;


export const getAllNeighborhoods = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};

export const getCityNeighborhoods = async (cityId) => {
  const response = await Get(`${_endpoint}/GetAll?cityId=${cityId}`, true);
  return response;
};

export const getNeighborhoodById = async (neighborhoodId) => {
  const response = await Get(`${_endpoint}/Get?id=${neighborhoodId}`, true);
  return response;
};

export const createNeighborhood = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const updateNeighborhood = async (requestBody) => {
  const resoponse = await Post(`${_endpoint}/Update`, requestBody, true);
  return resoponse;
};

export const deleteNeighborhood = async (neighborhoodId) => {
  const requestBody = {
    id: neighborhoodId,
  };
  const response = await Post(`${_endpoint}/Delete`, requestBody, true);
  return response;
};
