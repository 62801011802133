import { createSlice } from "@reduxjs/toolkit";

const neighborhoodSlice = createSlice({
  name: "neighborhood",
  initialState: {
    id: null,
  },
  reducers: {
    setNeighborhoodIdToEdit: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

export const { setNeighborhoodIdToEdit } = neighborhoodSlice.actions;

export default neighborhoodSlice.reducer;
