import React from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { requestOrderUpdate, RequiredOrderMeasurementUpdate } from "../../../services/order.service";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";
const RequestOrderUpdateModal = ({
  isOpen,
  toggleModal = () => { },
  t,
  toggleRefreshData = () => { },
  orderId,
  type
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      orderId: orderId,
      updateNotes: event.target.notes.value,
    };


    (async () => {
      const { ok } = type === 'orderUpdate' ?
        (await requestOrderUpdate(requestBody))
        :
        (await RequiredOrderMeasurementUpdate(requestBody))
      if (ok) {
        setTimeout(() => {
          toast.success(t("requestOrderUpdateDone"));
        }, 200);
        toggleRefreshData();
      }
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>
          {type === 'orderUpdate' ?
            (t("RequestOrderUpdate"))
            :
            (t("RequredOrderMeasurmentUpdate"))}
        </ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label className="col-sm-12 col-form-label">
                {t("requestOrderUpdateModalMsg")}
              </Label>
              <textarea
                className="col-sm-12 col-form-label"
                name="notes"
                placeholder={t("editNotesPlaceholder")}
                required
              ></textarea>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="success" type="submit">
              {t("confirm")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(RequestOrderUpdateModal));
