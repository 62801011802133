import React, { useEffect } from 'react'
import environment from '../../../environment';
import { checkOrders } from '../../../services/maintenance-public.service';
import Loader from '../Loader'
const CheckOrders = (props) => {


    const checkOrdersFunction = (value) => {
        // event.preventDefault();

        (async () => {
            const { body, ok } = await checkOrders();
            console.log(body)
            console.log(ok)
            if (ok) {
                if (body.orderId) {
                    console.log(body)
                    document.location.href = `${environment.customerRedirection[body.result]
                        }/${body.orderId}`;
                } else {
                    document.location.href = `${environment.customerRedirection[body.result]
                        }`;
                }
            }
            else {
                localStorage.clear();
                props.history.push(`${process.env.PUBLIC_URL}/register`);
            }
        })();

    };
    useEffect(() => {
        checkOrdersFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("lang")]);

    return (
        <div>
            <Loader />
        </div>
    )
}


export default CheckOrders
