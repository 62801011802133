import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import DataTable from "react-data-table-component";
import { translate } from "react-switch-lang";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getPaginatedResources } from "../../services/locale-resource.service";
import environment from "../../environment";
const ViewResource = (props) => {
  const [data, setData] = useState();

  const languageId = localStorage.getItem("languageId");

  const apiData = {
    languageId: languageId,
    pageIndex: 1,
    pageSize: 10,
    orderBy: null,
    searchText: null,
  };

  const tableColumns = [
    {
      name: props.t("name"),
      selector: "name",

      center: true,
    },
    {
      name: props.t("value"),
      selector: "value",

      center: true,
    },
    {
      name: props.t("language"),
      // selector: 'value',
      cell: (row) => localStorage.getItem("langugaeName"),

      center: true,
    },
    {
      cell: (row) => (
        <Button
          size="sm"
          color="light"
          onClick={() => {
            // localStorage.setItem("editResourceData", JSON.stringify(row))
            console.log(row);
            props.history.push(
              `${process.env.PUBLIC_URL}/resources/edit/${row.id}`
            );
          }}
        >
          {props.t("edit")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    (async () => {
      const { body, ok } = await getPaginatedResources(apiData);
      if (ok) {
        setData(body);
      }
    })();
  }, [apiData]);
  const RTLPaginationComponentOptions = {
    rowsPerPageText: "عدد السجلات في الصفحة",
    rangeSeparatorText: "من",
  };
  const LTRPaginationComponentOptions = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
  };

  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("resources")}
        title={props.t("viewResources")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={data}
                  width="auto"
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  // contextActions={contextActions}
                  // onSelectedRowsChange={handleRowSelected}
                  // clearSelectedRows={toggleCleared}
                  pagination={true}
                  paginationComponentOptions={
                    localStorage.getItem(environment.LANGUAGE_KEY) === "ar"
                      ? RTLPaginationComponentOptions
                      : LTRPaginationComponentOptions
                  }
                  // onRowClicked={setEditRow}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(ViewResource));
