import React from "react";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createNeighborhood } from "../../services/neighborhood.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const CreateNeighborhood = ({
  isOpen,
  toggleModal = () => {},
  t,
  setSubmitting = () => {},
  toggleRefreshTable = () => {},
  languages = [],
  cities = [],
}) => {
  // Store
  const dispatch = useDispatch();
  // Dynamic generation for Name inputs (Equal to the number of published languages)
  let languagesForm;
  if (languages.length > 0) {
    languagesForm = languages.map((language) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.code}
        >
          <Label htmlFor={`name_${language.code}`} className="col-4 mb-0">{`${t(
            "name"
          )} (${language.code.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            required
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <div></div>;
  }
  //END

  // Generate Select input with the name of the available cities
  let citiesSelector;
  if (cities.length > 0) {
    citiesSelector = (
      <FormGroup className="row d-flex align-items-center">
        <Label htmlFor="city" className="col-4 mb-0">
          {t("city")}
        </Label>
        <Input type="select" id="city" name="city" className="col-6 mb-0">
          {cities.map((city, index) => (
            // We turn city name to underscored lowercase
            <option
              value={city.name.toLowerCase().replace(/\s+/g, "_")}
              key={index}
            >
              {city.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  } else {
    citiesSelector = <div></div>;
  }
  //END

  const addNewNeighborhood = (event) => {
    event.preventDefault();
    setSubmitting(true);
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const translations = [];

    languages.forEach((language, index) => {
      translations.push({
        // index +1 because the first input is select input
        name: event.target[index + 1].value,
        languageId: language.id,
      });
    });

    const cityId = cities.filter((city) => {
      console.log(city);
      console.log(event.target[0].value);
      return (
        city.name.toLowerCase().replace(/\s+/g, "_") === event.target[0].value
      );
    })[0].id;

    console.log(cityId);

    const requestBody = {
      translations: translations,
      cityId,
    };
    (async () => {
      const { ok } = await createNeighborhood(requestBody);

      if (ok) {
        toast.success(t("addNeighborhoodSuccess"));
      }

      setSubmitting(false);
      toggleRefreshTable();
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("addNewNeighborhood")}</ModalHeader>
        <Form className="form theme-form" onSubmit={addNewNeighborhood}>
          <ModalBody>
            {citiesSelector}
            {languagesForm}
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" type="submit">
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default translate(CreateNeighborhood);
