import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { t } from "react-switch-lang";

import environment from "../../../environment";
import { Post } from "../../../services/http.service";
import Loader from "../Loader";
import NoData from "../NoData";

const KPTable = ({
  endpoint = "",
  columns = [],
  pageSize = environment.PAGINATION_PAGE_SIZE,
  refreshData = true,
  requestOptions = {},
}) => {
  const [data, setData] = useState([]);

  // Pagination
  const [pageIndex, setPageIndex] = useState(0);
  const [dynamicPageSize, setDynamicPageSize] = useState(pageSize);
  const [totalRows, setTotalRows] = useState(pageSize);
  const rowsPerPageOptions = [5, 10, 30, 50, 100];

  // Store
  const languageKey = useSelector((state) => state.language.key);

  // CUSTOM STYLE to fix alignment problem
  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
      },
    }
  }
  // UI/UX
  const [loading, setLoading] = useState();
  const RTLPaginationComponentOptions = {
    rowsPerPageText: "عدد السجلات في الصفحة",
    rangeSeparatorText: "من",
  };
  const LTRPaginationComponentOptions = {
    rowsPerPageText: "Rows per page",
    rangeSeparatorText: "of",
  };

  const onPageChange = (value) => {
    setPageIndex(value - 1);
  };

  const onChangeNumberOfRows = (value) => {
    setDynamicPageSize(value);
  };

  useEffect(() => {
    const REQUEST_OPTIONS = {
      ...requestOptions,
      pageIndex,
      pageSize: dynamicPageSize,
    };
    if (endpoint) {
      setLoading(true);
      (async () => {
        const { body, ok } = await Post(
          endpoint,
          REQUEST_OPTIONS,
          localStorage.getItem(environment.TOKEN_KEY)
        );

        if (ok) {
          setLoading(false);
          setData(body.items);
          setTotalRows(body.totalNumberOfRecords);
          console.log(body);
        }
      })();
    }
    //eslint-disable-next-line
  }, [endpoint, refreshData, pageIndex, dynamicPageSize, languageKey]);

  return (
    <DataTable
      columns={columns}
      data={data}
      striped
      center
      persistTableHead
      progressPending={loading}
      progressComponent={<Loader table />}
      noDataComponent={<NoData message={t("noDataMessage")} />}
      pagination
      paginationServer
      paginationPerPage={pageSize}
      onChangePage={onPageChange}
      onChangeRowsPerPage={onChangeNumberOfRows}
      paginationTotalRows={totalRows}
      paginationComponentOptions={
        languageKey === "ar"
          ? RTLPaginationComponentOptions
          : LTRPaginationComponentOptions
      }
      paginationRowsPerPageOptions={rowsPerPageOptions}
      customStyles={customStyles}
    ></DataTable>
  );
};

export default KPTable;
