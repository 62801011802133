import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { changeAccountPassword } from "../../services/account.service";

const Profile = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matching, setMatching] = useState(true);
  const [oldMatching, setOldMatching] = useState(false);

  const [toggleOldPassword, setToggleOldPassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

  const handleChangePassword = () => {
    // event.preventDefault();
    const requestBody = {
      currenPassword: oldPassword,
      newPassword: newPassword,
      newPasswordConfirmation: confirmPassword,
    };
    (async () => {
      const { body, ok } = await changeAccountPassword(requestBody);
      if (ok) {
        console.log(body);
        toast.success(props.t("passwordChangedSuccessfully"));
        props.history.push(`${process.env.PUBLIC_URL}/orders`);
      }
    })();
  };

  const handleOldMatching = (pp) => {
    console.log(pp.length);
    if (pp.length > 0) setOldMatching(true);
    else setOldMatching(false);
  };
  const handleNewMatching = (pp) => {
    if (confirmPassword === pp) setMatching(true);
    else setMatching(false);
  };
  const handleConfirmMatching = (pp) => {
    if (newPassword === pp) setMatching(true);
    else setMatching(false);
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <h4>{props.t("changePassword")}</h4>

                  <FormGroup>
                    <Label className="col-form-label">
                      {props.t("currentPassword")}
                    </Label>
                    <Input
                      className="form-control"
                      type={toggleOldPassword ? "text" : "password"}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                        handleOldMatching(e.target.value);
                      }}
                      defaultValue={oldPassword}
                      required
                    />
                    <div
                      className="show-hide"
                      onClick={() => setToggleOldPassword(!toggleOldPassword)}
                    >
                      <span className={toggleOldPassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label className="col-form-label">
                      {props.t("newPassword")}
                    </Label>
                    <Input
                      className="form-control"
                      type={toggleNewPassword ? "text" : "password"}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        handleNewMatching(e.target.value);
                      }}
                      defaultValue={newPassword}
                      required=""
                    />
                    <div
                      className="show-hide"
                      onClick={() => setToggleNewPassword(!toggleNewPassword)}
                    >
                      <span className={toggleNewPassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <FormGroup className="">
                    <Label className="col-form-label">
                      {props.t("confirmPassword")}
                    </Label>
                    <Input
                      className={
                        matching
                          ? "form-control"
                          : "form-control is-invalid border-danger"
                      }
                      type={toggleConfirmPassword ? "text" : "password"}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        handleConfirmMatching(e.target.value);
                      }}
                      defaultValue={confirmPassword}
                      required=""
                    />
                    <div
                      className="show-hide"
                      onClick={() =>
                        setToggleConfirmPassword(!toggleConfirmPassword)
                      }
                    >
                      <span
                        className={toggleConfirmPassword ? "" : "show"}
                      ></span>
                    </div>
                  </FormGroup>
                  <span className="text-danger">
                    {matching ? "" : props.t("notMatchMsg")}
                  </span>

                  <div className="form-group mb-0">
                    <Button
                      color="primary"
                      className="btn-block"
                      onClick={() => handleChangePassword()}
                      disabled={!matching || !oldMatching}
                    >
                      {props.t("changePassword")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(translate(Profile));
