import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { firebase_app, auth0 } from "./data/config";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./store";
import App from "./components/app";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";
import ConfigDB from "./data/customizer/config";
import {
  configureFakeBackend,
  authHeader,
  handleresponse,
} from "./services/fack.backend";

// Ahmad
// KP Components
import TestKP from "./KPComponents/kp/TestKP";
import { useJwt } from "react-jwt";
import CustomerRegister from "./customerComponents/auth/Register";
import CustomerVerification from "./customerComponents/auth/Verification";
import CustomerData from "./customerComponents/auth/CustomerData";

// Customer

import ViewCustomerOrders from "./customerComponents/pages/Orders/ViewCustomerOrders";
import CustomerApp from "./customerComponents/CustomerApp";

// get ProfileInfo
import { getProfileInfo } from "../src/services/users.srvices";

// Signin page
import LoginPage from "./auth/signin";

// Authentication
import Login from "./pages/authentication/login";
import LoginWithBgImage from "./pages/authentication/loginWithBgImage";
import LoginWithBgVideo from "./pages/authentication/loginWithBgVideo";
import LoginWithValidation from "./pages/authentication/loginwithValidation";
import Register from "./pages/authentication/register";
import RegisterWithBgImage from "./pages/authentication/registerWithBgImage";
import RegisterWithBgVideo from "./pages/authentication/registerWithBgVideo";
import UnlockUser from "./pages/authentication/unlockUser";
import Forgetpwd from "./pages/authentication/forgetpwd";
import Resetpwd from "./pages/authentication/resetpwd";

// Error page
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Comming soo
import Comingsoon from "./pages/comingSoon/comingsoon";
import ComingsoonImg from "./pages/comingSoon/comingsoonImg";
import ComingsoonVideo from "./pages/comingSoon/comingsoonVideo";

// Maintenanc
import Maintenance from "./pages/maintenance";

import Callback from "./auth/callback";
import { classes } from "./data/layouts";
import SiteSetting from "./KPComponents/siteSetting/SiteSetting";

import environment from "./environment";
import { setLanguage } from "react-switch-lang";
import Start from "./KPComponents/Start";
import OrderCustomerDetails from "./customerComponents/pages/Orders/OrderCustomerDetails";
import MaintenanceRequestDetails from "./customerComponents/pages/Maintenance/MaintenanceRequestDetails";
import CreateCustomerOrder from "./customerComponents/pages/Orders/CreateCustomerOrder";
import CheckOrders from "./KPComponents/partials/checkOrders/CheckOrders";

// import { useDispatch } from "react-redux";
// import { setProfileName } from "./store/slices/profile.slice";

// setup fake backend
configureFakeBackend();

const Root = (props) => {
  // Ahmad
  const claims = localStorage.getItem("newClaims");

  const [anim, setAnim] = useState("");
  // const [Ahmad, setAamad] = useState(true);
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem(environment.TOKEN_KEY) !== undefined || null
  );
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const language = localStorage.getItem(environment.LANGUAGE_KEY) ?? "ar";
  const direction = localStorage.getItem(environment.DIRECTION_KEY) ?? "rtl";
  const theme = localStorage.getItem(environment.THEME_KEY);

  if (!localStorage.getItem(environment.LANGUAGE_KEY)) {
    localStorage.setItem(environment.LANGUAGE_KEY, language);
  }
  if (!localStorage.getItem(environment.DIRECTION_KEY)) {
    localStorage.setItem(environment.DIRECTION_KEY, direction);
  }

  useEffect(() => {
    setLanguage(language);
    document.body.className = theme;

    document.body.setAttribute(environment.DIRECTION_KEY, direction);
    if (theme === "dark-only") {
      document.documentElement.style.setProperty("--theme-color", "#ffffffb3");
      document.documentElement.style.setProperty(
        "--theme-background",
        "#262932"
      );
      document.documentElement.style.setProperty(
        "--theme-secondary-background",
        "#1d1e26"
      );
    }
  }, [direction, theme, language]);
  // useEffect(() => {

  //     const requestOptions = { method: 'GET', headers: authHeader() };
  //     fetch('/users', requestOptions).then(handleresponse)
  //     setAnim(animation)
  //     firebase_app.auth().onAuthStateChanged(setCurrentUser);
  //     setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
  //     console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
  //     console.disableYellowBox = true;
  //     return function cleanup() {
  //         abortController.abort();
  //     }

  //     // eslint-disable-next-line
  //   }, []);
  return (
    <Fragment>
      <Provider store={store}>
        <Auth0Provider
          domain={auth0.domain}
          clientId={auth0.clientId}
          redirectUri={auth0.redirectUri}
        >
          <Start />
          <BrowserRouter basename={`/`}>
            <Switch>
              {/* Ahmad Space */}
              <Route
                path={`${process.env.PUBLIC_URL}/order/check`}
                component={CheckOrders}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/register`}
                component={CustomerRegister}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/verification`}
                component={CustomerVerification}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/customer/data`}
                component={CustomerData}
              />
              {/* end of Ahmad space */}
              <Route path={`${process.env.PUBLIC_URL}/customer`}>
                <CustomerApp>
                  <TransitionGroup>
                    <CSSTransition
                      timeout={100}
                      classNames={anim}
                      unmountOnExit
                    >
                      <Switch>
                        <Route
                          path={`${process.env.PUBLIC_URL}/customer/home`}
                          exact
                          component={ViewCustomerOrders}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/customer/orders/create`}
                          component={CreateCustomerOrder}
                        />

                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/customer/orders/:id`}
                          component={OrderCustomerDetails}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/customer/maintenance/:id`}
                          component={MaintenanceRequestDetails}
                        />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </CustomerApp>
              </Route>

              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginPage}
              />
              {/* <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} /> */}
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/login`}
                component={Login}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg1`}
                component={LoginWithBgImage}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg2`}
                component={LoginWithBgVideo}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithValidation`}
                component={LoginWithValidation}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signup`}
                component={Register}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg1`}
                component={RegisterWithBgImage}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg2`}
                component={RegisterWithBgVideo}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/forgetPwd`}
                component={Forgetpwd}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`}
                component={UnlockUser}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/resetPwd`}
                component={Resetpwd}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error400`}
                component={Error400}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error401`}
                component={Error401}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error403`}
                component={Error403}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error500`}
                component={Error500}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error503`}
                component={Error503}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`}
                component={Comingsoon}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonImg`}
                component={ComingsoonImg}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonVideo`}
                component={ComingsoonVideo}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/pages/maintenance`}
                component={Maintenance}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/callback`}
                render={() => <Callback />}
              />

              {jwt_token ? (
                <>
                  <App>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      render={() => {
                        return (
                          <Redirect to={`${process.env.PUBLIC_URL}/orders`} />
                        );
                      }}
                    />
                    <TransitionGroup>
                      <Switch>
                        {routes.map(({ path, Component, claim }) => (
                          <Route
                            key={path}
                            exact
                            path={`${process.env.PUBLIC_URL}${path}/`}
                          >
                            {({ match }) => (
                              <CSSTransition
                                in={match != null}
                                timeout={100}
                                classNames={anim}
                                unmountOnExit
                              >
                                {claims?.includes(claim) ? (
                                  <div>
                                    <Component />
                                  </div>
                                ) : (
                                  <div>
                                    <Error403 />
                                  </div>
                                )}
                              </CSSTransition>
                            )}
                          </Route>
                        ))}
                        <Route
                          path={`${process.env.PUBLIC_URL}/errors/404`}
                          component={Error404}
                        ></Route>
                        {/* <Route>
                          <Redirect
                            to={`${process.env.PUBLIC_URL}/errors/404`}
                          />
                        </Route> */}
                      </Switch>
                    </TransitionGroup>
                  </App>
                </>
              ) : (
                <>
                  {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={() => {
                      return (
                        <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                      );
                    }}
                  /> */}
                  <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                </>
              )}
              <Redirect to={`${process.env.PUBLIC_URL}/login}`} />
            </Switch>
          </BrowserRouter>
        </Auth0Provider>
      </Provider>
    </Fragment>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
