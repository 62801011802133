import environment from "../environment";
import { Get } from "./http.service";

const _endpoint = environment.CLAIM_ENDPOINT;


export const getAllClaimsWithRoles = async (id) => {
    const response = await Get(`${_endpoint}/GetAllWithRoles`, true);
    return response;
};






