import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    id: null,
  },
  reducers: {
    setBranchIdToEdit: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

export const { setBranchIdToEdit } = branchSlice.actions;

export default branchSlice.reducer;
