import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import {
  Home,
  ArrowRightCircle,
  ArrowRight,
  CornerDownRight
} from 'react-feather';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { translate } from 'react-switch-lang';
const Breadcrumbs = ({ t, title = "", parent = "", backTo = false }) => {
  let history = useHistory();
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <h3>{title}</h3>
              {backTo &&
                <div className='mt-2 d-flex text-primary'
                  onClick={history.goBack}
                  role="button"
                >
                  <ArrowRightCircle
                    className='pr-2'
                  />
                  <h6
                  > {t("backToMenu") + parent}</h6>
                </div>
              }

            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/orders/`}><Home /></Link></BreadcrumbItem>
                <BreadcrumbItem>{parent}</BreadcrumbItem>
                <BreadcrumbItem active>{title}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default translate(Breadcrumbs);