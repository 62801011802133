import { toast } from "react-toastify";
import { t } from "react-switch-lang";

const ERRORS_OBJECT = {
  400: "400Error",
  401: "401Error",
  403: "403Error",
  404: "404Error",
  500: "500Error",
};

export const handleError = ({ body, status, method }) => {
  if (Object.keys(body ?? {}).length > 0) {
    Object.keys(body).forEach((key) => {
      toast.error(`${body[key]}`);
    });
  } else {
    if (!(method === "GET" && status === 404)) {
      toast.error(`${t(ERRORS_OBJECT[status])}`);
    } else {
      window.location.replace(`${process.env.PUBLIC_URL}/errors/404`);
    }
  }
  /* if (status === 401) {
    setTimeout(() => {
      localStorage.clear();
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }, 1000);
  } */
};
