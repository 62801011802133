import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { translate } from "react-switch-lang";
import { Button, Input, Table } from "reactstrap";
import environment from "../../../environment";

const AccessoriesTable = ({
  t,
  options = {},
  accessoriesValue = [{}],
  setAccessoriesValue = () => {},
  setIsValid = () => {},
}) => {
  const [tableTotal, setTableTotal] = useState(0);
  const [tableOptions, setTableOptions] = useState(options);

  const [zeroError, setZeroError] = useState(false);

  const numberFormat = Intl.NumberFormat();
  const emptyOption = {
    localizedName: "",
    price: 1,
    quantity: 1,
    notes: "",
  };

  const tableRows = accessoriesValue.map((row, index) => (
    <tr key={index}>
      <td className="col-3">
        <Typeahead
          className="px-0"
          placeholder={t("selectPlaceholder")}
          options={tableOptions}
          labelKey={"localizedName"}
          id={`accessories-menu-${index}`}
          defaultSelected={[row]}
          onChange={(option) => handleSelectChange(option, index, row?.id)}
          clearButton
        ></Typeahead>
      </td>
      <td className="col-1">
        <Input
          type="number"
          value={row?.quantity}
          onChange={(event) => handleQuantityChange(event, index)}
          min={1}
        ></Input>
      </td>
      <td className="col-2">
        <Input
          type="text"
          value={row?.price}
          onChange={(event) => handlePriceChange(event.target.value, index)}
        ></Input>
      </td>
      <td className="col-3">
        <Input
          type="text"
          value={row?.notes}
          onChange={(event) => handleNotesChange(event, index)}
        />
      </td>
      <td className="col-3">
        <div className="d-flex w-100">
          <Input
            type="string"
            disabled
            className="w-75"
            value={`${numberFormat.format(row?.quantity * row?.price)} ${t(
              "currency"
            )}`}
          ></Input>
          <Button
            color="danger"
            className="w-25 d-flex justify-content-center align-items-center"
            style={{ marginInlineStart: 4 }}
            onClick={() => onDelete(row, index)}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      </td>
    </tr>
  ));

  const handleSelectChange = (option, index, changedId) => {
    const newOption = option.length
      ? { ...option[0], quantity: 1, notes: "" }
      : { ...emptyOption };
    setAccessoriesValue((prev) => {
      prev[index] = newOption;
      return [...prev];
    });
    if (changedId) {
      setTableOptions((prev) => {
        const newTableOptions = prev.map((tableOption) => {
          if (tableOption.id === changedId) {
            return { ...tableOption, disabled: false };
          }
          return tableOption;
        });
        return [...newTableOptions];
      });
    }
    if (newOption.id) {
      setTableOptions((prev) => {
        const newTableOptions = prev.map((tableOption) => {
          if (tableOption.id === newOption.id) {
            return { ...tableOption, disabled: true };
          }
          return tableOption;
        });
        return [...newTableOptions];
      });
    }
  };

  const handleZeroValue = (value) => {
    if (+value === 0) {
      setZeroError(true);
      setIsValid(false);
    }
  };

  const handleQuantityChange = (event, index) => {
    setAccessoriesValue((prev) => {
      prev[index].quantity = event.target.value;
      return [...prev];
    });
    handleZeroValue(event.target.value);
  };

  const handlePriceChange = (value, index) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setAccessoriesValue((prev) => {
        prev[index].price = value === "" ? 0 : value;
        return [...prev];
      });
      handleZeroValue(value === "" ? 0 : value);
    }
  };

  const handleNotesChange = (event, index) => {
    setAccessoriesValue((prev) => {
      prev[index].notes = event.target.value;
      return [...prev];
    });
  };

  const onAdd = () => {
    setAccessoriesValue((prev) => [...prev, emptyOption]);
  };

  const onDelete = (row, index) => {
    setTableOptions((prev) => {
      const newTableOptions = prev.map((tableOption) => {
        if (tableOption.id === row.id) {
          return { ...tableOption, disabled: false };
        }
        return tableOption;
      });
      return [...newTableOptions];
    });
    setAccessoriesValue((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  useEffect(() => {
    let total = 0.0;
    if (accessoriesValue.length > 0)
      accessoriesValue.forEach((accessory) => {
        total += parseFloat(+accessory.price * +accessory.quantity);
      });
    setTableTotal(total.toFixed(4));

    let valid = false;
    let error = false;
    if (accessoriesValue.length > 0) {
      valid = true;
      accessoriesValue.forEach((accessory) => {
        if (!accessory.id) {
          valid = false;
          return;
        }
        if (+accessory.price === 0 || +accessory.quantity === 0) {
          error = true;
          return;
        }
      });
    }
    setZeroError(error);
    setIsValid(valid && !error);
  }, [accessoriesValue, setIsValid]);

  useEffect(() => {
    setTableOptions(options);
  }, [options, setTableOptions]);

  return (
    <Table>
      <thead>
        <tr>
          <td className="col-3 label-required">{t("type")}</td>
          <td className="col-1 label-required">{t("quantity")}</td>
          <td className="col-2 label-required">{t("price")}</td>
          <td className="col-3">{t("notes")}</td>
          <td className="col-3">{t("total")}</td>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot>
        <tr>
          <td>
            <Button
              color="primary"
              onClick={onAdd}
              disabled={tableOptions.length === accessoriesValue.length}
            >
              +
            </Button>
          </td>
          <td></td>
          <td colSpan="2">
            {accessoriesValue.length > 0 && zeroError ? (
              <span className="text-danger">
                {t("accessoriesZeroValueError")}
              </span>
            ) : null}
          </td>
          <td>
            {accessoriesValue.length > 0 && (
              <span>
                {`${t("total")}: ${numberFormat.format(tableTotal)} ${t(
                  "currency"
                )}`}
              </span>
            )}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default translate(AccessoriesTable);
