import React, { useEffect } from "react";

import { getProfileInfo } from "../services/users.srvices";
import { useDispatch } from "react-redux";
import { setProfileName } from "../store/slices/profile.slice";
import environment from "./../environment"
export default function Start({ history }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem(environment.TOKEN_KEY)) {
      (async () => {
        const { body, ok } = await getProfileInfo();
        if (ok) {
          dispatch(
            setProfileName({
              profileName: body.displayName,
              role: body.role,
            })
          );
        } 
      })();
    }
  }, [dispatch]);

  return <div></div>;
}
