import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getCityById, updateCity } from "../../services/city.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import Loader from "../partials/Loader";

const EditCity = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshTable = () => {},
  languages = [],
}) => {
  let languagesForm;
  const [city, setCity] = useState({});

  // Store
  const cityId = useSelector((state) => state.city.id);
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);

  if (languages.length > 0) {
    if (Object.keys(city).length > 0) {
      languages.forEach((language) => {
        const value = city.translations.filter(
          (translation) => translation.languageId === language.id
        )[0];

        language.value = value ? value.name : "";
      });
    }

    languagesForm = languages.map((language) => {
      return (
        <FormGroup
          className="row d-flex align-items-center"
          key={language.code}
        >
          <Label htmlFor={`name_${language.code}`} className="col-4 mb-0">{`${t(
            "name"
          )} (${language.code.toUpperCase()})`}</Label>
          <Input
            className="col-6 mb-0"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            defaultValue={language.value}
            required
          ></Input>
        </FormGroup>
      );
    });
  } else {
    languagesForm = <div></div>;
  }

  const editCity = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const translations = [];

    languages.forEach((language, index) => {
      translations.push({
        name: event.target[index].value,
        languageId: language.id,
      });
    });

    const requestBody = {
      translations: translations,
      id: city.id,
    };

    (async () => {
      const { ok } = await updateCity(requestBody);

      if (ok) {
        toast.success(t("editCitySuccess"));
        toggleRefreshTable();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    if (cityId) {
      (async () => {
        setLoading(true);
        const { body, ok } = await getCityById(cityId);
        if (ok) {
          setCity(body);
        }
        setLoading(false);
      })();
    }
  }, [cityId]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("editCity")}</ModalHeader>
        {loading ? (
          <Loader />
        ) : (
          <Form className="form theme-form" onSubmit={editCity}>
            <ModalBody>{languagesForm}</ModalBody>
            <ModalFooter className="justify-content-center">
              <Button color="primary" type="submit">
                {t("save")}
              </Button>
              <Button color="light" onClick={toggleModal} type="button">
                {t("cancel")}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default translate(EditCity);
