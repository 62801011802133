/* eslint-disable no-fallthrough */
import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { translate } from "react-switch-lang";
import { withRouter, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import BasicInformation from "./BasicInformation";
import Contract from "./Contract";
import Sizes from "./Sizes";
import OrderDetails from "./OrderDetails";
import Customer from "./Customer";
import FactoringData from "./FactoringData";
import { useEffect } from "react";
import { getOrder } from "../../services/order.service";
import ApproveModal from "./orderActionsModals/ApproveModal";
import RejectOrderModal from "./orderActionsModals/RejectOrderModal";
import RequestOrderUpdateModal from "./orderActionsModals/RequestOrderUpdateModal";
import ApproveOrderForPreprationModal from "./orderActionsModals/ApproveOrderForPreprationModal";
import ProcessOrderManualPayment from "./orderActionsModals/ProcessOrderManualPaymentModal";
import AssignDeliveryDate from "./orderActionsModals/AssignDeliveryDate";
import AssignToInstallingTechModal from "./orderActionsModals/AssignToInstallingTechModal";
import Loader from "../partials/Loader";
import NoData from '../partials/NoData';
import MaintenanceRequests from "./MaintenanceRequests";


const OrderTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  // Data
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [kitchenManualDesign, setKitchenManualDesign] = useState([]);
  const [kitchen3DDesign, setKitchen3DDesign] = useState([]);
  const [contractImageFile, setContractImageFile] = useState([]);
  const [measurmentImages, setMeasurmentImages] = useState([]);
  const [kitchenBeforeImages, setKitchenBeforeImages] = useState([]);
  const [kitchenAfterInstallingFiles, setKitchenAfterInstallingFiles] = useState([]);
  const [bill, setBill] = useState([]);
  const [client, setClient] = useState([]);
  const [actions, setActions] = useState([]);
  const [doorsHandleImagesFiles, setDoorsHandleImagesFiles] = useState([]);
  const [washtubImagesFiles, setWashtubImagesFiles] = useState([]);

  const [counter, setCounter] = useState(0);
  const { orderId } = useParams();
  const [getMaintenanceRequests, setGetMaintenanceRequests] = useState(false);
  // Claims
  const claimsData = JSON.parse(localStorage.getItem("newClaims"));

  // UI/UX
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const [refreshData, setRefreshData] = useState([]);

  // Actions Modals
  const [editRequestType, setEditRequestType] = useState("");

  const [isApprove, setIsApprove] = useState(false);
  const [isRejectOrder, setIsRejectOrder] = useState(false);
  const [isRequestOrderUpdate, setIsRequestOrderUpdate] = useState(false);
  const [isApproveOrderForPrepration, setIsApproveOrderForPrepration] =
    useState(false);
  const [isProcessOrderManualPayment, setIsProcessOrderManualPayment] =
    useState(false);
  const [isAssignDeliveryDate, setIsAssignDeliveryDate] = useState(false);
  const [isAssignToInstallingTech, setIsAssignToInstallingTech] =
    useState(false);
  const [isPrintCard, setIsPrintCard] =
    useState(false);

  // Functions
  const handleClaims = (claim) => {
    if (claimsData.claims.indexOf(claim) > -1) {
      return true;
    } else return false;
  };

  const handleAction = (actionId) => {
    console.log(actionId);
    switch (actionId) {
      case "OrderContractRegistration":
        props.history.push(
          `${process.env.PUBLIC_URL}/orders/contract/register/${orderId}`
        );
        break;
      case "RequestOrderUpdate":
        setIsRequestOrderUpdate(!isRequestOrderUpdate);
        setEditRequestType("orderUpdate")
        break;
      case "RequredOrderMeasurmentUpdate":
        setIsRequestOrderUpdate(!isRequestOrderUpdate);
        setEditRequestType("measurementUpdate");
        break;
      case "Approve":
        setIsApprove(!isApprove);
        break;

      case "RejectOrder":
        setIsRejectOrder(!isRejectOrder);
        break;
      case "ApproveOrderForPrepration":
        setIsApproveOrderForPrepration(!isApproveOrderForPrepration);
        break;
      case "ProcessOrderManualPayment":
        setIsProcessOrderManualPayment(!isProcessOrderManualPayment);
        break;
      case "AssignDeliverDate":
        setIsAssignDeliveryDate(!isAssignDeliveryDate);
        break;
      case "AssignToInstallingTech":
        setIsAssignToInstallingTech(!isAssignToInstallingTech);
        break;

      default:
        return "state";
    }
  };
  const toggleApprove = () => setIsApprove(!isApprove);
  const toggleRejectOrder = () => setIsRejectOrder(!isRejectOrder);
  const toggleRequestOrderUpdate = () =>
    setIsRequestOrderUpdate(!isRequestOrderUpdate);
  const toggleApproveOrderForPrepration = () =>
    setIsApproveOrderForPrepration(!isApproveOrderForPrepration);
  const toggleProcessOrderManualPayment = () =>
    setIsProcessOrderManualPayment(!isProcessOrderManualPayment);
  const toggleAssignDeliveryDate = () =>
    setIsAssignDeliveryDate(!isAssignDeliveryDate);
  const toggleAssignToInstallingTech = () =>
    setIsAssignToInstallingTech(!isAssignToInstallingTech);
  const togglePrintCardModal = () =>
    setIsPrintCard(!isPrintCard);

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  const getData = () => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getOrder(orderId);
      if (ok) {
        console.log(body);
        setOrderDetailsData(body);
        setKitchenManualDesign(body.kitchenManualDesignFiles);
        setMeasurmentImages(body.measurmentImagesFiles);
        setKitchen3DDesign(body.kitchen3DDesignFiles);
        setContractImageFile(body.contractImageFile);
        setKitchenBeforeImages(body.kitchenBeforeImagesFiles);
        setKitchenAfterInstallingFiles(body.kitchenAfterInstallingFiles);
        setBill(body.invoiceUrl);
        setClient(body.client);
        setActions(body.actions);
        setDoorsHandleImagesFiles(body.doorsHandleImagesFiles);
        setWashtubImagesFiles(body.washtubImagesFiles);
      }

      setLoading(false);
    })();
  };
  const countActions = () => {
    actions.forEach((item) => {
      if (item.isActive === true) setCounter(counter + 1);
    });
  };

  useEffect(() => {
    getData();
    countActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData, localStorage.getItem("lang")]);

  return (
    <Fragment>
      <ApproveModal
        isOpen={isApprove}
        toggleModal={toggleApprove}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></ApproveModal>
      <RejectOrderModal
        isOpen={isRejectOrder}
        toggleModal={toggleRejectOrder}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></RejectOrderModal>
      <RequestOrderUpdateModal
        isOpen={isRequestOrderUpdate}
        toggleModal={toggleRequestOrderUpdate}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
        type={editRequestType}
      ></RequestOrderUpdateModal>
      <ApproveOrderForPreprationModal
        isOpen={isApproveOrderForPrepration}
        toggleModal={toggleApproveOrderForPrepration}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></ApproveOrderForPreprationModal>
      <ProcessOrderManualPayment
        isOpen={isProcessOrderManualPayment}
        toggleModal={toggleProcessOrderManualPayment}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></ProcessOrderManualPayment>
      <AssignDeliveryDate
        isOpen={isAssignDeliveryDate}
        toggleModal={toggleAssignDeliveryDate}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></AssignDeliveryDate>
      <AssignToInstallingTechModal
        isOpen={isAssignToInstallingTech}
        toggleModal={toggleAssignToInstallingTech}
        orderId={orderId}
        toggleRefreshData={toggleRefreshData}
      ></AssignToInstallingTechModal>
      {/* <PrintCard
        isOpen={isPrintCard}
        toggleModal={togglePrintCardModal}
        orderId={orderId}
      ></PrintCard> */}

      <Breadcrumb parent={props.t("orders")} title={props.t("orderDetails") + " ( " + (orderDetailsData.orderCode || props.t("notEntered")) + " )"} backTo="true" />
      <Container fluid={true}>
        <div className="user-profile social-app-profile">
          <Row>
            <Col sm="12">
              <Card className="hovercard text-center">
                <div className="d-flex info p-1">
                  <Nav
                    tabs
                    className="justify-content-start border-tab tabs-scoial col-sm-8"
                    type="button"
                  >
                    <NavItem className="nav" id="myTab" role="tablist">
                      <NavLink
                        tag="a"
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}
                      >
                        {props.t("orderDetails")}
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav" id="myTab" role="tablist">
                      <NavLink
                        tag="a"
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => setActiveTab("2")}
                      >
                        {props.t("sizes")}
                      </NavLink>
                      <NavItem className="nav" id="myTab" role="tablist">
                        <NavLink
                          tag="a"
                          className={activeTab === "3" ? "active" : ""}
                          onClick={() => setActiveTab("3")}
                        >
                          {props.t("contract")}
                        </NavLink>
                      </NavItem>
                    </NavItem>
                    <NavItem className="nav" id="myTab" role="tablist">
                      <NavLink
                        tag="a"
                        className={activeTab === "4" ? "active" : ""}
                        onClick={() => setActiveTab("4")}
                      >
                        {props.t("factoringData")}
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav" id="myTab" role="tablist">
                      <NavLink
                        tag="a"
                        className={activeTab === "5" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("5");
                          setGetMaintenanceRequests(true);
                        }}
                      >
                        {props.t("maintenanceRequests")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Nav
                    className="justify-content-end border-tab tabs-scoial col-sm-4 p-2"
                  >
                    {actions.map((item) =>
                      handleClaims(item.actionId) ? (
                        item.platform === "Web" &&
                          item.actionId !== "AssignToMeasuringTech" &&
                          item.actionId !== "UpdateOrderVisitDate" ? (
                          item.isActive ? (
                            <NavItem className="nav" id="myTab" role="tablist">
                              <Button
                                color="light"
                                className="mr-1 btn-sm p-1"
                                onClick={() =>
                                  handleAction(item.actionId.substring(7))
                                }
                              // onClick={`toggles${item.actionId}()`}
                              >
                                {props.t(item.actionId.substring(7))}
                              </Button>
                            </NavItem>
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div></div>
                        )
                      ) : (
                        <div></div>
                      )
                    )}
                    {
                      //Card button
                      orderDetailsData.orderStatus === "InstalledSuccessfully" && (
                        <NavItem className="nav" id="myTab" role="tablist">
                          <Button
                            color="light"
                            className="mr-1 btn-sm p-1"
                            onClick={() =>
                              // setIsPrintCard(!isPrintCard)
                              // console.log("fghjhfj")
                              props.history.push(`${process.env.PUBLIC_URL}/orders/card/${orderId}`)
                            }
                          >
                            {props.t("print")}
                          </Button>
                        </NavItem>
                      )
                    }
                  </Nav>
                </div>
              </Card>
            </Col>
          </Row>
          {/* Second Scenario */}
          {!loading ? (
            <TabContent activeTab={activeTab} className="tab-content">
              <TabPane tabId="x">
                <BasicInformation data={orderDetailsData} />
              </TabPane>
              <TabPane tabId="2">
                {(orderDetailsData.actualKitchenHeight) ?
                  <Sizes
                    data={orderDetailsData}
                    measurmentImages={measurmentImages}
                    kitchenBeforeImages={kitchenBeforeImages}
                  />
                  :
                  <Card>
                    <NoData message={props.t("noData")} />
                  </Card>
                }
              </TabPane>
              <TabPane tabId="3">
                {(orderDetailsData.contractNumber) ?
                  <Contract
                    data={orderDetailsData}
                    kitchenManualDesign={kitchenManualDesign}
                    kitchen3DDesign={kitchen3DDesign}
                    contractImageFile={contractImageFile}
                    doorsHandleImagesFiles={doorsHandleImagesFiles}
                    washtubImagesFiles={washtubImagesFiles}
                  />
                  :
                  <Card>
                    <NoData message={props.t("noData")} />
                  </Card>
                }
              </TabPane>

              <TabPane tabId="1">
                <OrderDetails data={orderDetailsData} client={client} />
              </TabPane>
              <TabPane tabId="6">
                <Customer data={orderDetailsData} client={client} />
              </TabPane>

              <TabPane tabId="4">
                {(orderDetailsData.paymentStatus) ?
                  <FactoringData
                    data={orderDetailsData}
                    kitchenAfterInstallingFiles={kitchenAfterInstallingFiles}
                    bill={bill}
                    orderId={orderId}
                    toggleRefreshData={toggleRefreshData}
                  />
                  :
                  <Card>
                    <NoData message={props.t("noData")} />
                  </Card>
                }
              </TabPane>
              <TabPane tabId="5">
                <MaintenanceRequests
                  data={orderDetailsData}
                  orderId={orderId}
                  toggleRefreshData={toggleRefreshData}
                  getMaintenanceRequests={getMaintenanceRequests}
                />

              </TabPane>

            </TabContent>
          ) : (
            <Card>
              <Loader />
            </Card>
          )}
        </div>
      </Container>
    </Fragment >
  );
};
export default withRouter(translate(OrderTabs));
