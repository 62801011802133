import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import InputMask from "react-input-mask";
const Customer = (props) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    return (
        <div>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form className="form theme-form"
                            // onSubmit={handleSubmit}
                            >
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">
                                                    {props.t("firstName")}
                                                </Label>
                                                <Col sm="9">
                                                    <Input className="form-control" type="text" name="firstName" required />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">
                                                    {props.t("surName")}</Label>
                                                <Col sm="9">
                                                    <Input className="form-control" type="text" name="surName" required />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">
                                                    {props.t("phoneNumber")}
                                                </Label>
                                                <Col sm="9">
                                                    <InputMask
                                                        className="col-6 mb-0 form-control"
                                                        type="tel"
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        onChange={(event) => {
                                                            setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                                                        }}
                                                        mask="05 999 999 99"
                                                        value={phoneNumber}
                                                    ></InputMask>
                                                </Col>
                                            </FormGroup>


                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="submit-footer">
                                    <Button color="primary" className="mr-1" type='submit'
                                    >{props.t("save")}</Button>
                                    {/* <Button color="light" type="reset"
                                            onClick={handleCancel}>{props.t("cancel")}</Button> */}
                                </CardFooter>
                            </Form>
                        </Card>



                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(translate(Customer));