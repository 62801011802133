import React, { useState, Fragment } from 'react'
import Breadcrumb from "../../layout/breadcrumb";
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import {
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Button,
    Form,
    CardHeader,
    Collapse,
    FormGroup,
    Label,
    Input,
    CardFooter,
} from "reactstrap";
import environment from "../../environment";
import KPTable from "../partials/KPTable/KPTable";
import TimePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization';
const ViewLogs = (props) => {

    const [requestOptions, setRequestOptions] = useState({
        orderBy: "",
        searchText: ""
    });
    const tableColumns = [
        {
            name: props.t("event"),
            selector: "event",
            width: "200px",
            cell: (row) =>
                <p style={{ textAlign: "center" }}>
                    {row.event}
                </p>
            ,
            center: true,
            wrap: true,
        },
        {
            name: props.t("date"),
            selector: (row) =>
                row.dateTime.substring(0, row.dateTime.length - 3) + " " +
                TimePeriodLocalization(row.dateTime.substring(row.dateTime.length - 2, row.dateTime.length)),
            center: true,
            width: "200px",
        },
        {
            name: props.t("level"),
            selector: "level",
            center: true,
            width: "160px",
        },
        {
            name: props.t("message"),
            selector: "message",
            cell: (row) =>
                <p style={{ textAlign: "center" }}>
                    {row.message}
                </p>
            ,
            center: true,
            wrap: true,
        },
    ];

    return (
        <Fragment>
            <Breadcrumb parent={props.t("logs")} title={props.t("viewLogs")} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <KPTable
                                    endpoint={`${environment.LOGS_ENDPOINT}/Search`}
                                    columns={tableColumns}
                                    //   refreshData={refreshTable}
                                    // waiting for API
                                    requestOptions={requestOptions}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    )
}

export default withRouter(translate(ViewLogs));
