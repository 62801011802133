import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.ACCOUNT_PUBLIC_ENDPOINT;


export const register = async (requestBody) => {
    const response = await Post(`${_endpoint}/login`, requestBody);
    return response;
};
export const verification = async (requestBody) => {
    const response = await Post(`${_endpoint}/VerifyCode`, requestBody);
    return response;
};
export const updateProfile = async (requestBody) => {
    const response = await Post(`${_endpoint}/UpdateProfile`, requestBody, true);
    return response;
};