import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Col } from "reactstrap";

import { translate } from "react-switch-lang";

const Error404 = ({ t }) => {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <div className="error-heading mt-3">
              <h2 className="headline font-danger">{"404"}</h2>
            </div>
            <Col md="8 offset-md-2">
              <p className="sub-content">{t("404PageMessage")}</p>
            </Col>
            <Link to={`${process.env.PUBLIC_URL}/orders/`}>
              <Button color="danger-gradien" size="lg">
                {t("backHome")}
              </Button>
            </Link>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Error404);
