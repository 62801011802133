import { configureStore } from "@reduxjs/toolkit";

import languageReducer from "./slices/languageSlice";
import customizerReducer from "../redux/customizer/reducer";
import themeReducer from "./slices/themeSlice";
import clientReducer from "./slices/clientSlice";
import orderReducer from "./slices/orderSlice";
import loadingReducer from "./slices/loadingSlice";
import profileReducer from "./slices/profile.slice";
import lookupReducer from "./slices/lookupSlice";
import branchReducer from "./slices/branchSlice";
import cityReducer from "./slices/citySlice";
import neighborhoodReducer from "./slices/neighborhoodSlice";
import maintenanceRequestReducer from "./slices/maintenanceRequestSlice";
import messageTemplateReducer from "./slices/messageTemplateSlice";

const toolkitReducers = {
  language: languageReducer,
  customizer: customizerReducer,
  theme: themeReducer,
  client: clientReducer,
  order: orderReducer,
  loading: loadingReducer,
  profile: profileReducer,
  lookup: lookupReducer,
  branch: branchReducer,
  city: cityReducer,
  neighborhood: neighborhoodReducer,
  maintenanceRequest: maintenanceRequestReducer,
  messageTemplate: messageTemplateReducer,
};
const store = configureStore({
  reducer: toolkitReducers,
});

export default store;
