import React, { Fragment } from 'react'
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { toast } from 'react-toastify';
import { translate } from 'react-switch-lang';
import { withRouter } from 'react-router-dom';
import { addRole } from '../../services/role.service'
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../store/slices/loadingSlice';
const AddRole = (props) => {

    // Store
    const dispatch = useDispatch();
    //    FUNCTIOS
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(setGlobalLoading({status: true}));
        
        const requestBody = {
            name: event.target.name.value
        };
        (async () => {
            const { ok } = await addRole(requestBody);

            if (ok) {
                setTimeout(() => {
                    toast.success(props.t("roleAddedSuccessfuly"));
                }, 200);
                props.history.push(`${process.env.PUBLIC_URL}/roles`);
            }
            dispatch(setGlobalLoading({status: false}));
        })();
    }
    const handleCancel = () => {
        props.history.push(`${process.env.PUBLIC_URL}/roles`);
    }


    return (
        <Fragment>
            <Breadcrumb parent={props.t("roles")} title={props.t("addRole")} backTo="true" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form className="form theme-form" onSubmit={handleSubmit}>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">{props.t("name")}</Label>
                                                <Col sm="9">
                                                    <Input className="form-control" type="text" name="name" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="submit-footer">
                                    
                                        <Button color="primary" className="mr-1" type='submit'
                                        >{props.t("save")}</Button>
                                        <Button color="light" type="reset"
                                            onClick={handleCancel}>{props.t("cancel")}</Button>
                                    
                                </CardFooter>
                            </Form>
                        </Card>



                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default withRouter(translate(AddRole));