import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { deleteClient } from "../../services/client.service";
import CreateClient from "./CreateClient";
import EditClient from "./EditClient";
import { setEditClientId } from "../../store/slices/clientSlice";
import ConfirmationModal from "../partials/ConfirmationModal";
import environment from "../../environment";
import { KPTable } from "../partials/KPTable";
import { RefreshCw } from "react-feather";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

const ViewClients = ({ t }) => {
  // Data from Backend
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [requestOptions, setRequestOptions] = useState(
    {
      name: "",
      phoneNumber: "",
      pageIndex: 0,
      pageSize: 0,
      orderBy: "string",
      searchText: "string"
    }
  );

  // Modals
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  //END

  const languageKey = useSelector((state) => state.language.key);


  //UI/UX
  const [submitting, setSubmitting] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const toggleRefreshTableData = () => {
    setRefreshTable(!refreshTable);
  };
  //END

  // Store
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: t("firstName"),
      selector: "firstName",

      center: true,
    },
    {
      name: t("surname"),
      selector: "sureName",

      center: true,
    },
    {
      name: t("phoneNumber"),
      selector: "phoneNumber",

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            dispatch(setEditClientId({ id: row.id }));
            toggleEditModal();
          }}
        >
          {t("edit")}
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setIsConfirm(true);
            setClientId(row.id);
          }}
          className="px-3"
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      button: true,
    },
  ];

  // Modals Toggles
  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const toggleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };
  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);
  //END

  const getFilteredData = (value) => {
    if (value === "reset") {
      setRequestOptions(
        {
          name: "",
          phoneNumber: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          name: clientName,
          phoneNumber: phoneNumber,
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTableData();
  };
  const resetData = () => {
    setPhoneNumber("");
    setClientName("");
    setIsFilter(false);
    getFilteredData("reset");
  };

  return (
    <>
      <CreateClient
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModal}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshTable}
      ></CreateClient>

      <EditClient
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        toggleRefreshTable={toggleRefreshTable}
      ></EditClient>

      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={clientId}
        toggleRefreshData={toggleRefreshTable}
        apiToCall={deleteClient}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb parent={t("clients")} title={t("viewAllClients")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("name")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="productName"
                              max="10"
                              value={clientName}
                              onChange={(event) => {
                                setClientName(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>

                      <Col sm="6">

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("phoneNumber")}
                          </Label>
                          <Col sm="8">
                            <InputMask
                              style={{
                                direction: "ltr",
                                textAlign: languageKey === "ar" ? "end" : "start",
                              }}
                              className="col-7 mb-0 form-control"
                              type="tel"
                              id="phoneNumber"
                              name="phoneNumber"
                              onChange={(event) => {
                                setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                              }}
                              mask="05 999 999 99"
                              value={phoneNumber}
                            ></InputMask>
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={toggleCreateModal}
                    size="sm"
                    disabled={submitting}
                  >
                    {t("addClient")}
                  </Button>
                  <RefreshCw
                    className="m-2"
                    color="#4433FF"
                    onClick={resetData}
                    role="button"
                  />
                </CardTitle>
                <KPTable
                  endpoint={`${environment.CLIENT_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                  requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(ViewClients);
