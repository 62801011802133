// systemName:"Products.Manage"
import React, { useState } from "react";
import { RefreshCw } from "react-feather";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import environment from "../../environment";
import Breadcrumb from "../../layout/breadcrumb/index";

import { deleteProduct } from "../../services/products.service";
import ConfirmationModal from "../partials/ConfirmationModal";
import KPTable from "../partials/KPTable/KPTable";

const ViewProducts = (props) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [barCode, setBarCode] = useState("");

  const [requestOptions, setRequestOptions] = useState(
    {
      productName: "",
      barcode: "",
      productType: "",
      pageIndex: 0,
      pageSize: 0,
      orderBy: "string",
      searchText: "string"
    }
  );
  //UX/UI
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const toggleRefreshTableData = () => {
    setRefreshTableData(!refreshTableData);
  };
  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);


  //END

  const tableColumns = [
    {
      name: props.t("name"),
      selector: "localizedName",
      sortable: true,
      center: true,
    },
    {
      name: props.t("manufacurer"),
      selector: "localizedManufecturer",
      sortable: true,
      center: true,
    },
    {
      name: props.t("productType"),
      selector: "localizedProductType",
      sortable: true,
      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            console.log(row);
            localStorage.setItem("productId", row.id);
            props.history.push(
              `${process.env.PUBLIC_URL}/products/edit/${row.id}`
            );
          }}
        >
          {props.t("edit")}
        </Button>
      ),
      button: true,
    },

    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setIsConfirm(true);
            setProductId(row.id);
          }}
          className="px-3"
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      button: true,
    },
  ];


  const getFilteredData = (value) => {
    if (value === "reset") {
      setRequestOptions(
        {
          productName: "",
          barcode: "",
          productType: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          productName: productName,
          barcode: barCode,
          productType: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTableData();
  };
  const resetData = () => {
    setBarCode("");
    setProductName("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  //END
  return (
    <>
      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={productId}
        toggleRefreshData={toggleRefreshTableData}
        apiToCall={deleteProduct}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb
        parent={props.t("products")}
        title={props.t("viewProducts")}
      ></Breadcrumb>
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{props.t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {props.t("name")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="productName"
                              max="10"
                              value={productName}
                              onChange={(event) => {
                                setProductName(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>
                      {/* Second Column */}
                      <Col sm="6">

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {props.t("productNumber")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="number"
                              name="productNumber"
                              max="10"
                              value={barCode}
                              onChange={(event) => {
                                setBarCode(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {props.t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {props.t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={() => {
                      props.history.push(
                        `${process.env.PUBLIC_URL}/products/add`
                      );
                    }}
                    size="sm"
                  >
                    {props.t("addProduct")}
                  </Button>
                  <RefreshCw
                    className="m-2"
                    color="#4433FF"
                    onClick={resetData}
                    role="button"
                  />
                </CardTitle>
                <KPTable
                  endpoint={`${environment.PRODUCT_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTableData}
                  requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(translate(ViewProducts));
