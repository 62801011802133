import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import VerificationInput from "react-verification-input";
import { verification } from "../../services/accountPublic.service";
import { toast } from "react-toastify";
import "./style.css";
import environment from "../../environment";
import { checkOrders } from "../../services/maintenance-public.service";
const Verification = ({ t, history }) => {
  const [verificationCode, setVerificationCode] = useState("");

  const handleSubmit = (value) => {
    // event.preventDefault();
    const requestBody = {
      code: value,
      userName: localStorage.getItem("userName"),
    };
    console.log(requestBody);
    (async () => {
      const { ok, body } = await verification(requestBody);
      if (ok) {
        toast.success(t("verificationDone"));
        localStorage.setItem(environment.TOKEN_KEY, body.accessToken);

        if (body.isProfileCompleted) {
          history.push(`${process.env.PUBLIC_URL}/order/check`)
        } else {
          document.location.href = (`${process.env.PUBLIC_URL}/customer/data`);
        }
      } else {
        setVerificationCode("");
      }
    })();
  };
  const handleChange = (value) => {
    setVerificationCode(value);
    if (value.length === 4) handleSubmit(value);
  };
  const fff = 4;
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main login-tab text-center ">
                <div>
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/logo/login.png")}
                    alt=""
                    style={{ width: "40%" }}
                  />
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/logo/secondLogo.jpeg")}
                    alt=""
                    style={{ width: "40%" }}
                  />
                </div>
                <Form className="theme-form mt-4" onSubmit={handleSubmit}>
                  <h4 className="text-center">{t("verificationAccount")}</h4>
                  <p className="text-center">{t("enterVerificationCode")}</p>
                  <FormGroup></FormGroup>
                  <FormGroup className="mt-3">
                    <VerificationInput
                      removeDefaultStyles
                      dir="ltr"
                      validChars="0-9"
                      classNames={{
                        container: "container containerCust",
                        character: "character",
                        characterInactive: "character--inactive",
                        characterSelected: "character--selected",
                      }}
                      length={fff}
                      placeholder="_"
                      value={verificationCode}
                      onChange={(value) => handleChange(value)}
                      autoFocus="true"
                    />
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(translate(Verification));
