import React, { useState, useEffect } from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";

import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import { loadAssignInstallingVisitPage } from "../../../services/order.service";
import { assignToInstallingTech } from "../../../services/order.service";
import Loader from "../../partials/Loader";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";
import { minTime } from "date-fns";

const AssignToInstallingTechModal = ({
  isOpen,
  toggleModal = () => { },
  t,
  toggleRefreshData = () => { },
  orderId,
}) => {
  // STATES
  const [techniciansOption, setTechniciansOption] = useState([]);
  const [technicianId, setTechnicianId] = useState("");
  const [visitTime, setVisitTime] = useState("12:00");

  const [timeRange, setTimeRange] = useState({});
  const [fromTime, setFromTime] = useState({});
  const [toTime, setToTime] = useState({});

  // TimePicker
  const languageKey = useSelector((state) => state.language.key);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const timeLocaleMap = {
    ar: "ar-SA",
    en: "en-US",
  };
  const timeDisplayOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handleTechnicianSelect = (value) => {
    console.log(value);
    if (value.length) {
      setTechnicianId(value[0].id);
    } else {
      setTechnicianId("");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      id: orderId,
      installingVisitTime: visitTime,
      installingTechnicianId: technicianId,
    };
    console.log(requestBody);
    (async () => {
      const { ok } = await assignToInstallingTech(requestBody);
      if (ok) {
        setTimeout(() => {
          toast.success(t("assignInstallingDateDone"));
        }, 200);
        toggleRefreshData();
      }
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  useEffect(() => {
    if (isOpen && orderId) {
      setLoading(true);
      let fromHours, fromMinutes;
      let toHours, toMinutes;
      (async () => {
        const { body, ok } = await loadAssignInstallingVisitPage(orderId);
        if (ok) {
          console.log(body);
          setTechniciansOption(body.technichians);
          if (body.visitPeriods.fromTime.hours > 9)
            fromHours = body.visitPeriods.fromTime.hours;
          else
            fromHours = "0" + body.visitPeriods.fromTime.hours;
          if (body.visitPeriods.fromTime.minutes > 9)
            fromMinutes = body.visitPeriods.fromTime.minutes;
          else
            fromMinutes = body.visitPeriods.fromTime.minutes + "0";
          setFromTime(fromHours + ":" + fromMinutes);
          if (body.visitPeriods.toTime.hours > 9)
            toHours = body.visitPeriods.toTime.hours;
          else
            toHours = "0" + body.visitPeriods.toTime.hours;
          if (body.visitPeriods.toTime.minutes > 9)
            toMinutes = body.visitPeriods.toTime.minutes;
          else
            toMinutes = body.visitPeriods.toTime.minutes + "0";
          setToTime(toHours + ":" + toMinutes);
          setTimeRange({
            from: new Date(
              2022,
              1,
              1,
              body.visitPeriods.fromTime.hours,
              body.visitPeriods.fromTime.minutes
            ),
            to: new Date(
              2022,
              1,
              1,
              body.visitPeriods.toTime.hours,
              body.visitPeriods.toTime.minutes
            ),
          });
        }
        setLoading(false);
      })();
    }
    setTechnicianId("");
    setSubmitLoading(false);
  }, [orderId, isOpen]);

  const isSaveButtonDisabled = () => {
    return technicianId.length > 0;
  };
  const handleTime = (value) => {
    setVisitTime(value);
    console.log(value)
    // console.log(visitTime)
    if (value >= fromTime && value <= toTime) {
      setTimeError(false);
      setIsSubmitting(true);
    }
    else {
      setTimeError(true);
      setIsSubmitting(false);
    }
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("AssignToInstallingTech")}</ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          {submitLoading ? (
            <ModalBody>
              <Loader />
            </ModalBody>
          ) : (
            <ModalBody>
              <FormGroup className="row d-flex align-items-center">
                <Label className="col-4 mb-0">{t("technical")}</Label>
                <Typeahead
                  className="col-7 p-0"
                  id="select-technician"
                  options={techniciansOption}
                  labelKey="displayName"
                  onChange={handleTechnicianSelect}
                  placeholder={t("selectPlaceholder")}
                  isLoading={loading}
                  clearButton
                  key={"id"}
                ></Typeahead>
              </FormGroup>
              <FormGroup className="row">
                <label className="col-4 mb-0">{t("time")}</label>
                <div className="col-7 p-0">
                  <TextField
                    id="time"
                    label={
                      <i className="fa fa-clock-o"></i>
                    }
                    type="time"
                    value={visitTime}
                    onChange={(value) => {
                      handleTime(value.target.value);
                      // setVisitTime(value.target.value)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputProps: {
                        step: 300, // 5 min
                        // min: fromTime,
                        // min: "11:00",
                        // max: toTime
                      }
                    }}
                    error={timeError}
                    sx={{ width: 290 }}
                  />
                  {!loading && (
                    <p
                      style={{
                        color: timeError ? "#D32F2F" : "var(--theme-default)",
                        fontSize: languageKey === "en" ? "0.7rem" : "0.9rem",
                      }}
                    >{`${t("selectTimeNote", {
                      from: timeRange.from?.toLocaleTimeString(
                        timeLocaleMap[languageKey],
                        timeDisplayOptions
                      ),
                      to: timeRange.to?.toLocaleTimeString(
                        timeLocaleMap[languageKey],
                        timeDisplayOptions
                      ),
                    })}`}</p>
                  )}
                </div>
              </FormGroup>
            </ModalBody>
          )}
          <ModalFooter className="justify-content-center">
            <Button
              color="danger"
              type="submit"
              disabled={!isSaveButtonDisabled() || !isSubmitting}
            >
              {t("confirm")}
            </Button>
            <Button
              color="light"
              onClick={toggleModal}
              type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(AssignToInstallingTechModal));
