import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardFooter,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { getAllClaimsWithRoles } from "../../services/claims.service";
import { getAllRoles, setRolesClaims } from "../../services/role.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import Loader from "../partials/Loader";

const Claims = (props) => {
  const [rolesData, setRolesData] = useState([]);
  const [claims, setClaims] = useState([]);
  const [cloneClaims, setCloneClaims] = useState(claims);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(true);

  const handleSubmit = () => {
    dispatch(setGlobalLoading({ status: true }));
    let submitData = [];
    cloneClaims.forEach((key, index) => {
      if (index < claims.length) submitData.push(key);
    });
    (async () => {
      const { ok } = await setRolesClaims(submitData);
      if (ok) {
        toast.success(props.t("claimsChangedSuccessfuly"));
        props.history.push(`${process.env.PUBLIC_URL}/users`);
      }
      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  const handleCancel = () => {
    props.history.push(`${process.env.PUBLIC_URL}/users`);
  };
  const getData = () => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getAllRoles();
      if (ok) {
        setRolesData(body);
      }

      setLoading(false);
    })();
  };
  const getClaims = () => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getAllClaimsWithRoles();
      if (ok) {
        setClaims(body);
        setCloneClaims(body);
      }

      setLoading(false);
    })();
  };
  useEffect(() => {
    getData();
    getClaims();
  }, []);

  return (
    <div>
      <Breadcrumb
        parent={props.t("usersManagment")}
        title={props.t("accessControl")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Row className="card-block">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>{props.t("permissionName")}</th>
                          {rolesData.map((item, index) => (
                            <th key={index}>{item.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          /* <tr>
                            <td> */
                          <Loader table />
                          /* </td>
                        </tr> */
                        ) : (
                          claims.map((item, index) => (
                            <tr key={index}>
                              <td key={index}>{item.claim.displayName}</td>
                              {item.roles.map((sub, index) =>
                                sub.isSelected ? (
                                  <td key={index}>
                                    <Input
                                      id="inline-2"
                                      type="checkbox"
                                      name="published"
                                      style={{ marginLeft: 20 }}
                                      onChange={() => {
                                        setCloneClaims([
                                          ...cloneClaims,
                                          (item.roles[
                                            index
                                          ].isSelected = false),
                                        ]);
                                        localStorage.setItem(
                                          "testRoledData",
                                          JSON.stringify(cloneClaims)
                                        );
                                      }}
                                      defaultChecked
                                    />
                                  </td>
                                ) : (
                                  <td key={index}>
                                    <Input
                                      id="inline-2"
                                      type="checkbox"
                                      name="published"
                                      style={{ marginLeft: 20 }}
                                      onChange={() => {
                                        setCloneClaims([
                                          ...cloneClaims,
                                          (item.roles[index].isSelected = true),
                                        ]);
                                        localStorage.setItem(
                                          "testRoledData",
                                          JSON.stringify(cloneClaims)
                                        );
                                      }}
                                    />
                                  </td>
                                )
                              )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <CardFooter className="submit-footer">
                <Button
                  color="primary"
                  className="mr-1"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {props.t("save")}
                </Button>
                <Button color="light" type="reset" onClick={handleCancel}>
                  {props.t("cancel")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(translate(Claims));
