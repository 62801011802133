
import React, { Fragment } from 'react';
import sad from '../../assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';
import { Container, Button, Media, Col } from "reactstrap"
import { translate } from "react-switch-lang";
const Error403 = (props) => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <h1 className="headline font-danger ">{"403"}</h1>
                        </div>
                        <Col md="8 offset-md-2">
                            <p className="sub-content">{props.t("accessDenied")}</p>
                        </Col>
                        <Link to={`${process.env.PUBLIC_URL}/sitesetting`}>
                            <Button
                                color="danger-gradien"
                                size='lg'
                            >
                                {props.t("homePage")}
                            </Button>
                        </Link>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default translate(Error403);