import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Container,
} from "reactstrap";
import Loader from "../../../KPComponents/partials/Loader";
import { getMaintenanceRequestForAnOrder } from "../../../services/maintenance-public.service";
import { getOrderById } from "../../../services/order.service";
import AddMaintenanceRequest from "../Maintenance/AddMaintenanceRequest";
import MaintenanceRequestCard from "../Maintenance/MaintenanceRequestCard";

const OrderCustomerDetails = ({ t }) => {
  const [data, setData] = useState({});
  const [images, setImages] = useState([]);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);

  // Routing
  const { id } = useParams();
  const history = useHistory();

  // Store
  const languageKey = useSelector((state) => state.language.key);

  // UI/UX
  const [loading, setLoading] = useState(false);
  const [addMessage, setAddMessage] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  // Create Maintenance Request
  const [isFormOpen, setIsFormOpen] = useState(false);

  // View Maintenance Requests
  const [areCardsOpen, setAreCardsOpen] = useState(false);

  // Carousel
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Lighbox
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getOrderById(id);
        if (ok) {
          setData(body);
          setImages(body.kitchenAfterInstallingFiles);
        }
        setLoading(false);
      })();
      (async () => {
        const { body, ok } = await getMaintenanceRequestForAnOrder({
          orderId: id,
        });
        if (ok) {
          setMaintenanceRequests(body.items);
          console.log(body.items);
        }
      })();
    }
  }, [id, languageKey, refreshData]);

  return (
    <>
      <Container fluid={true} style={{ position: "relative" }}>
        <div className="order-card">
          {loading ? (
            <Loader />
          ) : (
            <>
              <i
                className="fa fa-arrow-left back-icon"
                onClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/customer/home`)
                }
              ></i>
              <div className="order-container">
                <div className="title">
                  <div className="order-code">
                    <p className="up-text">{t("contractNumber")}</p>
                    <h3>{data.contractNumber}</h3>
                  </div>
                  <div className="date-container">
                    <p className="date">{data.orderDate?.substring(0, 11)}</p>
                    <p className="time">
                      {`${data.orderDate?.substring(
                        11,
                        data.orderDate.length - 2
                      )} ${t(
                        `${data.orderDate?.substring(
                          data.orderDate.length - 2,
                          data.orderDate.length
                        )}`
                      )}`}
                    </p>
                  </div>
                </div>
                <div className="address">
                  <div>
                    <p className="up-text">{t("city")}</p>
                    <p className="value">{data.cityLocalized}</p>
                  </div>
                  <div>
                    <p className="up-text">{t("neighborhood")}</p>
                    <p className="value">{data.neighborhoodLocalized}</p>
                  </div>
                </div>
                {images.length > 0 && (
                  <div className="images-container" dir="ltr">
                    <Carousel
                      className="order-carousel"
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                    >
                      {images.map((image, index) => (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={index}
                        >
                          <img
                            src={image}
                            alt={`kitchen-${index}`}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          />
                        </CarouselItem>
                      ))}
                      {images.length > 1 && (
                        <>
                          <CarouselIndicators
                            items={images}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                          />
                          <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                          />
                          <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                          />
                        </>
                      )}
                    </Carousel>
                  </div>
                )}
                <div className="maintenance-messages">
                  <p style={{ top: `${addMessage ? "0" : "100%"}` }}>
                    {t("addMaintenanceRequest")}
                  </p>
                  <p style={{ top: `${viewMessage ? "0" : "100%"}` }}>
                    {t("viewMaintenanceRequests")}
                  </p>
                </div>
              </div>
              <div className="buttons">
                <div className="create">
                  <i
                    className={`fa ${!isFormOpen ? "fa-plus" : "fa-minus"}`}
                    onMouseEnter={() => setAddMessage(true)}
                    onMouseLeave={() => setAddMessage(false)}
                    onClick={() => {
                      setIsFormOpen((prev) => !prev);
                      setAreCardsOpen(false);
                    }}
                  ></i>
                  <p className="button-label">{t("addMaintenanceRequest")}</p>
                </div>
                {maintenanceRequests.length > 0 && (
                  <div className="show">
                    <i
                      className="fa fa-file-text-o"
                      onMouseEnter={() => setViewMessage(true)}
                      onMouseLeave={() => setViewMessage(false)}
                      onClick={() => {
                        setIsFormOpen(false);
                        setAreCardsOpen((prev) => !prev);
                      }}
                    ></i>
                    <p className="button-label">
                      {t("viewMaintenanceRequests")}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
          <AddMaintenanceRequest
            isOpen={isFormOpen}
            refreshData={setRefreshData}
            setIsOpen={setIsFormOpen}
          />
        </div>
        {areCardsOpen && (
          <div
            className="row justify-content-center mb-3 cards-container mx-auto"
            style={{ gap: "1rem" }}
          >
            {maintenanceRequests.map((request, index) => (
              <MaintenanceRequestCard request={request} key={index} />
            ))}
          </div>
        )}
      </Container>
      {isOpen && (
        <Lightbox
          mainSrc={images[activeIndex]}
          nextSrc={images[activeIndex]}
          prevSrc={images[activeIndex]}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default translate(OrderCustomerDetails);
