import environment from "../environment";
import { Get, Post, PostForm } from "./http.service";

const _endpoint = environment.ORDER_ENDPOINT;

export const getAllOrders = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};
export const getOrder = async (id) => {
  const response = await Get(`${_endpoint}/Get?id=${id}`, true);
  return response;
};

export const getOrderById = async (orderId) => {
  const response = await Get(`${_endpoint}/Get?id=${orderId}`, true);
  return response;
};

export const getAssignOrderData = async (orderId) => {
  const response = await Get(
    `${_endpoint}/LoadAssignTechVisitPage?orderId=${orderId}`,
    true
  );
  return response;
};

export const createOrder = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const assignTechVisit = async (requestBody) => {
  const response = await Post(
    `${_endpoint}/AssignTechVisit`,
    requestBody,
    true
  );
  return response;
};

export const orderRegisterContract = async (requestBody) => {
  const response = await PostForm(
    `${_endpoint}/OrderContractRegistration`,
    requestBody,
    true
  );
  return response;
};
export const addContract = async (requestBody) => {
  const response = await PostForm(
    `${_endpoint}/InsertContract`,
    requestBody,
    true
  );
  return response;
};

export const acceptOrder = async (requestBody) => {
  const response = await Post(`${_endpoint}/ApproveOrder`, requestBody, true);
  return response;
};
export const rejectOrder = async (body) => {
  const response = await Post(`${_endpoint}/RejectOrder`, body, true);
  return response;
};

export const updateOrderDateTime = async (requestBody) => {
  const response = await Post(
    `${_endpoint}/UpdateOrderVisitDate`,
    requestBody,
    true
  );
  return response;
};
export const approveOrderForPreparation = async (requestBody) => {
  const response = await Post(
    `${_endpoint}/ApproveOrderForPrepration`,
    requestBody,
    true
  );
  return response;
};
export const requestOrderUpdate = async (data) => {
  const response = await Post(`${_endpoint}/RequestOrderUpdate`, data, true);
  return response;
};
export const RequiredOrderMeasurementUpdate = async (data) => {
  const response = await Post(`${_endpoint}/RequredOrderMeasurmentUpdate`, data, true);
  return response;
};

export const processOrderManualPayment = async (requestBody) => {
  const response = await PostForm(
    `${_endpoint}/ProcessOrderManualPayment`,
    requestBody,
    true
  );
  return response;
};
export const loadAssignInstallingVisitPage = async (orderId) => {
  const response = await Get(
    `${_endpoint}/LoadAssignInstallingVisitPage?orderId=${orderId}`,
    true
  );
  return response;
};
export const assignDeliverDate = async (data) => {
  const response = await Post(`${_endpoint}/AssignDeliverDate`, data, true);
  return response;
};
export const assignToInstallingTech = async (data) => {
  const response = await Post(
    `${_endpoint}/AssignToInstallingTech`,
    data,
    true
  );
  return response;
};
export const getStatus = async () => {
  const response = await Get(`${_endpoint}/GetOrderStatus`, true);
  return response;
};
export const search = async (data) => {
  const response = await Post(`${_endpoint}/Search`, data, true);
  return response;
};

export const isContractNumberExist = async (
  requestParams = { id: undefined, contractNumber: undefined }
) => {
  let query = "";
  for (const key of Object.keys(requestParams)) {
    if (requestParams[key]) {
      query += `${key}=${requestParams[key]}&`;
    }
  }
  query = query.substring(0, query.length - 1);
  const response = await Get(
    `${_endpoint}/IsContractNumberExsited?${query}`,
    true
  );
  return response;
};
export const isContractNumberExistOptional = async (contractNumber) => {
  const response = await Get(
    `${_endpoint}/IsContractNumberExsited?contractNumber=${contractNumber}`,
    true
  );
  return response;
};
export const closeInstallingTicket = async (ticketId) => {
  const data = {
    id: ticketId,
  };
  const response = await Post(`${_endpoint}/CloseInstallingTicket`, data, true);
  return response;
};
