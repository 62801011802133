import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { useParams, withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";

import { getPublishedLanguages } from "../../services/language.service";
import { getProduct } from "../../services/products.service";
import { updateProduct } from "../../services/products.service";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import Loader from "../partials/Loader";
import environment from "../../environment";
const EditProduct = ({ t, history, params }) => {
  let nameForm;
  let manuForm;
  let radiobButton;
  const translations = [];
  const { productId } = useParams();
  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState(["Accessories", "Device"]);
  const [languages, setLanguages] = useState([]);
  const [productData, setProductData] = useState({});
  const [choosedProduct, setChoosedProduct] = useState("");
  const [productTranslations, setProductTranslations] = useState([
    [
      {
        name: "",
        manufecturer: "",
        languageId: "c889a842-ecc6-49c9-b251-c17fa616a1a0",
      },
      {
        name: "",
        manufecturer: "",
        languageId: "b8ac763b-8f58-4a76-beaa-a1266dc09f1d",
      },
    ],
  ]);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);

  const handlePriceChange = (value) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setPrice(value);
    }
  };

  const editProduct = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    languages.forEach((language, index) => {
      translations.push({
        name: event.target[index].value,
        Manufecturer: event.target[index + languages.length].value,
        languageId: language.id,
      });
    });
    const apiData = {
      price: event.target.price.value,
      // productType: choosedProduct[0],
      productType: event.target.productType.value,
      barcode: event.target.barcode.value,
      translations: translations,
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      id: productId,
    };
    // console.log(apiData)
    (async () => {
      const { ok } = await updateProduct(apiData);

      if (ok) {
        toast.success(t("productEditedSuccessfully"));
        history.push(`${process.env.PUBLIC_URL}/products/`);
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  const getPublishedLang = () => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        const languagesInfo = body.map((language) => {
          return {
            id: language.id,
            code: language.culture,
          };
        });
        setLanguages(languagesInfo);
        console.log(languagesInfo);
      }
    })();
  };

  const getData = () => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getProduct(productId);
      if (ok) {
        setProductData(body);
        setProductTranslations(body.translations);
        setChoosedProduct(body.productType);
        setPrice(body.price);
      }

      setLoading(false);
    })();
    // console.log(body);
    console.log("choosedProduct");
  };

  useEffect(() => {
    getPublishedLang();
    getData();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductTranslations(productData.translations);
    setChoosedProduct(productData.productType);
  }, [productData]);

  const handleCancel = () => {
    history.push(`${process.env.PUBLIC_URL}/products`);
  };

  nameForm = languages.map((language, index) => {
    return productTranslations ? (
      <FormGroup className="row " key={language.code}>
        <Label
          htmlFor={`name_${language.code}`}
          className="col-sm-3 col-form-label"
        >{`${t("name")} (${language.code.toUpperCase()})`}</Label>
        <Col sm="9">
          <Input
            className="form-control"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            defaultValue={productTranslations[index].name || "name"}
            // defaultValue={productData.translations[index].manufecturer}
            required
          ></Input>
        </Col>
      </FormGroup>
    ) : (
      <FormGroup className="row " key={language.code}>
        <Label
          htmlFor={`name_${language.code}`}
          className="col-sm-3 col-form-label"
        >{`${t("name")} (${language.code.toUpperCase()})`}</Label>
        <Col sm="9">
          <Input
            className="form-control"
            type="text"
            id={`name_${language.code}`}
            name={language.code}
            required
          ></Input>
        </Col>
      </FormGroup>
    );
  });
  manuForm = languages.map((language, index) => {
    return productTranslations ? (
      <FormGroup className="row" key={language.code}>
        <Label
          htmlFor={`manufacurer_${language.code}`}
          className="col-sm-3 col-form-label"
        >
          {`${t("manufacurer")} (${language.code.toUpperCase()})`}
        </Label>
        <Col sm="9">
          <Input
            className="form-control"
            type="text"
            id={`manufacurer_${language.code}`}
            name={language.code}
            defaultValue={productTranslations[index].manufecturer}
            required
          ></Input>
        </Col>
      </FormGroup>
    ) : (
      <FormGroup className="row" key={language.code}>
        <Label
          htmlFor={`manufacurer_${language.code}`}
          className="col-sm-3 col-form-label"
        >
          {`${t("manufacurer")} (${language.code.toUpperCase()})`}
        </Label>
        <Col sm="9">
          <Input
            className="form-control"
            type="text"
            id={`manufacurer_${language.code}`}
            name={language.code}
            // defaultValue={productTranslations[index].manufecturer}
            required
          ></Input>
        </Col>
      </FormGroup>
    );
  });
  radiobButton = productType.map((item, index) => {
    return (
      <div
        key={index}
        className="d-flex align-items-center">
        <input
          type="radio"
          name="productType"
          value={item}
          key={index}
          defaultChecked={item === "Device"}
          style={{ marginInlineEnd: 4 }}
        />
        {t(item)}
      </div>
    );
  });

  return (
    <Fragment>
      <Breadcrumb
        parent={t("products")}
        title={t("editProduct")}
        backTo="true"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {loading ? (
                <Loader />
              ) : (
                <Form className="form theme-form" onSubmit={editProduct}>
                  <CardBody>
                    {nameForm}
                    {manuForm}
                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("productType")}
                      </Label>
                      <Col
                        sm="9"
                        className="d-flex justify-content-around align-items-center"
                      >
                        {/* {radiobButton} */}

                        {productType.map((item, index) =>
                          item === productData.productType ? (
                            <div
                              key={index}
                              className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="productType"
                                value={item}
                                key={index}
                                defaultChecked
                                style={{ marginInlineEnd: 4 }}
                              />
                              {t(item)}
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="productType"
                                value={item}
                                key={index}
                                // defaultChecked={item === productData.productType}
                                style={{ marginInlineEnd: 4 }}
                              />
                              {t(item)}
                            </div>
                          )
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("productNumber")}
                      </Label>
                      <Col sm="9">
                        <Input
                          className="form-control"
                          type="text"
                          name="barcode"
                          defaultValue={productData.barcode}
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("basicPrice")}
                      </Label>
                      <Col sm="9">
                        <Input
                          className="form-control"
                          type="text"
                          name="price"
                          value={price}
                          onChange={(event) => {
                            handlePriceChange(event.target.value);
                          }}
                          required
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  <CardFooter className="submit-footer">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1"
                    // disabled={!isSaveButtonDisabled()}
                    >
                      {t("save")}
                    </Button>
                    <Button color="light" onClick={handleCancel} type="button">
                      {t("cancel")}
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(EditProduct));
