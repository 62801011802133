import React, { useState } from "react";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import { deleteBranch } from "../../services/branch.service";
import CreateBranch from "./CreateBranch";
import EditBranch from "./EditBranch";
import environment from "../../environment";
import ConfirmationModal from "../partials/ConfirmationModal";
import { KPTable } from "../partials/KPTable";
import { useDispatch } from "react-redux";
import { setBranchIdToEdit } from "../../store/slices/branchSlice";

const ViewBranches = ({ t }) => {
  // Data from Backend
  const [branchId, setBranchId] = useState("");
  const [userName, setUserName] = useState("");
  const [code, setCode] = useState();
  const [address, setAddress] = useState();
  // After API
  const [requestOptions, setRequestOptions] = useState(
    {
      userType: "System",
      orderBy: "",
      searchText: ""
    }
  );
  //END

  // Modals
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  //END

  //UI/UX
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  //END

  // Modals Toggles
  const toggleRefreshTable = () => {
    setRefreshTableData(!refreshTableData);
  };
  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);

  //END
  // Store 
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: t("name"),
      selector: "name",

      center: true,
    },
    {
      name: t("address"),
      selector: "address",

      center: true,
    },
    {
      name: t("code"),
      selector: "code",

      center: true,
    },
    {
      name: t("smsBranchName"),
      selector: "smsBranchName",

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            dispatch(setBranchIdToEdit({ id: row.id }))
            toggleEditModal();
          }}
        >
          {t("edit")}
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            // removeBranch(row.id);
            setIsConfirm(true);
            setBranchId(row.id);
          }}
          className="px-3"
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      button: true,
    },
  ];


  const getFilteredData = (value) => {
    if (value === "reset") {
      // After API
      setRequestOptions(
        {
          productName: "",
          barcode: "",
          productType: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          userName: userName,
          address: address,
          code: code,
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTable();
  };
  const resetData = () => {
    setUserName("");
    setAddress("");
    setCode("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  return (
    <>
      <CreateBranch
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModal}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshTable}
      ></CreateBranch>

      <EditBranch
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        toggleRefreshTable={toggleRefreshTable}
      ></EditBranch>

      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={branchId}
        toggleRefreshData={toggleRefreshTable}
        apiToCall={deleteBranch}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumbs parent={t("branches")} title={t("viewBranches")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("name")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="productName"
                              max="10"
                              value={userName}
                              onChange={(event) => {
                                setUserName(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("address")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="address"
                              max="10"
                              value={address}
                              onChange={(event) => {
                                setAddress(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>

                      <Col sm="6">

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("code")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="code"
                              max="10"
                              value={code}
                              onChange={(event) => {
                                setCode(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={toggleCreateModal}
                    size="sm"
                    disabled={submitting}
                  >
                    {t("addBranch")}
                  </Button>
                </CardTitle>
                <KPTable
                  endpoint={`${environment.BRANCH_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTableData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(ViewBranches);
