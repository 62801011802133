import React from "react";
import { Modal, Card, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const LoadingModal = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const closeModal = () => {
    dispatch(setGlobalLoading({ status: false }));
  };
  return (
    <>
      <Modal
        isOpen={loading.status}
        backdrop="static"
        keyboard={false}
        centered
        toggle={() => closeModal()}
      >
        <Card className="mb-0">
          <CardHeader
            style={{
              padding: "10px 20px",
              backgroundColor: "var(--theme-background)",
            }}
          >
            {/* <i
              className="fa fa-times"
              style={{
                color: "var(--danger)",
                cursor: "pointer",
                fontSize: "1rem",
              }}
              onClick={() => closeModal()}
            ></i> */}
          </CardHeader>
          <div className="py-4">
            <Loader loadingMessage={loading.message} modal />
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default LoadingModal;
