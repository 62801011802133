import environment from "../environment";
import { Get, PostForm } from "./http.service";

const _endpoint = environment.LANGUAGE_ENDPOINT;


export const getPublishedLanguages = async () => {
  const response = await Get(`${_endpoint}/GetPublished`, true);
  return response;
};

export const getLanguages = async () => {
  const response = await Get(`${_endpoint}/Get`, true);
  return response;
};

export const getLanguageById = async (languageId) => {
  const response = await Get(`${_endpoint}/GetById?id=${languageId}`, true);
  return response;
};

export const addLanguage = async (requestBody) => {
  const response = await PostForm(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const editLanguage = async (requestBody) => {
  const response = await PostForm(`${_endpoint}/Update`, requestBody, true);
  return response;
};
