import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import { toast } from "react-toastify";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { getAllClients } from "../../services/client.service";
import { KPSearchSelect } from "../partials/KPSearchSelect";
import CreateClient from "../client/CreateClient";
import { getAllCities } from "../../services/city.service";
import { getCityNeighborhoods } from "../../services/neighborhood.service";
import { createOrder } from "../../services/order.service";

import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const AddOrder = ({ t, history }) => {
  // KPSearchSelect Options
  const [clientOption, setClientOption] = useState({});
  const [cityOptions, setCityOptions] = useState({});
  const [neighborhoodOptions, setNeighborhoodOptions] = useState({});

  // Create Order
  const [clientId, setClientId] = useState({});
  const [cityId, setCityId] = useState({});
  const [neighborhoodId, setNeighborhoodId] = useState({});

  // Create Client Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refreshClientOptions, setRefreshClientOptions] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleRefreshClientOptions = () => {
    setRefreshClientOptions(!refreshClientOptions);
  };

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [clientLoading, setClientLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [neighborhoodLoading, setNeighborhoodLoading] = useState(false);
  const isSubmitDisabled =
    neighborhoodId.value && clientId.value && cityId.value;

  const times = [
    {
      label: t("Morning"),
      value: "Morning",
    },
    {
      label: t("Evening"),
      value: "Evening",
    },
  ];

  const styles = {
    container: () => ({
      width: "100%",
      marginInlineEnd: 8,
    }),
  };

  //    FUNCTIONS
  const handleCancel = () => {
    history.push(`${process.env.PUBLIC_URL}/orders`);
  };

  const addOrder = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    setSubmitting(true);
    const request = {
      clientId: clientId.value,
      cityId: cityId.value,
      neighborhoodId: neighborhoodId.value,
      visitPeriod: event.target.time.value,
      notes: event.target.notes.value,
    };
    (async () => {
      const { body, ok } = await createOrder(request);
      if (ok) {
        console.log(body);
        toast.success(t("orderAddedSuccessfuly"));
        history.push(`${process.env.PUBLIC_URL}/orders`);
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    setClientLoading(true);
    (async () => {
      const { body, ok } = await getAllClients();
      if (ok) {
        const options = body.map((client) => ({
          value: client.id,
          label: `${client.firstName} ${client.sureName} / ${client.phoneNumber}`,
        }));
        setClientOption(options);
      }

      setClientLoading(false);
    })();
  }, [refreshClientOptions]);

  useEffect(() => {
    setCityLoading(true);
    setNeighborhoodLoading(true);
    (async () => {
      const { body, ok } = await getAllCities();
      if (ok) {
        const options = body.map((city) => ({
          value: city.id,
          label: city.localizedName,
        }));
        setCityOptions(options);
      }

      setCityLoading(false);
      setNeighborhoodLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(cityId).length > 0) {
      setNeighborhoodLoading(true);
      (async () => {
        const { body, ok } = await getCityNeighborhoods(cityId.value);

        if (ok) {
          const options = body.map((neighborhood) => ({
            value: neighborhood.id,
            label: neighborhood.localizedName,
          }));
          setNeighborhoodOptions(options);
        }
        setNeighborhoodLoading(false);
      })();
    }
    // return () => {
    //   setNeighborhoodOptions({}); // This worked for me
    // };
  }, [cityId]);

  return (
    <Fragment>
      <CreateClient
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshClientOptions}
      />

      <Breadcrumb parent={t("orders")} title={t("addOrder")} backTo="true" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{t("addOrder")}</h5>
              </CardHeader>
              <Form className="form theme-form" onSubmit={addOrder}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {t("clientName")}
                        </Label>
                        <Col sm="9 d-flex">
                          <KPSearchSelect
                            options={clientOption}
                            setValue={setClientId}
                            style={styles}
                            loading={clientLoading}
                          />
                          <Button
                            color="primary"
                            onClick={toggleModal}
                            disabled={submitting}
                          >
                            +
                          </Button>
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {t("city")}
                        </Label>
                        <Col sm="9">
                          <KPSearchSelect
                            options={cityOptions}
                            setValue={setCityId}
                            loading={cityLoading}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {t("neighborhood")}
                        </Label>
                        <Col sm="9">
                          <KPSearchSelect
                            options={neighborhoodOptions}
                            setValue={setNeighborhoodId}
                            loading={neighborhoodLoading}
                            value={neighborhoodId}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {t("visitPeriod")}
                        </Label>
                        <Col
                          sm="9"
                          className="d-flex justify-content-around align-items-center"
                        >
                          {times.map((time, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="time"
                                value={time.value}
                                key={index}
                                defaultChecked={time.value === "Morning"}
                                style={{ marginInlineEnd: 4 }}
                              />
                              {time.label}
                            </div>
                          ))}
                        </Col>
                      </FormGroup>
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {t("notes")}
                        </Label>
                        <Col sm="9">
                          <textarea
                            className="form-control"
                            name="notes"
                            rows="5"
                            cols="5"
                            placeholder={t("notesPlaceholder")}
                          ></textarea>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={!isSubmitDisabled || submitting}
                  >
                    {t("save")}
                  </Button>
                  <Button color="light" type="reset" onClick={handleCancel}>
                    {t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(AddOrder));
