import React, { useState } from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { assignDeliverDate } from "../../../services/order.service";

import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const AssignDeliveryDate = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshData = () => {},
  orderId,
}) => {
  // STATES
  const [deliveryData, setDeliveryData] = useState(new Date());

  // Store
  const dispatch = useDispatch();

  //  FUNCTIONS
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      id: orderId,
      installingVisitDate: deliveryData.toLocaleDateString("en-GB"),
    };
    // console.log(requestBody)
    (async () => {
      const { ok } = await assignDeliverDate(requestBody);
      if (ok) {
        setTimeout(() => {
          toast.success(t("assignDeliveryDateDone"));
        }, 200);

        toggleRefreshData();
        // setLoading(false);
      }
      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("AssignDeliverDate")}</ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup className="form-row">
              <label className="col-4 mb-0">{t("date")}</label>
              <div className="col-7 p-0 mb-0">
                <DatePicker
                  name="date"
                  className="form-control"
                  selected={deliveryData}
                  onChange={setDeliveryData}
                  minDate={new Date()}
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="danger" type="submit">
              {t("confirm")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(AssignDeliveryDate));
