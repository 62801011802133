import environment from "../environment";
import { Get, Post } from "./http.service";


const _endpoint = environment.MESSAGE_TEMPLATE_ENDPOINT;

export const getMessageTemplateById = async (messageTemplateId) => {
  const response = await Get(
    `${_endpoint}/Get?id=${messageTemplateId}`,
    true
  );
  return response;
};

export const updateMessageTemplate = async (requestBody) => {
  const response = await Post(`${_endpoint}/Update`, requestBody, true);
  return response;
};
