import React, { useState } from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { processOrderManualPayment } from "../../../services/order.service";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const ProcessOrderManualPaymentModal = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshData = () => {},
  orderId,
}) => {
  let bodyFormData;
  const [paymentData, setPaymentData] = useState([]);

  // Store
  const dispatch = useDispatch();

  const ACCEPT_TYPES = "image/*, application/pdf";
  let files = [];
  const handlePaymentDataChange = (event) => {
    for (const value of event.target.files) {
      files.push(value);
    }
    setPaymentData(files);
  };

  const handleSubmit = (event) => {
    bodyFormData = new FormData();
    toggleModal();
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    bodyFormData.append("id", orderId);
    bodyFormData.append("invoice", paymentData[0]);
    (async () => {
      const { ok } = await processOrderManualPayment(bodyFormData);
      if (ok) {
        setTimeout(() => {
          toast.success(t("PaymentProcessDone"));
        }, 200);
        toggleRefreshData();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("manualPaymentProcess")}</ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup className="row col-12 align-items-center">
              <Label
                className="col-sm-12 col-form-label label-required"
                htmlFor="manualDesign"
              >
                {t("manualPaymentProcessMsg")}
              </Label>
              <CustomInput
                className="col-12"
                id="paymentData"
                name="paymentData"
                label={`${t("pleaseSelectFile")}`}
                type="file"
                multiple
                accept={ACCEPT_TYPES}
                // value={""}
                onChange={handlePaymentDataChange}
                required
              ></CustomInput>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="danger" type="submit">
              {t("confirm")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(ProcessOrderManualPaymentModal));
