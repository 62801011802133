import React from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,
    Table
} from 'reactstrap';
import NoData from '../../partials/NoData';
const Devices = (props) => {
    const data = props.data.devices ||
        [
            {
                typeOfAccessories: props.t("notEntered"),
                quantity: props.t("notEntered"),
                price: props.t("notEntered"),
                notes: props.t("notEntered")
            },];

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <div className="table-responsive">
                            <Table size="sm">
                                <thead>
                                    <tr className='thead-dark'>
                                        <th scope="col">{"#"}</th>
                                        <th scope="col">{props.t("typeOfDevices")}</th>
                                        {/* <th scope="col">{props.t("manufacurer")}</th> */}
                                        <th scope="col">{props.t("quantity")}</th>
                                        <th scope="col">{props.t("basicPriceOfDevices")}</th>
                                        <th scope="col">{props.t("sellingPrice")}</th>
                                        <th scope="col">{props.t("notes")}</th>
                                    </tr>
                                </thead>
                                {(data.length === 0) ?
                                    (
                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <NoData message={props.t("noData")} />
                                                </td>
                                            </tr>
                                        </tbody>)
                                    :
                                    (
                                        <>
                                            <tbody>
                                                {data.map((item, index) =>
                                                    <tr>
                                                        <th scope="row" className="font-weight-bold">{index + 1}</th>
                                                        <td className="font-weight-bold">{item.product || props.t("notEntered")}</td>
                                                        <td className="font-weight-bold">{item.quantity || props.t("notEntered")}</td>
                                                        <td className="font-weight-bold">{item.unitPrice || props.t("notEntered")}</td>
                                                        <td className="font-weight-bold">{item.productSellingPrice || props.t("notEntered")}</td>
                                                        <td className="font-weight-bold">{item.notes || props.t("notEntered")}</td>
                                                    </tr>
                                                )
                                                }

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <th className="font-weight-bold">{props.t("totalAmount")}:</th>
                                                    <th className="font-weight-bold">{props.data.devicesSubTotal}</th>
                                                </tr>
                                            </tfoot>
                                        </>
                                    )
                                }
                            </Table>

                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>

    )
}
export default withRouter(translate(Devices));

