import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Popover,
  PopoverBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";

import { getMarbleCompanies } from "../../services/lookup.service";
import {
  getOrderById,
  isContractNumberExist,
} from "../../services/order.service";
import { getAllProducts, getProduct } from "../../services/products.service";
import Loader from "../partials/Loader";
import AccessoriesTable from "../partials/Tables/AccessoriesTable";
import DevicesTable from "../partials/Tables/DevicesTable";
import MetersTable from "../partials/Tables/MetersTable";
import { orderRegisterContract } from "../../services/order.service";
import { toast } from "react-toastify";
import { ImagesOverlayViewer } from "../partials/ImageOverlayViewer";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import { getBranchById } from "../../services/branch.service";

const ContractRegistration = ({ t }) => {
  const { orderId } = useParams();

  // Store
  const dispatch = useDispatch();

  // Tables Data
  const [meters, setMeters] = useState({ quantity: 0.0, price: 0.0 });
  const [accessories, setAccessories] = useState([]);
  const [accessoriesOptions, setAccessoriesOptions] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicesOptions, setDevicesOptions] = useState([]);

  // Order Data
  const [client, setClient] = useState({});
  const [marbleCompanies, setMarbleCompanies] = useState([]);
  const [orderCode, setOrderCode] = useState("");
  const [updateNotes, setUpdateNotes] = useState("");

  // Form
  const ACCEPT_TYPES = "image/*, application/pdf";
  const [contractNumber, setContractNumber] = useState("");
  const [kitchenType, setKitchenType] = useState("");
  const [kitchenManualDesign, setKitchenManualDesign] = useState([]);
  const [kitchen3DDesign, setKitchen3DDesign] = useState([]);
  const [doorsHandleImages, setDoorsHandleImages] = useState([]);
  const [washtubImages, setWashtubImages] = useState([]);
  const [contractImage, setContractImage] = useState([]);
  const [marbleCompanyId, setMarbleCompanyId] = useState([]);
  const [entryColorNo, setEntryColorNo] = useState("");
  const [basicColorNo, setBasicColorNo] = useState("");
  const [marbleColorNo, setMarbleColorNo] = useState("");
  const [deletedFiles, setDeletedFiles] = useState([]);

  // Form Validation
  const [metersTableIsValid, setMetersTableIsValid] = useState(false);
  const [accessoriesTableIsValid, setAccessoriesTableIsValid] = useState(false);
  const [devicesTableIsValid, setDevicesTableIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [validContractNumber, setValidContractNumber] = useState(true);

  // UI/UX
  const [loading, setLoading] = useState(true);
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true);
  const languageKey = useSelector((state) => state.language.key);
  const history = useHistory();
  const [manualImagesURL, setManualImagesURL] = useState([]);
  const [threeDImagesURL, setThreeDImagesURL] = useState([]);
  const [contractImageURL, setContractImageURL] = useState([]);
  const [doorsHandleImagesURL, setDoorsHandleImagesURL] = useState([]);
  const [washtubImagesURL, setWashtubImagesURL] = useState([]);
  const [marbleCompanyIdString, setMarbleCompanyIdString] = useState("");
  const [contractNumberCheckLoading, setContractNumberCheckLoading] =
    useState(false);

  const [branchCode, setBranchCode] = useState("");
  const handleFileInputChange = (event, setFunction = () => {}) => {
    let files = [];
    for (const value of event.target.files) {
      files.push(value);
    }
    setFunction(files);
  };

  const convertUrlToUrlImageObject = (urlsArray) => {
    return urlsArray.map((url) => ({
      url,
      image: url.split(".")[url.split(".").length - 1] !== "pdf",
    }));
  };

  const appendArrayOfFilesToFormData = (
    arrayOfFiles = [],
    formData = new FormData(),
    key = ""
  ) => {
    if (arrayOfFiles.length > 0) {
      arrayOfFiles.forEach((file, index) => {
        formData.append(key, arrayOfFiles[index], file.name);
      });
    } else {
      formData.append(key, "");
    }
  };

  const displayFilesIcons = (
    filesArray,
    setURLFunction = () => {},
    setDeletedFunction = () => {}
  ) => {
    return (
      <div className="row col-12 align-items-center mb-4">
        <div className="col-9" style={{ marginInlineStart: "25%" }}>
          <ImagesOverlayViewer
            imagesArray={filesArray}
            setFunction={setURLFunction}
            setDeletedFiles={setDeletedFunction}
          />
        </div>
      </div>
    );
  };

  const registerContract = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));

    const formData = new FormData();

    const formBody = {
      // Form
      orderId,
      kitchenType,
      contractNumber: `${branchCode}${contractNumber}`,
      marbleCompanyId: marbleCompanyId.length ? marbleCompanyId[0].id : "",

      contractImage: contractImage[0],

      entryColorNo,
      basicColorNo,
      marbleColorNo: event.target.marbleColorNo.value,

      // Meters Table
      totalMeters: parseFloat(meters.quantity),
      meterPrice: parseFloat(meters.price),
      totalMeterPrice: parseFloat(
        parseFloat(+meters.quantity * +meters.price).toFixed(4)
      ),
    };

    accessories.forEach((accessory, index) => {
      formBody[`accessories[${index}].ProductId`] = accessory.id;
      formBody[`accessories[${index}].Quantity`] = accessory.quantity;
      formBody[`accessories[${index}].ProductSellingPrice`] = parseFloat(
        accessory.price
      );
      formBody[`accessories[${index}].Notes`] = accessory.notes;
    });

    devices.forEach((device, index) => {
      formBody[`devices[${index}].ProductId`] = device.id;
      formBody[`devices[${index}].ProductSellingPrice`] = parseFloat(
        device.sellingPrice
      );
      formBody[`devices[${index}].UnitPrice`] = parseFloat(device.price);
    });

    deletedFiles.forEach((fileURL, index) => {
      formBody[`deletedFiles[${index}]`] = fileURL;
    });

    for (const [key, value] of Object.entries(formBody)) {
      formData.append(key, value);
    }

    appendArrayOfFilesToFormData(
      kitchenManualDesign,
      formData,
      "kitchenManualDesign"
    );
    appendArrayOfFilesToFormData(kitchen3DDesign, formData, "kitchen3DDesign");
    appendArrayOfFilesToFormData(
      doorsHandleImages,
      formData,
      "doorsHandleImages"
    );
    appendArrayOfFilesToFormData(washtubImages, formData, "washtubImages");

    (async () => {
      const { ok } = await orderRegisterContract(formData);
      dispatch(setGlobalLoading({ status: false }));
      if (ok) {
        toast.success(t("contractRegistrationSuccess"));
        history.goBack();
      }
    })();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const checkContractNumber = (value) => {
    const REQUEST_PARAMS = {
      id: orderId,
      contractNumber: value,
    }
    setContractNumberCheckLoading(true);
    (async () => {
      const { body, ok } = await isContractNumberExist(REQUEST_PARAMS);
      if (ok) {
        setValidContractNumber(!body);
      }

      setContractNumberCheckLoading(false);
    })();
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      setLoading(true);
      const { body, ok } = await getAllProducts();
      if (ok) {
        const products = { Accessories: [], Device: [] };
        body.forEach((product) => {
          products[product.productType].push(product);
        });
        setAccessoriesOptions(products.Accessories);

        const stringBarcodeDevices = products.Device.map((device) => ({
          ...device,
          barcode: `${device.barcode}`,
        }));
        setDevicesOptions(stringBarcodeDevices);
      }
    })();
    if (orderId) {
      (async () => {
        setLoading(true);
        const { body, ok } = await getOrderById(orderId);
        if (ok) {
          const response = await getBranchById(body.branchId);
          setClient(body.client);
          setOrderCode(body.orderCode);

          // Info to edit the contract
          setMarbleCompanyIdString(body.marbleCompanyId);
          setKitchenType(body.kitchenType ?? "");
          setEntryColorNo(body.entryColorNo ?? "");
          setBasicColorNo(body.basicColorNo ?? "");
          setMarbleColorNo(body.marbleColorNo ?? "");
          setUpdateNotes(body.updateNotes ?? "");

          setManualImagesURL(
            convertUrlToUrlImageObject(body.kitchenManualDesignFiles)
          );

          setThreeDImagesURL(
            convertUrlToUrlImageObject(body.kitchen3DDesignFiles)
          );

          setContractImageURL(
            convertUrlToUrlImageObject(body.contractImageFile)
          );

          setDoorsHandleImagesURL(
            convertUrlToUrlImageObject(body.doorsHandleImagesFiles)
          );

          setWashtubImagesURL(
            convertUrlToUrlImageObject(body.washtubImagesFiles)
          );

          // Meters Table
          setMeters({
            quantity: body.totalMeters ?? 0.0,
            price: body.meterPrice ?? 0.0,
          });

          // Accessories Table
          if (body.accessories.length) {
            const accessoriesArray = body.accessories.map((accessory) => {
              setAccessoriesOptions((prev) => {
                const newAccessoriesOptions = prev.map((option) => {
                  if (option.id === accessory.productId) {
                    return {
                      ...option,
                      disabled: true,
                    };
                  }
                  return option;
                });
                return newAccessoriesOptions;
              });
              return {
                id: accessory.productId,
                localizedName: accessory.product,
                price: parseFloat(accessory.unitPrice),
                quantity: accessory.quantity,
                notes: accessory.notes,
              };
            });

            setAccessories(accessoriesArray);
          }

          // Devices Table
          if (body.devices.length) {
            let promisesArray = [];
            let devicesArray = [];
            let sellingPrices = {};
            body.devices.forEach((device) => {
              promisesArray.push(
                new Promise((resolve, reject) => {
                  resolve(getProduct(device.productId));
                })
              );

              sellingPrices[device.productId] = parseFloat(
                device.productSellingPrice
              );
            });
            Promise.all(promisesArray)
              .then((response) => {
                response.forEach((data) => {
                  devicesArray.push({
                    ...data.body,
                    sellingPrice: parseFloat(sellingPrices[data.body.id]),
                  });
                });
              })
              .then(() => {
                setDevices(devicesArray);
              });
          }
          if (response.ok) {
            setBranchCode(response.body.code + "-");
            setContractNumber(
              body.contractNumber?.replace(response.body.code + "-", "") ?? ""
            );
          } else {
            setContractNumber(body.contractNumber ?? "");
          }
        }
        window.scrollTo(0, 0);

        setLoading(false);
      })();
    }
  }, [orderId, languageKey]);

  useEffect(() => {
    setLoading(true);
    if (marbleCompanyIdString !== "") {
      (async () => {
        setLoading(true);
        const { body, ok } = await getMarbleCompanies();
        if (ok) {
          setMarbleCompanies(body);
          if (body.length) {
            const companies = body.filter((marbleCompany) => {
              return marbleCompany.id === marbleCompanyIdString;
            });

            if (companies.length) {
              setMarbleCompanyId(companies);
            }
          }
        }
        setLoading(false);
      })();
    }
  }, [marbleCompanyIdString]);

  useEffect(() => {
    setRegisterButtonDisabled(
      !formIsValid ||
        !metersTableIsValid ||
        !accessoriesTableIsValid ||
        !devicesTableIsValid
    );
  }, [
    formIsValid,
    metersTableIsValid,
    accessoriesTableIsValid,
    devicesTableIsValid,
  ]);

  useEffect(() => {
    const isValid =
      contractNumber !== "" &&
      validContractNumber &&
      kitchenType !== "" &&
      (kitchenManualDesign.length > 0 || manualImagesURL.length > 0) &&
      (kitchen3DDesign.length > 0 || threeDImagesURL.length > 0) &&
      (doorsHandleImages.length > 0 || doorsHandleImagesURL.length > 0) &&
      (contractImage.length > 0 || contractImageURL.length > 0);

    setFormIsValid(isValid);
  }, [
    kitchenType,
    contractNumber,
    kitchenManualDesign,
    kitchen3DDesign,
    contractImage,
    manualImagesURL,
    contractImageURL,
    threeDImagesURL,
    validContractNumber,
    doorsHandleImages,
    doorsHandleImagesURL,
  ]);

  return (
    <>
      <Breadcrumb
        id="top"
        parent={t("orderDetails")}
        title={t("orderRegistration")}
        backTo="true"
      ></Breadcrumb>
      <Card>
        {!loading ? (
          <>
            <CardHeader className="py-4">
              <FormGroup className="row col-12">
                <Label className="col-3 col-form-label">
                  {t("clientName")}
                </Label>
                <span className="col-3 form-control font-weight-bold border-0 mb-0">{`${client?.firstName} ${client?.sureName}`}</span>
                <Label className="col-3 col-form-label">
                  {t("phoneNumber")}
                </Label>
                <span
                  dir="ltr"
                  className="col-3 form-control font-weight-bold border-0 mb-0"
                >
                  {client?.phoneNumber}
                </span>
              </FormGroup>

              <FormGroup className="row col-12 align-items-center">
                <Label className="col-3 col-form-label">{t("orderCode")}</Label>
                <span
                  dir="ltr"
                  className="col-9 form-control font-weight-bold border-0 text-uppercase mb-0"
                >
                  {orderCode}
                </span>
              </FormGroup>
              {updateNotes !== "" && (
                <FormGroup className="row col-12 align-items-center">
                  <Label className="col-3 col-form-label">
                    {t("updateNotes")}
                  </Label>
                  <span className="col-9 form-control font-weight-bold border-0 text-uppercase mb-0 text-danger">
                    {updateNotes}
                  </span>
                </FormGroup>
              )}
            </CardHeader>

            <Form onSubmit={registerContract}>
              <CardBody>
                <Row>
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      htmlFor="contractNumber"
                      className="col-3 col-from-label label-required"
                    >
                      {t("contractNumber")}
                    </Label>

                    <InputGroup className="col-3 p-0">
                      <Input
                        className={`form-control ${
                          !validContractNumber &&
                          contractNumber !== "" &&
                          !contractNumberCheckLoading
                            ? "is-invalid"
                            : ""
                        } ${
                          validContractNumber &&
                          contractNumber !== "" &&
                          !contractNumberCheckLoading
                            ? "is-valid"
                            : ""
                        }`}
                        id="contractNumber"
                        name="contractNumber"
                        type="text"
                        value={contractNumber ?? ""}
                        onChange={(event) => {
                          setContractNumberCheckLoading(true);
                          setContractNumber(event.target.value);
                        }}
                        onBlur={(event) =>
                          checkContractNumber(
                            `${branchCode}${event.target.value}`
                          )
                        }
                        style={{ direction: "ltr" }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ direction: "ltr" }}>
                          {branchCode}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>

                    <Popover
                      className="bg-danger"
                      target="contractNumber"
                      isOpen={
                        !validContractNumber &&
                        contractNumber !== "" &&
                        !contractNumberCheckLoading
                      }
                      placement="top"
                    >
                      <PopoverBody className="bg-danger">
                        {t("contractNumberExist")}
                      </PopoverBody>
                    </Popover>

                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="kitchenType"
                    >
                      {t("kitchenType")}
                    </Label>
                    <Input
                      className="col-3"
                      id="kitchenType"
                      name="kitchenType"
                      type="text"
                      value={kitchenType ?? ""}
                      onChange={(event) => {
                        setKitchenType(event.target.value);
                      }}
                    ></Input>
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label"
                      htmlFor="entryColorNo"
                    >
                      {t("entryColorNumber")}
                    </Label>
                    <Input
                      className="col-3"
                      id="entryColorNo"
                      name="entryColorNo"
                      type="text"
                      value={entryColorNo ?? ""}
                      onChange={(event) => setEntryColorNo(event.target.value)}
                    ></Input>
                    <Label
                      className="col-3 col-form-label"
                      htmlFor="basicColorNo"
                    >
                      {t("basicColorNumber")}
                    </Label>
                    <Input
                      className="col-3"
                      id="basicColorNo"
                      name="basicColorNo"
                      type="text"
                      value={basicColorNo ?? ""}
                      onChange={(event) => setBasicColorNo(event.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label"
                      htmlFor="marbleCompany"
                    >
                      {t("marbleCompany")}
                    </Label>
                    <Typeahead
                      className="col-3 px-0"
                      id="marbleCompany"
                      name="marbleCompany"
                      type="text"
                      placeholder={t("selectPlaceholder")}
                      options={marbleCompanies}
                      labelKey={"localizedName"}
                      selected={marbleCompanyId}
                      onChange={(value) => setMarbleCompanyId(value)}
                      clearButton
                    ></Typeahead>
                    <Label
                      className="col-3 col-form-label"
                      htmlFor="marbleColorNo"
                    >
                      {t("marbleColorNumber")}
                    </Label>
                    <Input
                      className="col-3"
                      id="marbleColorNo"
                      name="marbleColorNo"
                      type="text"
                      value={marbleColorNo ?? ""}
                      onChange={(event) => setMarbleColorNo(event.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="manualDesign"
                    >
                      {t("kitchenManualDesign")}
                    </Label>
                    <CustomInput
                      className="col-9"
                      id="manualDesign"
                      name="manualDesign"
                      label={`${t("pleaseSelectFile")} ${t("multipleFiles")}`}
                      type="file"
                      multiple
                      accept={ACCEPT_TYPES}
                      value={""}
                      onChange={(event) =>
                        handleFileInputChange(event, setKitchenManualDesign)
                      }
                    ></CustomInput>
                  </FormGroup>
                  {manualImagesURL.length > 0 &&
                    displayFilesIcons(
                      manualImagesURL,
                      setManualImagesURL,
                      setDeletedFiles
                    )}
                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="design3d"
                    >
                      {t("kitchen3DDesign")}
                    </Label>
                    <CustomInput
                      className="col-9"
                      id="design3d"
                      name="design3d"
                      label={`${t("pleaseSelectFile")} ${t("multipleFiles")}`}
                      type="file"
                      multiple
                      accept={ACCEPT_TYPES}
                      value={""}
                      onChange={(event) =>
                        handleFileInputChange(event, setKitchen3DDesign)
                      }
                    ></CustomInput>
                  </FormGroup>
                  {threeDImagesURL.length > 0 &&
                    displayFilesIcons(
                      threeDImagesURL,
                      setThreeDImagesURL,
                      setDeletedFiles
                    )}

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="contractImage"
                    >
                      {t("doorsHandlesImages")}
                    </Label>
                    <CustomInput
                      className="col-9"
                      id="doorsHandleImages"
                      name="doorsHandleImages"
                      label={`${t("pleaseSelectImage")} ${t("multipleFiles")}`}
                      type="file"
                      multiple
                      accept={ACCEPT_TYPES.slice(0, 7)}
                      value={""}
                      onChange={(event) =>
                        handleFileInputChange(event, setDoorsHandleImages)
                      }
                    ></CustomInput>
                  </FormGroup>
                  {doorsHandleImagesURL.length > 0 &&
                    displayFilesIcons(
                      doorsHandleImagesURL,
                      setDoorsHandleImagesURL,
                      setDeletedFiles
                    )}

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label"
                      htmlFor="contractImage"
                    >
                      {t("kitchenSinkImages")}
                    </Label>
                    <CustomInput
                      className="col-8"
                      id="kitchenSinkImages"
                      name="kitchenSinkImages"
                      label={`${t("pleaseSelectImage")} ${t("multipleFiles")}`}
                      type="file"
                      multiple
                      accept={ACCEPT_TYPES.slice(0, 7)}
                      onChange={(event) =>
                        handleFileInputChange(event, setWashtubImages)
                      }
                    ></CustomInput>
                    <div className="col-1 p-0 d-flex justify-content-end">
                      <Button
                        color="danger"
                        onClick={() => {
                          document.querySelector(
                            "label[for='kitchenSinkImages']"
                          ).textContent = `${t("pleaseSelectImage")} ${t(
                            "multipleFiles"
                          )}`;
                          setWashtubImages([]);
                        }}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  </FormGroup>
                  {washtubImagesURL.length > 0 &&
                    displayFilesIcons(
                      washtubImagesURL,
                      setWashtubImagesURL,
                      setDeletedFiles
                    )}

                  <FormGroup className="row col-12 align-items-center">
                    <Label
                      className="col-3 col-form-label label-required"
                      htmlFor="contractImage"
                    >
                      {t("contractImage")}
                    </Label>
                    <CustomInput
                      className="col-9"
                      id="contractImage"
                      name="contractImage"
                      label={`${t("pleaseSelectFile")}`}
                      type="file"
                      accept={ACCEPT_TYPES}
                      value={""}
                      onChange={(event) =>
                        setContractImage(Array.from(event.target.files))
                      }
                      disabled={contractImageURL.length > 0}
                    ></CustomInput>
                  </FormGroup>
                  {contractImageURL.length > 0 &&
                    displayFilesIcons(
                      contractImageURL,
                      setContractImageURL,
                      setDeletedFiles
                    )}

                  <h5 className="mt-5">{t("meters")}</h5>
                  <MetersTable
                    propValue={meters}
                    setPropValue={setMeters}
                    setIsValid={setMetersTableIsValid}
                  />

                  <h5 className="mt-5">{t("accessories")}</h5>
                  <AccessoriesTable
                    accessoriesValue={accessories}
                    options={accessoriesOptions}
                    setAccessoriesValue={setAccessories}
                    setIsValid={setAccessoriesTableIsValid}
                  />

                  <h5 className="mt-5">{t("devices")}</h5>
                  <DevicesTable
                    devicesValue={devices}
                    options={devicesOptions}
                    setDevicesValue={setDevices}
                    setIsValid={setDevicesTableIsValid}
                  />
                </Row>
              </CardBody>
              <CardFooter className="submit-footer">
                <Button
                  color="primary"
                  className="mr-1"
                  type="submit"
                  disabled={registerButtonDisabled}
                >
                  {t("register")}
                </Button>
                <Button color="light" type="reset" onClick={handleCancel}>
                  {t("cancel")}
                </Button>
              </CardFooter>
            </Form>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </>
  );
};

export default translate(ContractRegistration);
