import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import FsLightbox from 'fslightbox-react';
import { t } from "react-switch-lang";
const ImageOverlayViewer = ({
  setFunction = () => { },
  setDeletedFiles = () => { },
  imagesArray = [{ url: "", image: false }],
}) => {


  let imgs = []
  const [photoIndex, setPhotoIndex] = useState({ index: 0, isOpen: true });
  const [toggler, setToggler] = useState(false);
  const [imageFiles, setImageFiles] = useState([

  ])
  const removeFile = (index, url, isImage, setFunction) => {
    if (window.confirm(t("deleteFilePrompt"))) {
      setFunction((prev) => {
        const removedFile = prev.splice(index, 1)[0];
        setDeletedFiles((prev) => [...prev, removedFile.url]);
        return [...prev];
      });
      if (isImage) {
        console.log("imgs")
        setImageFiles(current =>
          current.filter(element => {
            return element !== url;
          }),
        );
        setPhotoIndex({ index: 0, isOpen: true })
      }
    }
  };


  const filterFiles = () => {
    imagesArray.map((item) => {
      if (item.image) {
        imgs.push(item.url)
        setImageFiles(imgs)
      }
    })

  }

  useEffect(() => {
    filterFiles()
  }, [])
  return (
    <div className="files">
      {imagesArray.map((design, index) => (
        <>
          <div className="kp-file-box"
          //  key={`${index}-file-box`}
          >
            <div
            // key={`${index}-inner`}
            >
              {design.image ? (
                <>
                  <img
                    alt={`${t("kitchenManualDesign")} - ${index + 1}`}
                    className="icon-image"
                    src={design.url}
                    onClick={() => {
                      console.log(photoIndex)
                      setToggler(!toggler)
                      setPhotoIndex(() => ({
                        ...photoIndex,
                        index,
                        isOpen: true,

                      }))

                    }}
                  // key={`${index}-image`}
                  />
                  <i
                    className="close-icon fa fa-times"
                    onClick={() => removeFile(index, design.url, design.image, setFunction)}
                  // key={`${index}-close-image`}
                  ></i>
                </>
              ) : (
                <>
                  <img
                    src={require("../../../assets/images/pdf-icon4.png")}
                    onClick={() =>
                      window.open(design.url, "_blank", "noopener,noreferrer")
                    }
                    // key={`${index}-PDF`}
                    alt={`${t("kitchenManualDesign")} - ${index + 1}`}
                    width="40"
                  ></img>

                  <i
                    className="close-icon fa fa-times"
                    onClick={() => removeFile(index, design.url, design.image, setFunction)}
                  // key={`${index}-close-pdf`}
                  ></i>
                </>
              )}
            </div>
          </div>
        </>
      ))}
      {photoIndex.isOpen && (
        <FsLightbox
          toggler={toggler}
          sources={imageFiles}
          type="image"
          sourceIndex={photoIndex.index}
        />
        /* <Lightbox
          mainSrc={imagesArray[photoIndex.index].url}
          nextSrc={imagesArray[photoIndex.index].url}
          prevSrc={imagesArray[photoIndex.index].url}
          onCloseRequest={() =>
            setPhotoIndex(() => ({ ...photoIndex, isOpen: false }))
          }
          key={`Lightbox ${photoIndex.index}`}
        />
      */
      )}
    </div>
  );
};

export default ImageOverlayViewer;
