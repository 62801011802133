import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderById,
  updateOrderDateTime,
  getAssignOrderData,
} from "../../services/order.service.js";
import Loader from "../partials/Loader";
import { setEditOrderId } from "../../store/slices/orderSlice";
import { setGlobalLoading } from "../../store/slices/loadingSlice.js";
import { Typeahead } from "react-bootstrap-typeahead";

const EditOrder = ({
  isOpen,
  toggleModal = () => { },
  t,
  setSubmitting = () => { },
  toggleRefreshTable = () => { },
}) => {
  const orderId = useSelector((state) => state.order.id);

  const [visitDate, setVisitDate] = useState(new Date());
  const [visitTime, setVisitTime] = useState();
  const [technicians, setTechnicians] = useState([]);
  const [selectedTech, setSelectedTech] = useState([]);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(true);

  const postData = (e) => {
    e.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      id: orderId,
      visitDate: visitDate.toLocaleDateString("en-GB"),
      visitTime: visitTime,
      measureTechnicianId: selectedTech[0]?.id,
    };

    (async () => {
      const { ok } = await updateOrderDateTime(requestBody);
      if (ok) {
        toast.success(t("orderEditedSuccessfuly"));
      }

      setVisitDate(new Date());
      setVisitTime();
      dispatch(setEditOrderId({ id: "" }));
      toggleRefreshTable();
      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    let selectedTechId = "";
    setSelectedTech([]);
    if (orderId !== "" && orderId) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getOrderById(orderId);
        if (ok) {
          selectedTechId = body.measureTechnicianId ?? "";
          console.log(body);
          setVisitDate(
            new Date(
              +body.visitDate.slice(6),
              +body.visitDate.slice(3, 5) - 1,
              +body.visitDate.slice(0, 2) - 1
            )
          );
          setVisitTime(
            body.visitTime24
          )

        }
        (async () => {
          const { body, ok } = await getAssignOrderData(orderId);
          if (ok) {
            setTechnicians(body.technichians);
            if (selectedTechId !== "") {
              setSelectedTech(
                body.technichians.filter((tech) => tech.id === selectedTechId)
              );
            }
          }
          setLoading(false);
        })();
      })();
    }
  }, [orderId]);

  const saveButtonDisabled = () => {
    return selectedTech.length === 0;
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("editOrder")}</ModalHeader>
        {loading ? (
          <Loader />
        ) : (
          <Form className="form theme-form" onSubmit={postData}>
            <ModalBody>
              <FormGroup className="form-row">
                <label className="col-4 mb-0">{t("measureTechnician")}</label>
                <Typeahead
                  className="col-7 p-0"
                  placeholder={t("selectPlaceholder")}
                  options={technicians}
                  labelKey={"displayName"}
                  id="select-assign-tech"
                  defaultSelected={selectedTech}
                  clearButton
                  onChange={(value) => setSelectedTech(value)}
                />
              </FormGroup>
              <FormGroup className="form-row">
                <label className="col-4 mb-0">{t("date")}</label>
                <div className="col-7 p-0 mb-0">
                  <DatePicker
                    name="date"
                    className="form-control"
                    selected={visitDate}
                    onChange={setVisitDate}
                    minDate={new Date()}
                  />
                </div>
              </FormGroup>
              <FormGroup className="form-row">
                <label className="col-4 mb-0">{t("time")}</label>
                <div className="col-7 p-0">
                  <TextField
                    id="time"
                    label={
                      <i className="fa fa-clock-o"></i>
                    }
                    type="time"
                    value={visitTime}
                    onChange={(value) => setVisitTime(value.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputProps: {
                        step: 300, // 5 min
                      }
                    }}
                    // error={timeError}
                    sx={{ width: 276 }}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                color="primary"
                type="submit"
                disabled={saveButtonDisabled()}
              >
                {t("save")}
              </Button>
              <Button color="light" onClick={toggleModal} type="button">
                {t("cancel")}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    </>
  );
};
export default withRouter(translate(EditOrder));
