import { createSlice } from "@reduxjs/toolkit";

const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    id: "",
  },
  reducers: {
    setLookupToEdit: (state, { payload }) => {
      state.id = payload.id;
    },
  },
});

export const { setLookupToEdit } = lookupSlice.actions;

export default lookupSlice.reducer;
