import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardFooter,
  Input,
  Label,
  Collapse,
  FormGroup,
  Form,
  CardHeader,
} from "reactstrap";

import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import ApproveModal from "./modals/ApproveModal";
import RejectModal from "./modals/RejectModal";
import { getAllMaintenanceRequests, getMaintenanceRequestStatus } from '../../services/maintenance.service';
import { handleError } from "../../services/error.service";
import { KPTable } from '../partials/KPTable'
import environment from "../../environment";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import TimePeriodLocalization from "../partials/TimePeriodLocalization/TimePeriodLocalization";
import { useSelector } from "react-redux";
const MaintenanceOrders = ({ t, history }) => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [requestStatusData, setRequestStatusData] = useState();

  // Request Options
  const [status, setStatus] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  // UI/UX
  const [refreshTable, setRefreshTable] = useState(false);
  const langaugeKey = useSelector((state) => state.language.key);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [requestStatusLoading, setRequestStatusLoading] = useState(false);

  const toggleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };
  const [details, setDetails] = useState(false);

  const [requestOptions, setRequestOptions] = useState({
    orderId: "",
    orderCode: "",
    contractNo: "",
    clientPhoneNumber: "",
    requestStatus: [
      0
    ],
    fromDate: "",
    toDate: "",
    orderBy: "",
    searchText: ""
  });
  // Modals
  const [isApprove, setIsApprove] = useState(false);
  const [isReject, setIsReject] = useState(false);

  const toggleApprove = () => setIsApprove(!isApprove);
  const toggleReject = () => setIsReject(!isReject);


  // Store 


  //  Claims
  const claimsData = JSON.parse(localStorage.getItem("newClaims"));

  const handleClaims = (claim) => {
    if (claimsData.claims.indexOf(claim) > -1) return true;
    else return false;
  };

  const handleBtn = () => {
    setDetails(!handleClaims("Maintenance.ViewDetails"));
  };
  // handle status design
  const handleStatus = (row) => {
    if (row.requestStatus === "Rejected") {
      return (
        <p
          className="text-danger font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.requestStatusLocalized || t("notEntered")}
        </p>
      );
    } else if (
      row.requestStatus === "UpdatedRequest" ||
      row.requestStatus === "InstallingProcessing"
    ) {
      return (
        <p
          className=" text-warning font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.requestStatusLocalized || t("notEntered")}
        </p>
      );
    } else {
      return (
        <p
          class="text-success font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.requestStatusLocalized || t("notEntered")}
        </p>
      );
    }
  };
  //  DAta Table Data 
  const tableColumns = [
    {
      name: t("contractNumber"),
      selector: "contractNumber",
      center: true,
      minWidth: "130px",
    },
    {
      name: t("status"),
      selector: "requestStatusLocalized",
      cell: (row) => handleStatus(row),
      center: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: t("contractDate"),
      selector: (row) =>
        row.createdOn.substring(0, row.createdOn.length - 3) + " " +
        TimePeriodLocalization(row.createdOn.substring(row.createdOn.length - 2, row.createdOn.length))
      ,
      center: true,
      minWidth: "190px",
    },
    {
      name: t("clientName"),
      selector: (row) => row.client.firstName + " " + row.client.sureName,
      center: true,
      minWidth: "150px",
    },
    {
      name: t("clientPhone"),
      selector: "client.phoneNumber",
      center: true,
      minWidth: "150px",
    },
    {
      cell: (row) =>
        handleClaims("Maintenance.ViewDetails") ? (
          <Button
            color="primary"
            size="sm"
            className="mr-1"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/orders/contract/details/${row.id}`);
            }}
          >
            {t("details")}
          </Button>
        ) : (
          <div></div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: details,
      minWidth: "120px",
    },

  ];

  const getData = () => {
    (async () => {
      setLoading(true);
      const { body, status, ok } = await getAllMaintenanceRequests();
      if (ok) {
        setData(body);
        console.log(body)
      } else {
        console.log(body)
        handleError({
          response: {
            data: body,
            status,
          },
        });
      }
      setLoading(false);
    })();
  }

  useEffect(() => {
    getData();
    handleBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFilteredData = (value) => {
    let requestStatusToSubmit = [];
    if (value === "reset") {
      setRequestOptions({
        orderId: "",
        orderCode: "",
        contractNo: "",
        clientPhoneNumber: "",
        requestStatus: [
          0
        ],
        fromDate: "",
        toDate: "",
        orderBy: "",
        searchText: "",
      });
    } else {
      requestStatusToSubmit = [];
      if (status.length > 0) {
        requestStatusToSubmit = status.map((item) => item.id);
      }
      setRequestOptions({
        orderId: "",
        contractNo: contractNumber || "",
        orderCode: orderCode || "",
        clientPhoneNumber: phoneNumber || "",
        requestStatus: requestStatusToSubmit,
        fromDate: fromDate ? fromDate.toLocaleDateString("en-GB") : "",
        toDate: toDate ? toDate.toLocaleDateString("en-GB") : "",
        orderBy: "string",
        searchText: "string",
      });
    }
    console.log(requestOptions);
    toggleRefreshTable();
  };
  const getRequestStatus = () => {
    (async () => {
      setRequestStatusLoading(true);
      const { body, ok } = await getMaintenanceRequestStatus();
      if (ok) {
        setRequestStatusData(body);
      }

      setRequestStatusLoading(false);
    })();
  };
  const resetData = () => {
    setOrderCode("");
    setContractNumber("");
    setPhoneNumber("");
    setStatus([]);
    setFromDate("");
    setToDate("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  useEffect(() => {
    getRequestStatus();
  }, [refreshTable, langaugeKey]);
  const handleInputStatusChange = (input) => {
    setStatus(input);
  };
  // const [dropdownOpen, setOpen] = useState(false);
  // const toggle = () => setOpen(!dropdownOpen);
  return (
    <Fragment>
      <ApproveModal
        isOpen={isApprove}
        toggleModal={toggleApprove}
        orderId={id}
        toggleRefreshData={toggleRefreshTable}
      ></ApproveModal>
      <RejectModal
        isOpen={isReject}
        toggleModal={toggleReject}
        orderId={id}
        toggleRefreshData={toggleRefreshTable}
      ></RejectModal>
      <Breadcrumb parent={t("customer")} title={t("contractsList")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("contractNumber")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="contractNumber"
                              max="10"
                              value={contractNumber}
                              onChange={(event) => {
                                setContractNumber(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("phoneNumber")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="number"
                              name="phoneNumber"
                              max="10"
                              value={phoneNumber}
                              onChange={(event) => {
                                setPhoneNumber(
                                  event.target.value.replace(/\s+/g, "")
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("from")}
                          </Label>
                          <Col sm="8">
                            <DatePicker
                              name="date"
                              className="form-control"
                              selected={fromDate}
                              onChange={setFromDate}
                            // minDate={new Date()}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      {/* Second Column */}
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("orderCode")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="orderCode"
                              max="10"
                              value={orderCode}
                              onChange={(event) => {
                                setOrderCode(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("status")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              // className="col-8 mb-0"
                              id="selectstatus"
                              labelKey="name"
                              multiple
                              options={requestStatusData}
                              selected={status}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleInputStatusChange}
                              isLoading={requestStatusLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("to")}
                          </Label>
                          <Col sm="8">
                            <DatePicker
                              name="date"
                              className="form-control"
                              selected={toDate}
                              onChange={setToDate}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <KPTable
                  endpoint={`${environment.MAINTENANCE_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                  pageSize={30}
                  requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </Fragment>
  )
}
export default withRouter(translate(MaintenanceOrders));
