import React, { useState, Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { translate } from "react-switch-lang";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { deleteRole } from "../../services/role.service";
import ConfirmationModal from "../partials/ConfirmationModal";
import environment from "../../environment";
import { KPTable } from "../partials/KPTable";

const ViewRoles = (props) => {
  const [roleId, setRoleId] = useState("");

  // UI/UX
  const [isConfirm, setIsConfirm] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);
  const toggleRefreshTableData = () => {
    setRefreshTable(!refreshTable);
  };

  const tableColumns = [
    {
      name: props.t("name"),
      selector: "name",

      center: true,
    },

    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            props.history.push(
              `${process.env.PUBLIC_URL}/roles/edit/${row.id}`
            );
          }}
        >
          {props.t("edit")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setRoleId(row.id);
            setIsConfirm(true);
          }}
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Fragment>
      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={roleId}
        toggleRefreshData={toggleRefreshTableData}
        apiToCall={deleteRole}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb parent={props.t("roles")} title={props.t("viewRoles")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={() => {
                      props.history.push(`${process.env.PUBLIC_URL}/roles/add`);
                    }}
                    size="sm"
                  >
                    {props.t("addRolebtn")}
                  </Button>
                </CardTitle>
                <KPTable
                  endpoint={`${environment.ROLE_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(ViewRoles));
