import { Get, GetNoHandleError, Post, PostForm } from "./http.service";
import environment from "../environment";

const _endpoint = environment.MAINTENANCE_REQUEST_PUBLIC_ENDPOINT;


export const getClientOrders = async (requestBody) => {
  const response = await Post(
    `${_endpoint}/GetClientOrders`,
    requestBody,
    true
  );
  return response;
};

export const createMaintenanceRequest = async (requestBody) => {
  const response = await PostForm(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const getMaintenanceRequestForAnOrder = async (requestBody) => {
  const response = await Post(`${_endpoint}/Search`, requestBody, true);
  return response;
};

export const getMaintenanceRequestById = async (id) => {
  const response = await Get(`${_endpoint}/Get?id=${id}`, true);
  return response;
};

export const checkOrders = async () => {
  const response = await GetNoHandleError(`${_endpoint}/CheckOrders`, true);
  return response;
};
