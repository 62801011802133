import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogOut, Lock } from "react-feather";
import { useHistory } from "react-router-dom";
import { firebase_app } from "../../data/config";
import { useAuth0 } from "@auth0/auth0-react";
import Bookmark from "../../layout/bookmark";
import { Link } from "react-router-dom";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";

import { English } from "../../constant";

import { switchLanguage } from "../../store/slices/languageSlice";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ar from "../../assets/i18n/ar.json";
import { InputGroup, InputGroupAddon, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { switchTheme } from "../../store/slices/themeSlice";
import { setProfileName } from "../../store/slices/profile.slice";
import { getProfileInfo } from "../../services/users.srvices";

setTranslations({ en, es, pt, fr, du, cn, ar });
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = ({ t, customer = false }) => {
  const history = useHistory();

  const name = useSelector((state) => state.profile.profileName);
  const role = useSelector((state) => state.profile.role);
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [langdropdown, setLangdropdown] = useState(false);
  const moonlight = useSelector((state) => state.theme.appliedTheme);

  const [selected, setSelected] = useState(
    useSelector((state) => state.language.key)
  );
  const [cartDropdown, setCartDropDown] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [chatDropDown, setChatDropDown] = useState(false);

  // auth0 profile
  const { logout } = useAuth0();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const dispatch = useDispatch();

  const logOutFun = () => {
    localStorage.clear();
    dispatch(
      setProfileName({
        profileName: "",
        role: "",
      })
    );
    customer
      ? history.push(`${process.env.PUBLIC_URL}/register`)
      : history.push(`${process.env.PUBLIC_URL}/login`);
  };
  const ProfileFun = () => {
    history.push(`${process.env.PUBLIC_URL}/profile`);
  };

  const handleSetLanguage = (key) => {
    setLangdropdown(false);
    setSelected(key);
    dispatch(switchLanguage({ key }));
  };

  // const Logout_From_Firebase = () => {
  //   localStorage.removeItem('profileURL')
  //   localStorage.removeItem('token');
  //   firebase_app.auth().signOut()
  //   history.push(`${process.env.PUBLIC_URL}/login`)
  // }

  const Logout_From_Auth0 = () => {
    localStorage.removeItem("auth0_profile");
    localStorage.setItem("authenticated", false);
    history.push(`${process.env.PUBLIC_URL}/login`);
    logout();
  };

  const RedirectToChats = () => {
    history.push(`${process.env.PUBLIC_URL}/app/chat-app`);
  };

  const RedirectToCart = () => {
    history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`);
  };

  const UserMenuRedirect = (redirect) => {
    history.push(redirect);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.remove("open");
    }
  };

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language);
    } else {
      setLangdropdown(!language);
    }
  };

  const MoonlightToggle = (appliedTheme) => {
    dispatch(switchTheme({ appliedTheme }));
  };

  useEffect(() => {
    (async () => {
      const { body, ok, status } = await getProfileInfo();
      if (ok) {
        dispatch(
          setProfileName({
            profileName: body.displayName,
            role: body.role,
          })
        );
      }
    })();
  }, []);
  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus" style={{ height: "100%" }}>
          <li className="language-nav">
            <div
              className={`translate_wrapper ${langdropdown ? "active" : ""}`}
            >
              <div className="current_lang">
                <div
                  className="lang"
                  onClick={() => LanguageSelection(langdropdown)}
                >
                  <i
                    className={`flag-icon flag-icon-${
                      selected === "en"
                        ? "us"
                        : selected === "ar"
                        ? "sa"
                        : selected
                    }`}
                  ></i>
                  <span className="lang-txt">{selected}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? "active" : ""}`}>
                <div className="lang" onClick={() => handleSetLanguage("en")}>
                  <i className="flag-icon flag-icon-us"></i>
                  <span className="lang-txt">
                    {English}
                    <span> {"(US)"}</span>
                  </span>
                </div>
                {/* <div className="lang" onClick={() => handleSetLanguage('du')}><i className="flag-icon flag-icon-de"></i><span className="lang-txt">{Deutsch}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('es')}><i className="flag-icon flag-icon-es"></i><span className="lang-txt">{Español}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('fr')}><i className="flag-icon flag-icon-fr"></i><span className="lang-txt">{Français}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('pt')}><i className="flag-icon flag-icon-pt"></i><span className="lang-txt">{Português}<span> {"(BR)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('cn')}><i className="flag-icon flag-icon-cn"></i><span className="lang-txt">{简体中文}</span></div> */}
                {/* <div className="lang" onClick={() => handleSetLanguage('ae')}><i className="flag-icon flag-icon-ae"></i><span className="lang-txt"> */}
                <div className="lang" onClick={() => handleSetLanguage("ar")}>
                  <i className="flag-icon flag-icon-sa"></i>
                  <span className="lang-txt">
                    {"العربية"}
                    <span> {"(AR)"}</span>
                  </span>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${
                  moonlight === "dark-only" ? "fa-lightbulb-o" : "fa-moon-o"
                }`}
              ></i>
            </div>
          </li>
          <li
            className="profile-nav onhover-dropdown p-0"
            style={{ height: "100%", minWidth: "150px" }}
          >
            <div className="media profile-media" style={{ height: "100%" }}>
              <div className="media-body d-flex flex-column justify-content-center hei-80">
                <p className="mb-2 font-roboto">{name}</p>
                <p className="mb-0 font-roboto">
                  {role}
                  <i
                    className="middle fa fa-angle-down"
                    style={{ marginInlineStart: "0.5rem" }}
                  ></i>
                </p>
              </div>
            </div>
            <ul
              className="profile-dropdown onhover-show-div top-80"
              style={{ right: "-30px", width: "180px" }}
            >
              {!customer && (
                <li onClick={ProfileFun} style={{ width: "180px" }}>
                  <Lock />
                  <span>{t("changePassword")}</span>
                </li>
              )}

              <li onClick={logOutFun}>
                <LogOut />
                <span>{t("logout")}</span>
              </li>
            </ul>
          </li>
          <li className="logout-mobile" onClick={logOutFun}>
            <LogOut />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
