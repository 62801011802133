import React from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';

const ContractBasicInformation = (props) => {


    return (
        <div>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form className="form theme-form"
                            // onSubmit={handleSubmit}
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("contractNumber")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.contractNumber || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("typeOfKitchen")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.kitchenType || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("insertionColourNumber")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.entryColorNo || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("basicColourNumber")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.basicColorNo || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("marbleCopmpany")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.marbleCompany || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-5 col-form-label">
                                                    {props.t("marbleColourNumber")}
                                                </Label>
                                                <Col sm="7">
                                                    <Label className="col-form-label font-weight-bold">
                                                        {props.data.marbleColorNo || props.t("notEntered")}
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Form>
                        </Card>



                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default withRouter(translate(ContractBasicInformation));

