import environment from "../environment";
import { handleError } from "./error.service";
import store from "../store";
import { setGlobalLoading } from "../store/slices/loadingSlice";

const _requestHeaders = (
  token = false,
  requestHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
  }
) => {
  const lang = localStorage.getItem(environment.LANGUAGE_KEY);
  const headers = {};
  headers["Accept-Language"] = lang;
  if (token) {
    headers["Authorization"] = `Bearer ${localStorage.getItem(
      environment.TOKEN_KEY
    )}`;
  }
  if (Object.keys(requestHeaders)) {
    for (const [key, value] of Object.entries(requestHeaders)) {
      if (value !== 0) {
        headers[key] = value;
      }
    }
  }

  return headers;
};

const _requestBody = (body) => {
  return JSON.stringify({ ...body });
};

const _responseBody = async (response) => {
  let body = {};
  try {
    body = await response.json();
  } catch (error) {
    console.error(error);
    body = "";
  }
  return body;
};

const _createResponse = async (response, method = "") => {
  const body = await _responseBody(response);
  return {
    ok: response.ok,
    status: response.status,
    body,
    method,
  };
};

export const Get = async (path, token = false) => {
  const response = await fetch(`${environment.API_URL}/${path}`, {
    method: "GET",
    headers: _requestHeaders(token),
  });
  const createdResponse = await _createResponse(response, "GET");
  if (!createdResponse.ok) {
    store.dispatch(setGlobalLoading({ status: false }));
    handleError(createdResponse);
  }
  return createdResponse;
};
export const GetNoHandleError = async (path, token = false) => {
  const response = await fetch(`${environment.API_URL}/${path}`, {
    method: "GET",
    headers: _requestHeaders(token),
  });
  console.log(response)
  const createdResponse = await _createResponse(response, "GET");
  return createdResponse;
};

export const Post = async (path, body, token = false) => {
  const response = await fetch(`${environment.API_URL}/${path}`, {
    method: "POST",
    body: _requestBody(body),
    headers: _requestHeaders(token),
  });

  const createdResponse = await _createResponse(response, "POST");
  if (!createdResponse.ok) {
    store.dispatch(setGlobalLoading({ status: false }));
    handleError(createdResponse);
  }
  return createdResponse;
};

export const PostForm = async (path, body, token = false) => {
  const response = await fetch(`${environment.API_URL}/${path}`, {
    method: "POST",
    body,
    headers: _requestHeaders(token, {
      "Content-Type": 0,
      Accept: "*/*",
    }),
  });
  const createdResponse = await _createResponse(response, "POST");
  if (!createdResponse.ok) {
    store.dispatch(setGlobalLoading({ status: false }));
    handleError(createdResponse);
  }
  return createdResponse;
};
