import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";

import { getPublishedLanguages } from "../../services/language.service";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { addProduct } from "../../services/products.service";
import { useDispatch } from "react-redux";
import Loader from "../partials/Loader";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import environment from "../../environment";
const AddProduct = ({ t, history }) => {
  const translations = [];
  const [languages, setLanguages] = useState([]);

  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState(["Accessories", "Device"]);

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(false);

  const handlePriceChange = (value) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setPrice(value);
    }
  };

  const addNewProduct = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    languages.forEach((language, index) => {
      translations.push({
        name: event.target[index].value,
        Manufecturer: event.target[index + languages.length].value,
        languageId: language.id,
      });
    });

    const apiData = {
      price: price,
      // productType: choosedProduct[0],
      productType: event.target.productType.value,
      barcode: event.target.barcode.value,
      translations: translations,
      // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      id: null,
    };

    // console.log(apiData)
    (async () => {
      const { ok } = await addProduct(apiData);
      if (ok) {
        setTimeout(() => {
          toast.success(t("productAddedSuccessfully"));
        }, 200);
        history.push(`${process.env.PUBLIC_URL}/products`);
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        const languagesInfo = body.map((language) => {
          return {
            id: language.id,
            code: language.culture,
          };
        });
        setLanguages(languagesInfo);
      }

      setLoading(false);
    })();
  }, []);

  const handleCancel = () => {
    // console.log(productId);
    history.push(`${process.env.PUBLIC_URL}/products`);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent={t("products")}
        title={t("addNewProduct")}
        backTo="true"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {loading ? (
                <Loader />
              ) : (
                <Form className="form theme-form" onSubmit={addNewProduct}>
                  <CardBody>
                    {languages.map((language) => (
                      <FormGroup className="row" key={language.code}>
                        <Label
                          htmlFor={`name_${language.code}`}
                          className="col-sm-3 col-form-label"
                        >
                          {`${t("name")} (${language.code.toUpperCase()})`}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            id={`name_${language.code}`}
                            name={language.code}
                            required
                          ></Input>
                        </Col>
                      </FormGroup>
                    ))}
                    {languages.map((language) => (
                      <FormGroup className="row" key={language.code}>
                        <Label
                          htmlFor={`manufacurer_${language.code}`}
                          className="col-sm-3 col-form-label"
                        >
                          {`${t(
                            "manufacurer"
                          )} (${language.code.toUpperCase()})`}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            id={`manufacurer_${language.code}`}
                            name={language.code}
                            required
                          ></Input>
                        </Col>
                      </FormGroup>
                    ))}

                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("productType")}
                      </Label>
                      <Col
                        sm="9"
                        className="d-flex justify-content-around align-items-center"
                      >
                        {productType.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center">
                            <input
                              type="radio"
                              name="productType"
                              value={item}
                              key={index}
                              defaultChecked={item === "Accessories"}
                              style={{ marginInlineEnd: 4 }}
                            />
                            {t(item)}
                          </div>
                        ))}
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("productNumber")}
                      </Label>
                      <Col sm="9">
                        <Input
                          className="form-control"
                          type="text"
                          name="barcode"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-sm-3 col-form-label">
                        {t("basicPrice")}
                      </Label>
                      <Col sm="9">
                        <Input
                          className="form-control"
                          type="text"
                          name="price"
                          required
                          value={price}
                          onChange={(event) => {
                            handlePriceChange(event.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </CardBody>
                  <CardFooter className=" submit-footer">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1"
                    // disabled={!isSaveButtonDisabled()}
                    >
                      {t("save")}
                    </Button>
                    <Button color="light" onClick={handleCancel} type="button">
                      {t("cancel")}
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(AddProduct));
