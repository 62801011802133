import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpooint = environment.LOCALE_RESOURCE_ENDPOINT;


export const getResourceById = async (resourceId) => {
  const response = await Get(`${_endpooint}/GetById?id=${resourceId}`, true);
  return response;
};

export const getPaginatedResources = async (requestBody) => {
  const response = await Post(`${_endpooint}/Search`, requestBody, true);
  return response;
};

export const updateResource = async (requestBody) => {
  const response = await Post(`${_endpooint}/Update`, requestBody, true);
  return response;
};
