import { createSlice } from "@reduxjs/toolkit";

const maintenanceRequestSlice = createSlice({
    name: "maintenanceRequest",
    initialState: {
        id: ""
    },
    reducers: {
        setRequestId: (state, { payload }) => {
            state.id = payload.id
        },
    },
});

export const { setRequestId } = maintenanceRequestSlice.actions;
export default maintenanceRequestSlice.reducer;