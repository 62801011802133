import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.CITY_ENDPOINT;


export const getAllCities = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};

export const getCityById = async (cityId) => {
  const response = await Get(`${_endpoint}/Get?id=${cityId}`, true);
  return response;
};

export const createCity = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const updateCity = async (requestBody) => {
  const response = await Post(`${_endpoint}/Update`, requestBody, true);
  return response;
};

export const deleteCity = async (cityId) => {
  const requestBody = {
    id: cityId,
  };
  const response = await Post(`${_endpoint}/Delete`, requestBody, true);
  return response;
};
