// sign in page Ahmad

import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";

import { translate } from "react-switch-lang";
import jwt_decode from "jwt-decode";
import Loader from "react-spinners/ClipLoader";

import "./logoDesign.css";

import { login } from "../services/auth.service";
import environment from "../environment";
/* import { toast } from "react-toastify"; */

const Logins = ({ t }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const data = {
    grantType: "password",
    userName: userName,
    password: password,
    refreshToken: "string",
    fcmToken: "string",
    deviceId: "string",
  };

  const loginWithJwt = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { body, ok } = await login(data);
    if (ok) {
      setTimeout(() => { }, 1000);

      localStorage.setItem(environment.TOKEN_KEY, body.accessToken);
      localStorage.setItem(
        environment.CLAIMS_KEY,
        JSON.stringify(jwt_decode(body.accessToken))
      );
      /* 
      const decodedToken = jwt_decode(body.accessToken);
      let isClient = false;

      decodedToken.claims.forEach((claim) => {
        if (claim === "Client") {
          isClient = true;
        }
      });
      if (isClient) {
        toast.error(t("clientWrongLogin"));
        localStorage.clear();
        setTimeout(() => {
          document.location.href = `${process.env.PUBLIC_URL}/register`;
        }, 2000);
      } else {  }*/

      document.location.href = `${process.env.PUBLIC_URL}/orders`;
    }

    setLoading(false);
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <span className="logo">
                  <img
                    className="img-fluid"
                    src={require("../assets/images/logo/secondLogo.jpeg")}
                    alt=""
                  />
                </span>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form" onSubmit={loginWithJwt}>
                  <h4>{t("signinText")}</h4>
                  <p>{t("signinInstruction")}</p>
                  <FormGroup>
                    <Label className="col-form-label">{t("userName")}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      onChange={(e) => setUserName(e.target.value)}
                      defaultValue={userName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{t("password")}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      defaultValue={password}
                      required=""
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0 mt-5">
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          style={{ minHeight: "25px" }}
                          className="w-100 d-flex align-items-center justify-content-center position-relative"
                        >
                          <Loader
                            color="white"
                            size={20}
                            speedMultiplier={0.6}
                          />
                        </div>
                      ) : (
                        t("signinButton")
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default translate(Logins);
