import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { editLanguage, getLanguageById } from "../../services/language.service";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const EditLanguage = (props) => {
  const [editData, setEditData] = useState({});
  let bodyFormData;
  const { languageId } = useParams();

  // Store
  const dispatch = useDispatch();

  const handleCancel = () => {
    props.history.push(`${process.env.PUBLIC_URL}/languages`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(setGlobalLoading({ status: true }));

    bodyFormData = new FormData();
    bodyFormData.append("id", editData.id);
    bodyFormData.append("name", event.target.name.value);
    bodyFormData.append("culture", event.target.culture.value);
    if (event.target.icon.files[0] !== undefined)
      bodyFormData.set("logo", event.target.icon.files[0]);
    bodyFormData.set("rTl", event.target.rtl.checked);
    bodyFormData.set("published", event.target.published.checked);
    bodyFormData.set("default", event.target.default.checked);

    (async () => {
      const { ok } = await editLanguage(bodyFormData);
      if (ok) {
        toast.success("langugaeSuccessflyEdited");
        dispatch(setGlobalLoading({ status: false }));
        props.history.push(`${process.env.PUBLIC_URL}/languages/`);
      }
    })();
  };

  useEffect(() => {
    if (languageId !== "" && languageId) {
      (async () => {
        const { body, ok } = await getLanguageById(languageId);
        if (ok) {
          setEditData(body);
        }
      })();
    }
  }, [languageId]);

  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("languages")}
        title={props.t("editLanguage")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={handleSubmit}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("name")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="name"
                            required="true"
                            defaultValue={editData.name}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("culture")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="culture"
                            defaultValue={editData.culture}
                            required="true"
                            maxLength="2"
                          />
                        </Col>
                      </FormGroup>
                      {/* uplad file */}
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("icon")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="file"
                            name="icon"
                          />
                        </Col>
                      </FormGroup>
                      {/* check box area */}

                      <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                        {editData.rtl ? (
                          <div className="checkbox checkbox-dark">
                            <Input
                              id="inline-1"
                              type="checkbox"
                              name="rtl"
                              defaultChecked
                            />
                            <Label for="inline-1">{props.t("rtl")}</Label>
                          </div>
                        ) : (
                          <div className="checkbox checkbox-dark">
                            <Input id="inline-1" type="checkbox" name="rtl" />
                            <Label for="inline-1">{props.t("rtl")}</Label>
                          </div>
                        )}
                        {editData.published ? (
                          <div className="checkbox checkbox-dark">
                            <Input
                              id="inline-2"
                              type="checkbox"
                              name="published"
                              defaultChecked
                            />
                            <Label for="inline-2">{props.t("published")}</Label>
                          </div>
                        ) : (
                          <div className="checkbox checkbox-dark">
                            <Input
                              id="inline-2"
                              type="checkbox"
                              name="published"
                            />
                            <Label for="inline-2">{props.t("published")}</Label>
                          </div>
                        )}

                        {editData.default ? (
                          <div className="checkbox checkbox-dark">
                            <Input
                              id="inline-3"
                              type="checkbox"
                              name="default"
                              defaultChecked
                            />
                            <Label for="inline-3">{props.t("default")}</Label>
                          </div>
                        ) : (
                          <div className="checkbox checkbox-dark">
                            <Input
                              id="inline-3"
                              type="checkbox"
                              name="default"
                            />
                            <Label for="inline-3">{props.t("default")}</Label>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button color="primary" className="mr-1" type="submit">
                    {props.t("save")}
                  </Button>
                  <Button color="light" type="reset" onClick={handleCancel}>
                    {props.t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(EditLanguage));
