import React from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { rejectOrder } from "../../../services/order.service";

import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const RejectOrderModal = ({
  isOpen,
  toggleModal = () => {},
  t,
  toggleRefreshData = () => {},
  orderId,
}) => {
  // Store
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));

    const requestBody = {
      orderId: orderId,
      rejectReason: event.target.rejectionReason.value,
    };
    (async () => {
      const { ok } = await rejectOrder(requestBody);
      if (ok) {
        setTimeout(() => {
          toast.success(t("orderRejected"));
        }, 200);

        toggleRefreshData();
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("rejectOrder")}</ModalHeader>
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label className="col-sm-12 col-form-label">
                {t("rejectionReasonMsg")}
              </Label>
              <textarea
                className="col-sm-12 col-form-label"
                name="rejectionReason"
                placeholder={t("rejectionReasonPlaceholder")}
                required
              ></textarea>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="danger" type="submit">
              {t("confirm")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(RejectOrderModal));
