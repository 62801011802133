/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import {
    CardBody,
    Col,
    Container,
    FormGroup,
    Row,
} from 'reactstrap';
import NoData from '../partials/NoData';
import { translate } from "react-switch-lang";
import './imageView.css'
import FsLightbox from 'fslightbox-react';
// import 'react-image-lightbox/style.css';
const ImagesView = ({
    images,
    t
}) => {

    const [toggler, setToggler] = useState(false);
    const [itemIndex, setItemIndex] = useState(2);

    const [imageFiles, setImageFiles] = useState([

    ])
    const [pdfFiles, setPdfFiles] = useState([])

    let imgs = []
    const filterFiles = () => {
        images.map((item) => {
            if (item.substring(item.length - 3) === "pdf")
                setPdfFiles([...pdfFiles, item])
            else {
                imgs.push(item)
                setImageFiles(imgs)
            }
        })

    }

    useEffect(() => {
        filterFiles()
    }, [])

    return (
        <div>
            {(images?.length !== 0)
                ?
                (
                    <CardBody>
                        <Row>
                            <Col className='d-flex'>
                                <FormGroup>
                                    <div className="files">
                                        {imageFiles?.map((item, index) =>
                                            <div className='viewing-file-box'>
                                                <div>
                                                    <img
                                                        className="icon-image"

                                                        onClick={() => {
                                                            setItemIndex(index);
                                                            setToggler(!toggler)
                                                        }}
                                                        src={item} />
                                                </div>
                                            </div>
                                        )}
                                        {pdfFiles?.map((item, index) =>
                                            <div className='viewing-file-box'>
                                                <div className='pdf-bg'>
                                                    <a href={item} target="_blank">
                                                        <i className="fa fa-file-pdf-o"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                )
                :
                (
                    <Container fluid={true}>
                        <NoData message={t("noImages")} />
                    </Container>
                )

            }
            <FsLightbox
                toggler={toggler}
                sources={imageFiles}
                type="image"
                sourceIndex={itemIndex}
            />

        </div>
    )
}
export default translate(ImagesView);