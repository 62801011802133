import React, { Fragment, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";

import { translate } from "react-switch-lang";
import { useParams, withRouter } from "react-router-dom";
import { KPTable } from '../partials/KPTable'
import environment from "../../environment";
import Loader from '../partials/Loader';
import TimePeriodLocalization from "../partials/TimePeriodLocalization/TimePeriodLocalization";
const MaintenanceRequests = (props) => {
    const orderId = useParams();
    const requestOptions = {
        orderId: orderId.orderId,
        orderCode: "",
        contractNo: "",
        clientPhoneNumber: "",
        requestStatus: [
            0
        ],
        fromDate: "",
        toDate: "",
        orderBy: "",
        searchText: ""
    };
    // UI/Ux
    const [refreshTable, setRefreshTable] = useState(false);

    // handle status design
    const handleStatus = (row) => {
        if (row.requestStatus === "Rejected") {
            return (
                <p
                    className="text-danger font-weight-bold"
                    style={{ fontSize: "12px", textAlign: "center" }}
                >
                    {row.requestStatusLocalized || props.t("notEntered")}
                </p>
            );
        } else if (
            row.requestStatus === "UpdatedRequest" ||
            row.requestStatus === "InstallingProcessing"
        ) {
            return (
                <p
                    className=" text-warning font-weight-bold"
                    style={{ fontSize: "12px", textAlign: "center" }}
                >
                    {row.requestStatusLocalized || props.t("notEntered")}
                </p>
            );
        } else {
            return (
                <p
                    className="text-success font-weight-bold"
                    style={{ fontSize: "12px", textAlign: "center" }}
                >
                    {row.requestStatusLocalized || props.t("notEntered")}
                </p>
            );
        }
    };
    const tableColumns = [
        {
            name: props.t("status"),
            selector: "requestStatusLocalized",
            cell: (row) => handleStatus(row),
            center: true,
            wrap: true,
            minWidth: "100px",
        },
        {
            name: props.t("contractDate"),
            selector: (row) =>
                row.createdOn.substring(0, row.createdOn.length - 3) + " " +
                TimePeriodLocalization(row.createdOn.substring(row.createdOn.length - 2, row.createdOn.length)),
            center: true,
            minWidth: "100px",
        },
        {
            name: props.t("requestDetails"),
            selector: "requestIssueDetails",
            center: true,
            minWidth: "100px",
        },
        {
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: "120px",
            cell: (row) =>
                // handleClaims("Maintenance.Approve") ? (
                <Button
                    color="primary"
                    size="sm"
                    className="mr-1"
                    onClick={() => {
                        props.history.push(`${process.env.PUBLIC_URL}/orders/contract/details/${row.id}`);
                    }}
                >
                    {props.t("details")}
                </Button>
        },
    ];




    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        {(props.getMaintenanceRequests === true) ?
                            <Card>
                                <CardBody>
                                    <KPTable
                                        endpoint={`${environment.MAINTENANCE_ENDPOINT}/Search`}
                                        columns={tableColumns}
                                        refreshData={refreshTable}
                                        pageSize={30}
                                        requestOptions={requestOptions}
                                    />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <Loader />
                            </Card>
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default withRouter(translate(MaintenanceRequests));