import React, { useState } from "react";
import { translate } from "react-switch-lang";
import Breadcrumb from "../../layout/breadcrumb";
import Loader from "../partials/Loader";
import { useParams, withRouter } from "react-router-dom";
import { useEffect } from "react";
import {
  getMessageTemplateById,
  updateMessageTemplate,
} from "../../services/message-template.service";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DisplayTokens } from "../partials/displayTokens";
import { getPublishedLanguages } from "../../services/language.service";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import { toast } from "react-toastify";

const EditEmailTemplate = ({ t, history }) => {
  const { templateId } = useParams();

  const [template, setTemplate] = useState({});
  const [tags, setTags] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [bodies, setBodies] = useState([]);

  // UI/UX
  const [loading, setLoading] = useState(false);
  const [texts, setTexts] = useState([]);

  //Store
  const dispatch = useDispatch();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ size: [] }, { header: [] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }, { align: [] }],
      ["link", "image", "video"],

      ["clean"],
    ],
  };

  const formats = [
    "align",
    "background",
    "blockquote",
    "bold",
    "bullet",
    "code",
    "code-block",
    "color",
    "direction",
    "font",
    "formula",
    "header",
    "image",
    "indent",
    "italic",
    "link",
    "list",
    "script",
    "strike",
    "underline",
    "video",
  ];

  let bodyForm;
  if (languages.length > 0) {
    if (Object.keys(template).length > 0) {
      languages.forEach((language, index) => {
        const value = template.translations.filter(
          (translation) => translation.languageId === language.id
        )[0];

        language.value = value ? value.body : "";
        language.subject = value ? value.subject : "";
      });
      bodyForm = languages.map((language, index) => {
        return (
          <div key={`${language.culture}`} className="mb-4">
            <FormGroup className="row d-flex align-items-center ">
              <Label
                htmlFor={`subject_${language.culture}`}
                className="col-3 mb-0 label-required"
              >{`${t(
                "emailSubject"
              )} (${language.culture.toUpperCase()})`}</Label>
              <Input
                type="text"
                className="col-9 mb-0"
                dir={language.culture === "ar" ? "rtl" : "ltr"}
                name={`subject_${language.culture}`}
                defaultValue={language.subject}
                required
                style={{ fontSize: "0.8rem" }}
              />
            </FormGroup>
            <FormGroup className="row d-flex align-items-center ">
              <Label
                htmlFor={`body_${language.culture}`}
                className="col-3 mb-0 label-required"
              >{`${t("emailBody")} (${language.culture.toUpperCase()})`}</Label>
              <div
                className={`col-9 mb-0 p-0 ${
                  language.culture === "ar" ? "" : "ltr"
                }`}
              >
                <ReactQuill
                  defaultValue={language.value}
                  modules={modules}
                  formats={formats}
                  onChange={(content, delta, source, editor) => {
                    setBodies((prev) => {
                      const newBodies = prev;
                      newBodies[index] = content;
                      return [...newBodies];
                    });
                    setTexts((prev) => {
                      prev[index] = editor.getText();
                      return [...prev];
                    });
                  }}
                />
              </div>
            </FormGroup>
          </div>
        );
      });
    }
  } else {
    bodyForm = <></>;
  }

  const editEmailTemplate = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));

    const translations = languages.map((language, index) => ({
      body: bodies[index],
      subject: event.target[`subject_${language.culture}`].value,
      languageId: language.id,
    }));

    const requestBody = {
      id: templateId,
      translations,
      isActive: true,
    };
    

    (async () => {
      const { ok } = await updateMessageTemplate(requestBody);
      if (ok) {
        toast.success(t("emailTemplateEditedSuccess"));
      }
      dispatch(setGlobalLoading({ status: false }));
      history.goBack();
    })();
  };

  const isSaveButtonDisabled = () => {
    for (let i = 0; i < texts.length; i++) {
      if (texts[i].replace(/\s/g, "") === "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      let bodiesArray = [];
      (async () => {
        (async () => {
          const { body, ok } = await getPublishedLanguages();
          if (ok) {
            setLanguages(body);

            body.forEach(() => {
              bodiesArray.push("");
            });
          }
        })();

        const { body, ok } = await getMessageTemplateById(templateId);
        if (ok) {
          setTemplate(body);
          setTags(
            body.allowedTokens.replace("\r\n", "").length > 0
              ? body.allowedTokens.split(",")
              : []
          );
          body.translations.forEach((translation, index) => {
            bodiesArray[index] = translation.body;
          });
        }
        setBodies(bodiesArray);
        setTexts(bodiesArray);
        setLoading(false);
      })();
    }
  }, [templateId]);

  return (
    <>
      <Breadcrumb
        parent={t("emailTemplates")}
        title={t("editEmailTemplate")}
        backTo="true"
      />
      <Row>
        <Col sm="12">
          <Card>
            {loading ? (
              <Loader />
            ) : (
              <Form onSubmit={editEmailTemplate}>
                <CardBody>
                  <FormGroup className="row d-flex align-items-center">
                    <Label className="col-3 mb-0">{t("name")}</Label>
                    <p className="col-9 mb-0">{template.localizedName}</p>
                  </FormGroup>
                  {bodyForm}

                  {tags.length > 0 && (
                    <FormGroup className="row d-flex align-items-center">
                      <Label className="col-3 mb-0">{t("tagsToUse")}</Label>
                      <DisplayTokens
                        tags={tags}
                        classNames="col-9 mb-0 px-0 tags-container"
                      />
                    </FormGroup>
                  )}
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isSaveButtonDisabled()}
                  >
                    {t("save")}
                  </Button>
                  <Button
                    color="light"
                    onClick={() => {
                      history.goBack();
                    }}
                    type="button"
                  >
                    {t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(translate(EditEmailTemplate));
