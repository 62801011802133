import React, { Fragment, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Button,
    Container,
    Card,
    Form,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';
import ImagesView from '../orderTabs/ImagesView';
import NoData from '../partials/NoData';
import TimePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization';
import CloseMaintenanceTicket from './modals/CloseMaintenanceTicket';

const Tickets = (props) => {
    const data = props.data;
    // Modal
    const [isCloseInstallation, setIsCloseInstallation] = useState(false);
    const [ticketId, setTicketId] = useState("");
    const toggleCloseInstallation = () => setIsCloseInstallation(!isCloseInstallation);
    return (
        <Fragment>
            <CloseMaintenanceTicket
                isOpen={isCloseInstallation}
                toggleModal={toggleCloseInstallation}
                ticketId={ticketId}
                toggleRefreshData={props.toggleRefreshData}
            >
            </CloseMaintenanceTicket>
            {
                (data.tickets?.length > 0) ?
                    (

                        (data.tickets.map((ticket, index) =>
                            <Container fluid={true}>
                                <Row >
                                    <Col sm="12" >
                                        <Card>
                                            <Form className="form theme-form">
                                                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                                    <h4>{props.t("ticket")} # {index + 1}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="6">
                                                            <FormGroup className="row">
                                                                <Label className="col-sm-4 col-form-label">
                                                                    {props.t("orderTicketStatus")}
                                                                </Label>
                                                                <Col sm="8">
                                                                    {(ticket.ticketStatus === "Pending") ?
                                                                        (
                                                                            <h5><span class="badge badge-warning">
                                                                                <p style={{ fontSize: "10px" }}>
                                                                                    {ticket.ticketStatusLocalized || props.t("notEntered")}
                                                                                </p>
                                                                            </span>
                                                                            </h5>

                                                                        ) : (
                                                                            <h5><span class="badge badge-success">
                                                                                <p style={{ fontSize: "10px" }}>
                                                                                    {ticket.ticketStatusLocalized || props.t("notEntered")}
                                                                                </p>
                                                                            </span>
                                                                            </h5>
                                                                        )}
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                                <Label className="col-sm-4 col-form-label">
                                                                    {props.t("installingIssueDetails")}
                                                                </Label>
                                                                <Col sm="8">
                                                                    <Label className="col-form-label font-weight-bold">
                                                                        {ticket.maintenanceIssueDetails || props.t("notEntered")}
                                                                    </Label>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="6">
                                                            <FormGroup className="row">
                                                                <Label className="col-sm-4 col-form-label">
                                                                    {props.t("issueCreatedBy")}
                                                                </Label>
                                                                <Col sm="8">
                                                                    <Label className="col-form-label font-weight-bold">
                                                                        {ticket.createdBy || props.t("notEntered")}
                                                                    </Label>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="row">
                                                                <Label className="col-sm-4 col-form-label">
                                                                    {props.t("issueCreatedOn")}
                                                                </Label>
                                                                <Col sm="8">
                                                                    <Label className="col-form-label font-weight-bold">
                                                                        {(ticket.createdOn !== null) ?
                                                                            (ticket.createdOn.substring(0, ticket.createdOn.length - 3) + " " +
                                                                                TimePeriodLocalization(ticket.createdOn.substring(ticket.createdOn.length - 2, ticket.createdOn.length))
                                                                            )
                                                                            :
                                                                            (props.t("notEntered")
                                                                            )}
                                                                    </Label>

                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                {(ticket.ticketStatus === "Pending") ?
                                                    (
                                                        <CardFooter className="submit-footer">

                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    toggleCloseInstallation();
                                                                    setTicketId(ticket.id);
                                                                }}
                                                            >
                                                                {props.t("closeTicket")}
                                                            </Button>


                                                        </CardFooter>
                                                    ) : (
                                                        <div></div>
                                                    )}

                                            </Form>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        )))
                    :
                    (
                        <Card>
                            <NoData message={props.t("noData")} />
                        </Card>
                    )
            }
        </Fragment>
    )
}

export default withRouter(translate(Tickets));
