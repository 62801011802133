import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { translate } from "react-switch-lang";
import { Button, Input, Table } from "reactstrap";
import environment from "../../../environment";

const DevicesTable = ({
  t,
  options,
  devicesValue = [],
  setDevicesValue = () => {},
  setIsValid = () => {},
}) => {
  const [tableTotal, setTableTotal] = useState(0);
  const [tableOptions, setTableOptions] = useState(options);
  const [zeroError, setZeroError] = useState(false);
  const numberFormat = Intl.NumberFormat();
  const emptyOption = {
    localizedName: "",
    localizedManufecturer: "",
    sellingPrice: 0.0,
    price: 0.0,
    barcode: "",
  };

  const handleSelectChange = (option, index) => {
    const newOption = option.length
      ? {
          ...option[0],
          sellingPrice: parseFloat(option[0].price),
          barcode: `${option[0].barcode}`,
        }
      : { ...emptyOption };
    
    setDevicesValue((prev) => {
      prev[index] = newOption;
      return [...prev];
    });
  };
  // END handleSelectChange

  const handleSellingPriceChange = (value, index) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setDevicesValue((prev) => {
        prev[index].sellingPrice = value === "" ? 0 : value;
        return [...prev];
      });
      handleZeroValue(value === "" ? 0 : value);
    }
  };

  const handleZeroValue = (value) => {
    if (+value === 0) {
      setZeroError(true);
      setIsValid(false);
    }
  };

  const onAdd = () => {
    setDevicesValue((prev) => [...prev, emptyOption]);
  };

  const onDelete = (row, index) => {
    setTableOptions((prev) => {
      const newTableOptions = prev.map((tableOption) => {
        if (tableOption.id === row.id) {
          return { ...tableOption, disabled: false };
        }
        return tableOption;
      });
      return [...newTableOptions];
    });
    setDevicesValue((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const tableRows = devicesValue.map((device, index) => (
    <tr key={index}>
      <td className="col-2">
        <Typeahead
          className="px-0"
          placeholder={t("selectPlaceholder")}
          options={tableOptions}
          labelKey={"barcode"}
          id={`devices-select-menu-${index}`}
          onChange={(option) => handleSelectChange(option, index)}
          defaultSelected={[device]}
        ></Typeahead>
      </td>

      <td className="col-3">
        <Input type="text" value={device.localizedName} disabled></Input>
      </td>

      <td className="col-3">
        <Input
          type="text"
          value={device?.localizedManufecturer}
          disabled
        ></Input>
      </td>

      <td className="col-2">
        <Input
          type="number"
          min={0}
          step={0.01}
          value={device?.price}
          disabled
        ></Input>
      </td>

      <td className="col-2">
        <div className="d-flex w-100">
          <Input
            className="w-75"
            type="text"
            value={device?.sellingPrice}
            onChange={(event) =>
              handleSellingPriceChange(event.target.value, index)
            }
          ></Input>

          <Button
            color="danger"
            className="w-25 d-flex justify-content-center align-items-center"
            style={{ marginInlineStart: 4 }}
            onClick={() => onDelete(device, index)}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      </td>
    </tr>
  ));

  useEffect(() => {
    let total = 0;
    if (devicesValue.length > 0) {
      devicesValue.forEach((device) => {
        total += +device.sellingPrice;
      });
    }
    setTableTotal(total);
    let valid = false;
    let error = false;
    if (devicesValue.length > 0) {
      valid = true;
      devicesValue.forEach((accessory) => {
        if (!accessory.id) {
          valid = false;
          return;
        }
        if (+accessory.price === 0 || +accessory.quantity === 0) {
          error = true;
          return;
        }
      });
    }
    setZeroError(error);
    setIsValid(valid && !error);
  }, [devicesValue, setIsValid]);

  useEffect(() => {
    setTableOptions(options);
  }, [options, setTableOptions]);

  return (
    <Table>
      <thead>
        <tr>
          <td className="col-2 label-required">{t("modelNumber")}</td>
          <td className="col-3 label-required">{t("type")}</td>
          <td className="col-3 label-required">{t("manufacturer")}</td>
          <td className="col-2 label-required">{t("devicePrice")}</td>
          <td className="col-2 label-required">{t("sellingPrice")}</td>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      <tfoot>
        <tr>
          <td>
            <Button
              color="primary"
              onClick={onAdd}
              disabled={tableOptions.length < 1}
            >
              +
            </Button>
          </td>
          <td colSpan="2">
            {devicesValue.length > 0 && zeroError ? (
              <span className="text-danger">{t("devicesZeroValueError")}</span>
            ) : null}
          </td>
          <td></td>
          <td>
            {devicesValue.length > 0 && (
              <span>
                {`${t("total")}: ${numberFormat.format(tableTotal)} ${t(
                  "currency"
                )}`}
              </span>
            )}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default translate(DevicesTable);
