import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { deleteNeighborhood } from "../../services/neighborhood.service";
import Breadcrumb from "../../layout/breadcrumb";
import { getPublishedLanguages } from "../../services/language.service";
import { getAllCities } from "../../services/city.service";
import CreateNeighborhood from "./CreateNeighborhood";

import EditNeighborhood from "./EditNeighborhood";
import { useDispatch } from "react-redux";

import environment from "../../environment";
import ConfirmationModal from "../partials/ConfirmationModal";
import { setNeighborhoodIdToEdit } from "../../store/slices/neighborhoodSlice";
import { KPTable } from "../partials/KPTable";

const ViewNeighborhoods = ({ t }) => {
  // Data from Backend
  const [languages, setLanguages] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoodId, setNeighborhoodId] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [requestOptions, setRequestOptions] = useState(
    {
      name: "",
      phoneNumber: "",
      pageIndex: 0,
      pageSize: 0,
      orderBy: "string",
      searchText: "string"
    }
  );
  //END

  // Modals
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  //END

  //UI/UX
  const [submitting, setSubmitting] = useState(false);
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  //

  // Store
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: t("neighborhood"),
      selector: "localizedName",

      center: true,
    },
    {
      name: t("city"),
      selector: "city",
      cell: (row) => row.city?.localizedName,

      center: true,
    },
    {
      cell: (row) => (
        <Button
          color="light"
          size="sm"
          onClick={() => {
            dispatch(setNeighborhoodIdToEdit({ id: row.id }));
            toggleEditModal();
          }}
        >
          {t("edit")}
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            setIsConfirm(true);
            setNeighborhoodId(row.id);
          }}
          className="px-3"
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      button: true,
    },
  ];

  // Modals Toggles
  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const toggleRefreshTableData = () => {
    setRefreshTableData(!refreshTableData);
  };
  const toggleConfirmationModal = () => setIsConfirm(!isConfirm);
  // END
  const getFilteredData = (value) => {
    if (value === "reset") {
      setRequestOptions(
        {
          name: "",
          phoneNumber: "",
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    } else {
      setRequestOptions(
        {
          neighborhood: neighborhood,
          city: city,
          pageIndex: 0,
          pageSize: 0,
          orderBy: "string",
          searchText: "string"
        }
      );
    }
    toggleRefreshTableData();
  };
  const resetData = () => {
    setNeighborhood("");
    setCity("");
    setIsFilter(false);
    getFilteredData("reset");
  };
  useEffect(() => {
    (async () => {
      const { body, ok } = await getPublishedLanguages();
      if (ok) {
        const languagesInfo = body.map((language) => {
          return {
            id: language.id,
            code: language.culture,
          };
        });
        setLanguages(languagesInfo);
      }
    })();
    (async () => {
      const { body, ok } = await getAllCities();
      if (ok) {
        const citiesInfo = body.map((city) => ({
          id: city.id,
          name: city.localizedName,
        }));
        setCities(citiesInfo);
      }
    })();
  }, []);
  return (
    <>
      <CreateNeighborhood
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModal}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshTableData}
        languages={languages}
        cities={cities}
      ></CreateNeighborhood>

      <EditNeighborhood
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        toggleRefreshTable={toggleRefreshTableData}
        languages={languages}
        cities={cities}
      ></EditNeighborhood>

      <ConfirmationModal
        isOpen={isConfirm}
        toggleModal={toggleConfirmationModal}
        id={neighborhoodId}
        toggleRefreshData={toggleRefreshTableData}
        apiToCall={deleteNeighborhood}
        message={"deleteMsg"}
      ></ConfirmationModal>

      <Breadcrumb parent={t("neighborhoods")} title={t("viewNeighborhoods")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <h2 className="mb-0 d-flex align-items-center justify-content-between">
                    <span
                      color="pl-0"
                      data-toggle="collapse"
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      <h5>{t("search")}</h5>
                    </span>
                    <i
                      className="middle fa fa-angle-down float-right p-2"
                      onClick={() => setIsFilter(!isFilter)}
                      role="button"
                    ></i>
                  </h2>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("neighborhood")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="neighborhood"
                              value={neighborhood}
                              onChange={(event) => {
                                setNeighborhood(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>

                      <Col sm="6">

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("city")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="text"
                              name="city"
                              value={city}
                              onChange={(event) => {
                                setCity(
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  <Button
                    outline
                    color="primary"
                    onClick={toggleCreateModal}
                    size="sm"
                    disabled={
                      submitting ||
                      languages.length === 0 ||
                      cities.length === 0
                    }
                  >
                    {t("addNeighborhood")}
                  </Button>
                </CardTitle>

                <KPTable
                  endpoint={`${environment.NEIGHBORHOOD_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTableData}
                  requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(ViewNeighborhoods);
