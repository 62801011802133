import React, { useEffect, useState } from "react";
import { Carousel, CarouselItem, Col, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { getMaintenanceRequestById } from "../../../services/maintenance-public.service";
import Loader from "../../../KPComponents/partials/Loader";
import { t } from "react-switch-lang";
import { useSelector } from "react-redux";
import { CarouselControl, CarouselIndicators } from "reactstrap";
import Lightbox from "react-image-lightbox";

const MaintenanceRequestDetails = () => {
  const [maintenanceRequest, setMaintenanceRequest] = useState({});
  const [showVisitInfo, setShowVisitInfo] = useState(false);
  const [images, setImages] = useState([]);

  // Carousel
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  // Routing
  const { id } = useParams();
  const history = useHistory();

  // Store
  const languageKey = useSelector((state) => state.language.key);

  // UI/UX
  const [loading, setLoading] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Lighbox
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getMaintenanceRequestById(id);
        if (ok) {
          setMaintenanceRequest(body);
          console.log(body);
          setShowVisitInfo(body.visitTime && body.visitDate);
          setImages(body.requestIssueImages);
        }
        setLoading(false);
      })();
    }
  }, [id, languageKey]);

  return (
    <>
      <Row className="justify-content-center position-relative">
        {loading ? (
          <Loader />
        ) : (
          <>
            <i
              className="fa fa-arrow-left back-icon"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/customer/home`)
              }
            ></i>
            <Col sm="12" md="6" lg="4" className="mt-4 pt-4">
              <div className="maintenance-container p-4 py-0">
                <div className="info-group">
                  <p className="title">{t("status")}</p>
                  <p className="value request-status">
                    <span className={`${maintenanceRequest.requestStatus}`}>
                      {maintenanceRequest?.requestStatusLocalized}
                    </span>
                  </p>
                </div>
                <div className="info-group">
                  <p className="title">{t("address")}</p>
                  <p className="value">{maintenanceRequest?.address}</p>
                </div>
                <div className="info-group">
                  <p className="title">{t("contractNumber")}</p>
                  <p className="value">{maintenanceRequest?.contractNumber}</p>
                </div>
                <div className="info-group">
                  <p className="title">{t("contractDate")}</p>
                  <p className="value">{maintenanceRequest?.createdOn?.substring(0, maintenanceRequest?.createdOn?.length - 3) + " "}
                    {(maintenanceRequest?.createdOn) && t(maintenanceRequest?.createdOn?.substring(maintenanceRequest?.createdOn?.length - 2, maintenanceRequest?.createdOn?.length))}</p>
                </div>
                <span className="separator"></span>
                <h4 className="section-header">{t("visitInfo")}</h4>
                {showVisitInfo ? (
                  <p className="visit-info">{`${maintenanceRequest?.visitDate} ${maintenanceRequest?.visitTime}`}</p>
                ) : (
                  <p className="visit-message">{t("noVisitMessage")}</p>
                )}
                <span className="separator"></span>
                {images.length > 0 && (
                  <div className="images-container" dir="ltr">
                    <Carousel
                      className="order-carousel"
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                    >
                      {images.map((image, index) => (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={index}
                        >
                          <img
                            src={image}
                            alt={`kitchen-${index}`}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          />
                        </CarouselItem>
                      ))}
                      {images.length > 1 && (
                        <>
                          <CarouselIndicators
                            items={images}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                          />
                          <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                          />
                          <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                          />
                        </>
                      )}
                    </Carousel>
                  </div>
                )}
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={images[activeIndex]}
                  nextSrc={images[activeIndex]}
                  prevSrc={images[activeIndex]}
                  onCloseRequest={() => setIsOpen(false)}
                />
              )}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default MaintenanceRequestDetails;
