import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Button,
} from "reactstrap";
import Loader from "react-spinners/ClipLoader";
import './style.css';
import InputMask from "react-input-mask";
import { register } from "../../services/accountPublic.service"
import { toast } from "react-toastify";
const CustomerRegister = ({ t, history }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const requestBody = {
            userName: phoneNumber
        };
        (async () => {
            const { ok } = await register(requestBody);
            if (ok) {
                toast.success(t("registrationDone"));
                localStorage.setItem("userName", phoneNumber);
                history.push(`${process.env.PUBLIC_URL}/verification`);
            }
        })();
    }
    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>

                            <div className="login-main login-tab text-center ">
                                <div>
                                    <img
                                        className="img-fluid"
                                        src={require("../../assets/images/logo/secondLogo.jpeg")}
                                        alt=""
                                        style={{ width: "40%" }}
                                    />
                                </div>
                                <Form className="theme-form mt-4" onSubmit={handleSubmit}>
                                    <h4 className='text-center'>{t("registerAccount")}</h4>
                                    <p className='text-center'>{t("enterPhoneNumber")}</p>
                                    <FormGroup>
                                        {/* <Label className="col-form-label">{t("phoneNumber")}</Label> */}
                                        <InputMask
                                            className=" col-sm-9 customCenter mb-0 form-control"
                                            type="tel"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            onChange={(event) => {
                                                setPhoneNumber(event.target.value.replace(/\s+/g, ""));
                                            }}
                                            mask="05 999 999 99"
                                            value={phoneNumber}
                                            dir="ltr"
                                        ></InputMask>

                                    </FormGroup>

                                    <div className="form-group col-sm-9  mb-0 mt-3 customCenter">
                                        <Button
                                            color="primary"
                                            className="btn-block"
                                            type="submit"
                                            // onClick={() => history.push(`${process.env.PUBLIC_URL}/verification`)}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <div
                                                    style={{ minHeight: "25px" }}
                                                    className="w-100 d-flex align-items-center justify-content-center position-relative"
                                                >
                                                    <Loader
                                                        color="white"
                                                        size={20}
                                                        speedMultiplier={0.6}
                                                    />
                                                </div>
                                            ) : (
                                                t("registerButton")
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default withRouter(translate(CustomerRegister));