import React, { Fragment } from "react";
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { LoadingModal } from "../KPComponents/partials/LoadingModal";

const App = ({ children }) => {
  return (
    <Fragment>
      <LoadingModal />
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">{children}</div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default withRouter(App);
