import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.ACCOUNT_ENDPOINT;


export const getProfileInfo = async () => {
    const response = await Get(`${_endpoint}/Profile`, true);
    return response;
};
export const changeAccountPassword = async (requestBody) => {
    const response = await Post("api/v1/Account/ChangeAccountPassword", requestBody, true);
    return response;
};