import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.PRODUCT_ENDPOINT;


export const getAllProducts = async () => {
    const response = await Get(`${_endpoint}/GetAll`, true);
    return response;
};
export const getProduct = async (id) => {
    const response = await Get(`${_endpoint}/Get?id=${id}`, true);
    return response;
};

export const addProduct = async (requestBody) => {
    const response = await Post(`${_endpoint}/Create`, requestBody, true);
    return response;
};

export const updateProduct = async (requestBody) => {
    const response = await Post(`${_endpoint}/Update`, requestBody, true);
    return response;
};

export const deleteProduct = async (productId) => {
    const deleteData = {
        "id": productId
    }
    const response = await Post(`${_endpoint}/Delete`, deleteData, true);
    return response;
};
