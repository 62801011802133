import React from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,

    Table
} from 'reactstrap';
import NoData from '../../partials/NoData';
const MeasuringKitchen = (props) => {
    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <div className="table-responsive">
                            <Table size="sm">
                                <thead>
                                    <tr className='thead-dark'>
                                        <th scope="col">{"#"}</th>
                                        <th scope="col">{props.t("metresNumber")}</th>
                                        <th scope="col">{props.t("pricePerMetre")}</th>
                                        <th scope="col">{props.t("totalPrice")}</th>
                                    </tr>
                                </thead>
                                {(props.data.totalMeters === null || props.data.totalMeters === 0) ?
                                    (
                                        <tbody>
                                            <tr>
                                                <td colspan="4">
                                                    <NoData message={props.t("noData")} />
                                                </td>
                                            </tr>
                                        </tbody>)
                                    :
                                    (
                                        <tbody>
                                            <tr>
                                                <th scope="row" className="font-weight-bold">{1}</th>
                                                <td className="font-weight-bold">{props.data.totalMeters || props.t("notEntered")}</td>
                                                <td className="font-weight-bold">{props.data.meterPrice || props.t("notEntered")}</td>
                                                <td className="font-weight-bold">{props.data.totalMeterPrice || props.t("notEntered")}</td>
                                            </tr>

                                        </tbody>
                                    )
                                }
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default withRouter(translate(MeasuringKitchen));

