import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Nav,
    NavLink,
    NavItem,
    TabContent, TabPane
} from "reactstrap";
import NoData from '../partials/NoData';
import TimePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization';
import ImagesView from './ImagesView';
import CloseInstallingTicket from './orderActionsModals/CloseInstallingTicket';
const FactoringData = (props) => {
    const data = props.data;
    const [VerticalTab, setVerticalTab] = useState('1');
    // Modal
    const [isCloseInstallation, setIsCloseInstallation] = useState(false);
    const [ticketId, setTicketId] = useState("");
    const toggleCloseInstallation = () => setIsCloseInstallation(!isCloseInstallation);
    return (
        <Fragment>
            <CloseInstallingTicket
                isOpen={isCloseInstallation}
                toggleModal={toggleCloseInstallation}
                ticketId={ticketId}
                toggleRefreshData={props.toggleRefreshData}
            >
            </CloseInstallingTicket>

            <Container fluid={true}>
                <Row>
                    <Col sm="3" xs="12">
                        <Nav className="nav-dark flex-column nav-pills">
                            <NavItem>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '1' ? 'active' : ''}
                                    onClick={() => setVerticalTab('1')}                              >
                                    {props.t("basicInformation")}
                                </NavLink>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '2' ? 'active' : ''}
                                    onClick={() => setVerticalTab('2')}                              >
                                    {props.t("attachments")}
                                </NavLink>
                                <NavLink href="#javascript"
                                    className={VerticalTab === '3' ? 'active' : ''}
                                    onClick={() => setVerticalTab('3')}                           >
                                    {props.t("tickets")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm="9" xs="12">
                        <TabContent activeTab={VerticalTab} id="basicInformation">
                            <TabPane className="fade show" tabId="1">
                                <Card>
                                    <Form className="form theme-form">
                                        <CardBody>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-4 col-form-label">
                                                            {props.t("paymentStatus")}
                                                        </Label>
                                                        <Col sm="8">
                                                            {(data.paymentStatus === "Paid") ?
                                                                (
                                                                    <h5><span class="badge badge-success">
                                                                        <p style={{ fontSize: "10px" }}>
                                                                            {data.paymentStatusLocalized || props.t("notEntered")}
                                                                        </p>
                                                                    </span>
                                                                    </h5>

                                                                ) : (
                                                                    <h5><span class="badge badge-warning">
                                                                        <p style={{ fontSize: "10px" }}>
                                                                            {data.paymentStatusLocalized || props.t("notEntered")}
                                                                        </p>
                                                                    </span>
                                                                    </h5>
                                                                )}
                                                        </Col>

                                                    </FormGroup>

                                                    <FormGroup className="row">
                                                        <Label className="col-sm-4 col-form-label">
                                                            {props.t("installingTechnician")}
                                                        </Label>
                                                        <Col sm="8">
                                                            <Label className="col-form-label font-weight-bold">
                                                                {data.installingTechnician || props.t("notEntered")}
                                                            </Label>
                                                        </Col>
                                                    </FormGroup>

                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup className="row">
                                                        <Label className="col-sm-4 col-form-label">
                                                            {props.t("installingVisitTime")}
                                                        </Label>
                                                        <Col sm="8">
                                                            <Label className="col-form-label font-weight-bold">
                                                                {
                                                                    (data.installingVisitTimeLocalized !== null) ?
                                                                        (data.installingVisitTimeLocalized.substring(0, 5) + " " +
                                                                            TimePeriodLocalization(data.installingVisitTimeLocalized.substring(6, 8)))
                                                                        :
                                                                        (props.t("notEntered"))
                                                                }
                                                            </Label>
                                                        </Col>
                                                    </FormGroup>

                                                    <FormGroup className="row">
                                                        <Label className="col-sm-4 col-form-label">
                                                            {props.t("installingVisitDate")}
                                                        </Label>
                                                        <Col sm="8">
                                                            <Label className="col-form-label font-weight-bold">
                                                                {data.installingVisitDateLocalized || props.t("notEntered")}
                                                            </Label>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>

                                    </Form>
                                </Card>
                            </TabPane>
                        </TabContent>

                        <TabContent activeTab={VerticalTab} id="attachments">
                            <TabPane className="fade show" tabId="2">
                                <Card>
                                    <Form className="form theme-form">
                                        <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                            <h4> {props.t("kitchenAfterInstallingFiles")}</h4>
                                        </CardHeader>
                                        <ImagesView images={props.kitchenAfterInstallingFiles || []} />
                                    </Form>
                                </Card>
                                <Card>
                                    <Form className="form theme-form">
                                        <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                            <h4> {props.t("bill")}</h4>
                                        </CardHeader>
                                        <ImagesView images={props.bill || []} />
                                    </Form>
                                </Card>
                            </TabPane>
                        </TabContent>
                        <TabContent activeTab={VerticalTab} id="tickets">
                            <TabPane className="fade show" tabId="3">
                                {
                                    (data.tickets.length > 0) ?
                                        (

                                            (data.tickets.map((ticket, index) =>
                                                <Container fluid={true}>
                                                    <Row >
                                                        <Col sm="12" >
                                                            <Card>
                                                                <Form className="form theme-form">
                                                                    <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                                                        <h4>{props.t("ticket")} # {index + 1}</h4>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col sm="6">
                                                                                <FormGroup className="row">
                                                                                    <Label className="col-sm-4 col-form-label">
                                                                                        {props.t("orderTicketStatus")}
                                                                                    </Label>
                                                                                    <Col sm="8">
                                                                                        {(ticket.orderTicketStatus === "Pending") ?
                                                                                            (
                                                                                                <h5><span class="badge badge-warning">
                                                                                                    <p style={{ fontSize: "10px" }}>
                                                                                                        {ticket.orderTicketStatusLocalized || props.t("notEntered")}
                                                                                                    </p>
                                                                                                </span>
                                                                                                </h5>

                                                                                            ) : (
                                                                                                <h5><span class="badge badge-success">
                                                                                                    <p style={{ fontSize: "10px" }}>
                                                                                                        {ticket.orderTicketStatusLocalized || props.t("notEntered")}
                                                                                                    </p>
                                                                                                </span>
                                                                                                </h5>
                                                                                            )}
                                                                                    </Col>
                                                                                </FormGroup>
                                                                                <FormGroup className="row">
                                                                                    <Label className="col-sm-4 col-form-label">
                                                                                        {props.t("installingIssueDetails")}
                                                                                    </Label>
                                                                                    <Col sm="8">
                                                                                        <Label className="col-form-label font-weight-bold">
                                                                                            {ticket.installingIssueDetails || props.t("notEntered")}
                                                                                        </Label>
                                                                                    </Col>
                                                                                </FormGroup>
                                                                                <FormGroup className="row">
                                                                                    <Label className="col-sm-3 col-form-label">
                                                                                        {props.t("issueImages")}
                                                                                    </Label>
                                                                                    <Col sm="9">
                                                                                        <ImagesView images={ticket.issueImages} />
                                                                                    </Col>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <FormGroup className="row">
                                                                                    <Label className="col-sm-4 col-form-label">
                                                                                        {props.t("issueCreatedBy")}
                                                                                    </Label>
                                                                                    <Col sm="8">
                                                                                        <Label className="col-form-label font-weight-bold">
                                                                                            {ticket.createdBy || props.t("notEntered")}
                                                                                        </Label>
                                                                                    </Col>
                                                                                </FormGroup>
                                                                                <FormGroup className="row">
                                                                                    <Label className="col-sm-4 col-form-label">
                                                                                        {props.t("issueCreatedOn")}
                                                                                    </Label>
                                                                                    <Col sm="8">
                                                                                        <Label className="col-form-label font-weight-bold">
                                                                                            {(ticket.createdOn !== null) ?
                                                                                                (ticket.createdOn.substring(0, ticket.createdOn.length - 3) + " " +
                                                                                                    TimePeriodLocalization(ticket.createdOn.substring(ticket.createdOn.length - 2, ticket.createdOn.length))
                                                                                                )
                                                                                                :
                                                                                                (props.t("notEntered")
                                                                                                )}
                                                                                        </Label>

                                                                                    </Col>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                    {(ticket.orderTicketStatus === "Pending") ?
                                                                        (
                                                                            <CardFooter className="submit-footer">

                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        toggleCloseInstallation();
                                                                                        setTicketId(ticket.id);
                                                                                    }}
                                                                                >
                                                                                    {props.t("closeTicket")}
                                                                                </Button>


                                                                            </CardFooter>
                                                                        ) : (
                                                                            <div></div>
                                                                        )}

                                                                </Form>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            )))
                                        :
                                        (
                                            <Card>
                                                <NoData message={props.t("noData")} />
                                            </Card>
                                        )
                                }
                            </TabPane>
                        </TabContent>

                    </Col>
                </Row>
            </Container >
        </Fragment >
    )
}

export default withRouter(translate(FactoringData));