import React from 'react'

export const tableData = [
    {
        id: "1",
        name: "Product Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "2",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "3",
        name: "Subcategory Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "4",
        name: "Sales  Menu",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "5",
        name: "Vendor Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "6",
        name: "Product Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "7",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "8",
        name: "Subcategory Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "9",
        name: "Sales  Menu",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "11",
        name: "Vendor Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "111",
        name: "Product Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "1111",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "22",
        name: "Subcategory Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "222",
        name: "Sales  Menu",
        status: <i className="fa fa-circle font-danger f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "33",
        name: "Vendor Menu",
        status: <i className="fa fa-circle font-success f-12" />,
        creat_on: "2018-04-18T00:00:00"
    },
    {
        id: "44",
        name: "Category Menu",
        status: <i className="fa fa-circle font-warning f-12" />,
        creat_on: "2018-04-18T00:00:00"
    }
]





