import React from "react";
import { Button, Col } from "reactstrap";
import { t } from "react-switch-lang";
import { useHistory } from "react-router-dom";

const MaintenanceRequestCard = ({ request }) => {
  const history = useHistory();
  const handleClick = (id) => {
    history.push(`${process.env.PUBLIC_URL}/customer/maintenance/${id}`);
  };
  return (
    <>
      <Col
        sm="12"
        lg="5"
        className="project-box theme-background d-flex flex-column align-items-center"
      >
        <p className="up-text">{t("status")}</p>
        <p className="header request-status"><span className={`${request.requestStatus}`}>{request.requestStatusLocalized}</span></p>
        <div className="info">
          <div className="info-group">
            <p className="title">{t("contractNumber")}</p>
            <p className="value">{request.contractNumber}</p>
          </div>
          <div className="info-group">
            <p className="title">{request.createdOn.substring(0, 11)}</p>
            <p className="title">
              {request.createdOn.substring(
                11,
                request.createdOn.length - 2
              ) +
                " " +
                t(
                  `${request.createdOn.substring(
                    request.createdOn.length - 2,
                    request.createdOn.length
                  )}`
                )}
            </p>
          </div>
        </div>
        <Button color="light" onClick={() => handleClick(request.id)}>
          {t("details")}
        </Button>
      </Col>
    </>
  );
};

export default MaintenanceRequestCard;
