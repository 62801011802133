import React, { useEffect, useState } from "react";
import { translate } from "react-switch-lang";
import { Input, Table } from "reactstrap";
import environment from "../../../environment";

const MetersTable = ({
  t,
  setPropValue = () => {},
  propValue = { quantity: 0, price: 0 },
  setIsValid = () => {},
}) => {
  const [tableData, setTableData] = useState(propValue);
  const numberFormat = new Intl.NumberFormat();

  useEffect(() => {
    setIsValid(tableData.quantity > 0 && tableData.price > 0);
  }, [tableData, setIsValid]);

  useEffect(() => {
    setTableData(propValue);
  }, [propValue]);

  const handleQuantityChange = (value) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setPropValue((prev) => ({
        ...prev,
        quantity: value === "" ? 0 : value,
      }));
      setTableData(() => {
        const newTableData = {
          price: tableData.price,
          quantity: value === "" ? 0 : value,
        };
        return { ...newTableData };
      });
    }
  };

  const handlePriceChange = (value) => {
    if (!value || value.match(environment.NUMBERS_WITH_TWO_DECIMALS_REGEX)) {
      setPropValue((prev) => ({
        ...prev,
        price: value === "" ? 0 : value,
      }));
      setTableData((prev) => ({
        ...prev,
        price: value === "" ? 0 : value,
      }));
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <td className="col-4 label-required">{t("quantity")}</td>
          <td className="col-4 label-required">{t("price")}</td>
          <td className="col-4">{t("total")}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="col-4">
            <Input
              type="text"
              value={tableData.quantity ?? 0}
              name="meters-quantity"
              onChange={(event) => {
                handleQuantityChange(event.target.value);
              }}
            ></Input>
          </td>
          <td className="col-4">
            <Input
              type="text"
              value={tableData.price ?? 0}
              name="meters-price"
              onChange={(event) => {
                handlePriceChange(event.target.value);
              }}
            ></Input>
          </td>
          <td className="col-4">
            <Input
              type="text"
              disabled
              value={`${numberFormat.format(
                tableData.price * tableData.quantity
              )} ${t("currency")}`}
            ></Input>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default translate(MetersTable);
