import React from "react";
import { ClipLoader, MoonLoader } from "react-spinners";
const index = ({ loadingMessage = "", table = false, modal = false }) => {
  return table ? (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "20vh", backgroundColor: "var(--theme-background)" }}
    >
      <ClipLoader
        margin={"1rem"}
        color="var(--theme-default)"
        size={50}
        speedMultiplier={0.6}
      ></ClipLoader>
    </div>
  ) : (
    <div
      className="mx-auto d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "30vh" }}
    >
      <MoonLoader
        margin={"1rem"}
        color="var(--theme-default)"
        size={modal ? 40 : 70}
        speedMultiplier={0.6}
      ></MoonLoader>
      <p
        style={{
          textAlign: "center",
          fontSize: "1.2rem",
          color: "var(--secondary)",
          marginTop: "2rem",
        }}
      >
        {loadingMessage}
      </p>
    </div>
  );
};

export default index;
