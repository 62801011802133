import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import Loader from "react-spinners/ClipLoader";
import { updateProfile } from "../../services/accountPublic.service";
const CustomerData = ({ t, history }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const requestBody = {
      firstName: firstName,
      sureName: lastName,
    };
    (async () => {
      const { ok } = await updateProfile(requestBody);
      if (ok) {
        history.push(`${process.env.PUBLIC_URL}/order/check`)
      }
    })();
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main login-tab ">
                <div className=" text-center">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/logo/login.png")}
                    alt=""
                    style={{ width: "40%" }}
                  />
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/logo/secondLogo.jpeg")}
                    alt=""
                    style={{ width: "40%" }}
                  />
                </div>
                <Form className="theme-form mt-4" onSubmit={handleSubmit}>
                  <h4 className="text-center">{t("customerData")}</h4>
                  <p className="text-center">{t("enterCustomerData")}</p>
                  <FormGroup>
                    <Label className="col-form-label">{t("name")}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      onChange={(e) => setFirstName(e.target.value)}
                      defaultValue={firstName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{t("lastName")}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      onChange={(e) => setLastName(e.target.value)}
                      defaultValue={lastName}
                    />
                  </FormGroup>

                  <div className="form-group col-sm-9  customCenter mb-0 mt-5">
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      // onClick={() => history.push(`/customerHome`)}
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          style={{ minHeight: "25px" }}
                          className="w-100 d-flex align-items-center justify-content-center position-relative"
                        >
                          <Loader
                            color="white"
                            size={20}
                            speedMultiplier={0.6}
                          />
                        </div>
                      ) : (
                        t("completeRegistration")
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(translate(CustomerData));
