import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.ROLE_ENDPOINT;


export const getAllRoles = async (id) => {
    const response = await Get(`${_endpoint}/GetAll`, true);
    return response;
};
export const search = async (requestBody) => {
    const response = await Post(`${_endpoint}/Search`, requestBody, true);
    return response;
};
export const getRole = async (id) => {
    const response = await Get(`${_endpoint}/Get?id=${id}`, true);
    return response;
};
export const deleteRole = async (productId) => {
    const deleteData = {
        "id": productId
    }
    const response = await Post(`${_endpoint}/Delete`, deleteData, true);
    return response;
};
export const updateRole = async (requestBody) => {
    const response = await Post(`${_endpoint}/Update`, requestBody, true);
    return response;
};
export const addRole = async (requestBody) => {
    const response = await Post(`${_endpoint}/Create`, requestBody, true);
    return response;
};
export const setRolesClaims = async (claimsRoles) => {

    let data = {
        claimsRoles
    }
    // console.log(test)
    const response = await Post(`${_endpoint}/SetRolesClaims`, data, true);
    return response;
};



