import React from "react";

const DisplayTokens = ({ classNames = "", tags = [] }) => {
  const onClickCopy = (tag) => {
    navigator.clipboard.writeText(tag);
  };
  return (
    <div className={classNames}>
      {tags.map((tag, index) => (
        <span
          key={`tag-${index}`}
          className="tag"
          onClick={() => onClickCopy(tag)}
        >
          {tag}
          <i className="fa fa-clipboard"></i>
        </span>
      ))}
    </div>
  );
};

export default DisplayTokens;
