import React, { useEffect, useRef, useState } from "react";
import { translate } from "react-switch-lang";
import { useParams, withRouter } from "react-router-dom";
import {
    Button,
    Card,
    CardFooter,
    Container,
    FormGroup,
    Label,
} from "reactstrap";

import { QRCodeSVG } from 'qrcode.react'

import ReactToPrint, { useReactToPrint } from "react-to-print";

import './Card.css'
import { getOrder } from "../../../services/order.service";
import Breadcrumb from "../../../layout/breadcrumb";
import Loader from "../../partials/Loader";



const PrintCard = ({
    isOpen,
    toggleModal = () => { },
    t,
    toggleRefreshData = () => { },
    history
}) => {
    const [loading, setLoading] = useState(true);
    const [orderDetailsData, setOrderDetailsData] = useState([]);
    const componentRef = useRef();
    const { orderId } = useParams();
    const direction = localStorage.getItem("dir")
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    const getData = () => {
        (async () => {
            setLoading(true);
            const { body, ok } = await getOrder(orderId);
            if (ok) {
                console.log(body);
                setOrderDetailsData(body);
            }
            setLoading(false);
        })();
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("lang")]);
    const ComponentToPrintEdited = React.forwardRef((props, ref) => (
        <div
            className="test"
        >
            <div
                dir={direction}
                ref={ref}
                className="print-card">

                <div className="top">
                    <div className={direction === 'rtl' ? "title" : "title-ltr"}>
                        <h5 className="text-center font-weight-bold">{t("orderCard")}</h5>
                    </div>
                    <div className={direction === 'rtl' ? "contract-number" : "contract-number-ltr"} >
                        <p id="number">#{orderDetailsData?.orderCode}</p>
                        <h5 id={direction === 'rtl' ? "number-title" : "number-title-ltr"} >{t('contractNumber')}</h5>

                    </div>
                </div>
                <div
                    className={direction === 'rtl' ? "bottom" : "bottom-ltr"}
                >
                    <div className={direction === 'rtl' ? "first-col" : "first-col-ltr"}>
                        <FormGroup className="row m-0" >
                            <Label className="col-form-label" id="information">
                                {t("client")}
                                <span> </span>
                                :
                                <span> </span>
                                {orderDetailsData?.client?.firstName} {orderDetailsData?.client?.sureName}
                            </Label>
                        </FormGroup>
                        <FormGroup className="row m-0">
                            <Label className="col-form-label" id="information">
                                {t("mobileNumber")}
                                <span> </span>:
                                <span> </span>
                                {orderDetailsData?.client?.phoneNumber}
                            </Label>
                        </FormGroup>
                        <FormGroup className="row m-0">
                            <Label className="col-form-label" id="information">
                                <i className="fa fa-map-marker"></i>
                                <span> </span>
                                {orderDetailsData?.address}
                            </Label>
                        </FormGroup>
                    </div>
                    <div className="second-col">
                        <div className={direction === 'rtl' ? "qrCode" : "qrCode-ltr"}>
                            <QRCodeSVG
                                value="https://irsina.sa/cuba/order/check"
                                width="100"
                            />
                        </div>
                        <h5 className="text-center"
                            id={direction === 'rtl' ? "QrCode-title" : "QrCode-title-ltr"}
                        >{t("maintenanceQrCode")}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    ));
    return (
        <>
            <Breadcrumb parent={t("orderDetails")} title={t("orderCard")} backTo="true" />

            {!loading ?
                (
                    <Container fluid={true}
                    >
                        <Card>

                            <ReactToPrint
                                copyStyles={true}
                                // trigger={() => <a href="#">Print this out!</a>}
                                content={() => componentRef.current}
                            />
                            <ComponentToPrintEdited ref={componentRef} />

                            <CardFooter className="submit-footer">
                                <Button color="primary"
                                    className="ml-1 mr-1"
                                    // onClick={printDocument}>
                                    onClick={handlePrint}>
                                    {t("print")}
                                </Button>

                                <Button color="light"
                                    className="ml-1 mr-1"
                                    onClick={
                                        () => history.push(`${process.env.PUBLIC_URL}/orders/tabs/${orderId}`)
                                    } type="button">
                                    {t("cancel")}
                                </Button>
                            </CardFooter>
                        </Card>
                    </Container>
                ) : (
                    <Card>
                        <Loader />
                    </Card>
                )
            }

        </>
    );
};
export default withRouter(translate(PrintCard));
