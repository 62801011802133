import { createSlice } from "@reduxjs/toolkit";
import { t } from "react-switch-lang";

const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    status: false,
    message: "Loading...",
  },
  reducers: {
    setGlobalLoading: (state, { payload }) => {
      state.status = payload.status;
      state.message = t("pleaseWait");
    },
  },
});

export const { setGlobalLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
