import React from 'react'
import { translate } from 'react-switch-lang';
const TestKP = (props) => {
    const claims = JSON.parse(localStorage.getItem("claims"));
    console.log(claims);
    return (
        <div>
            {claims[0] === 'Users.Add' ?
                <h1>ahamd</h1>
                :
                <h1>Adel</h1>
            }
            {claims[1]}
            <h1>{props.t("kitchenProject")}</h1>
            <p>{props.t("KitchenPara")}</p>        </div>
    )
}
export default translate(TestKP); 
