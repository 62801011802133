import React from 'react'
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Row,
    Col,
    FormGroup,
    Label,
} from 'reactstrap';

import TimePeriodLocalization from '../partials/TimePeriodLocalization/TimePeriodLocalization';

const BasicInformation = (props) => {
    const data = props.data;
    const clientData = props.clientData || {};

    let statusComponent;
    if (data.orderStatus === 'Rejected')
        statusComponent =
            <h5><span class="badge badge-danger">
                <p style={{ fontSize: "10px" }}>
                    {data.orderStatusLocalized || props.t("notEntered")}
                </p>
            </span>
            </h5>

    else if (data.orderStatus === 'UpdatedRequest ' || data.orderStatus === 'InstallingProcessing')
        statusComponent =
            <h5><span class="badge badge-warning">
                <p style={{ fontSize: "10px" }}>
                    {data.orderStatusLocalized || props.t("notEntered")}
                </p>
            </span>
            </h5>
    else statusComponent =
        <h5><span class="badge badge-success">
            <p style={{ fontSize: "10px" }}>
                {data.orderStatusLocalized || props.t("notEntered")}
            </p>
        </span>
        </h5>

    return (
        <div>
            <Row >
                <Col sm="6">
                    <FormGroup className="row ">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("orderStatus")}: </Label>
                        <Col sm="8 d-flex">
                            {statusComponent}
                        </Col>

                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("createdBy")}
                        </Label>
                        <Col sm="8">
                            <Label className="col-form-label font-weight-bold">
                                {data.branchManager || props.t("notEntered")}
                            </Label>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("orderCode")}
                        </Label>
                        <Col sm="8">
                            <Label className="col-form-label font-weight-bold">
                                {data.orderCode || props.t("notEntered")}
                            </Label>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("customerName")}
                        </Label>
                        <Col sm="8">
                            <Label className="col-form-label font-weight-bold">
                                {clientData.firstName + " " + clientData.sureName || props.t("notEntered")}
                            </Label>
                        </Col>
                    </FormGroup>
                    {
                        (data.orderStatus === 'Rejected') ?
                            (
                                <FormGroup className="row ">
                                    <Label className="col-sm-4 col-form-label">
                                        {props.t("rejectionReason")} </Label>
                                    <Col sm="8 d-flex">
                                        <Label className="col-form-label font-weight-bold text-danger">
                                            {data.rejectionReason || props.t("notEntered")}
                                        </Label>
                                    </Col>

                                </FormGroup>
                            )
                            :
                            (<div></div>)
                    }
                </Col>
                <Col sm="6">
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("orderDate")}
                        </Label>
                        <Col sm="8">
                            <Label className="col-form-label font-weight-bold">
                                {(data.orderDate !== null) ?
                                    (data.orderDate.substring(0, data.orderDate.length - 3) + " " +
                                        TimePeriodLocalization(data.orderDate.substring(data.orderDate.length - 2, data.orderDate.length))
                                    )
                                    :
                                    (props.t("notEntered")
                                    )}
                            </Label>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label">
                            {props.t("phoneNumber")}
                        </Label>
                        <Col sm="8">
                            <Label className="col-form-label font-weight-bold ">
                                {clientData.phoneNumber || props.t("notEntered")}
                            </Label>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(translate(BasicInformation));