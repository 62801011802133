import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { translate } from "react-switch-lang";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  getMessageTemplateById,
  updateMessageTemplate,
} from "../../services/message-template.service";
import Loader from "../partials/Loader";
import { DisplayTokens } from "../partials/displayTokens";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import { setMessageTemplateIdToEdit } from "../../store/slices/messageTemplateSlice";
import { toast } from "react-toastify";

const EditMessageTemplate = ({
  isOpen = false,
  toggleModal = () => {},
  toggleRefreshData = () => {},
  languages = [],
  t,
}) => {
  const [messageTemplate, setMessageTemplate] = useState({});
  const [tags, setTags] = useState([]);

  // UI/UX
  const [loading, setLoading] = useState(false);

  // Store
  const dispatch = useDispatch();
  const id = useSelector((state) => state.messageTemplate.id);

  let bodyForm;
  if (languages.length > 0) {
    if (Object.keys(messageTemplate).length > 0) {
      languages.forEach((language) => {
        const value = messageTemplate.translations.filter(
          (translation) => translation.languageId === language.id
        )[0];

        language.value = value ? value.body : "";
      });
      bodyForm = languages.map((language) => {
        return (
          <FormGroup
            className="row d-flex align-items-center"
            key={language.culture}
          >
            <Label
              htmlFor={`body_${language.culture}`}
              className="col-4 mb-0"
            >{`${t("messageBody")} (${language.culture.toUpperCase()})`}</Label>
            <Input
              type="textarea"
              className="col-6 mb-0"
              dir={language.culture === "ar" ? "rtl" : "ltr"}
              name={`body_${language.culture}`}
              defaultValue={language.value}
              required
              style={{ fontSize: "0.8rem" }}
            />
          </FormGroup>
        );
      });
    }
  } else {
    bodyForm = <></>;
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      (async () => {
        const { body, ok } = await getMessageTemplateById(id);
        if (ok) {
          setMessageTemplate(body);
          setTags(
            body.allowedTokens.replace("\r\n", "").length > 0
              ? body.allowedTokens.split(",")
              : []
          );
        }
        setLoading(false);
      })();
    }
  }, [id]);

  const editMessageTemplate = (event) => {
    event.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));

    const translations = languages.map((language, index) => ({
      body: event.target[index].value,
      languageId: language.id,
    }));

    const requestBody = {
      id,
      translations,
      isActive: true,
    };

    (async () => {
      const { ok } = await updateMessageTemplate(requestBody);
      if (ok) {
        toast.success(t("messageTemplateEditedSuccess"));
      }
      toggleRefreshData();
      dispatch(setGlobalLoading({ status: false }));
      dispatch(setMessageTemplateIdToEdit({ id: null }));
    })();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      centered
      className="big-modal"
      scrollable
    >
      <ModalHeader>{t("editMessageTemplate")}</ModalHeader>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={editMessageTemplate}>
          <ModalBody>
            <FormGroup className="row d-flex align-items-center">
              <Label className="col-4 mb-0">{t("name")}</Label>
              <p className="col-6 mb-0">{messageTemplate.localizedName}</p>
            </FormGroup>
            {bodyForm}
            {tags.length > 0 && (
              <FormGroup className="row d-flex align-items-center">
                <Label className="col-4 mb-0">{t("tagsToUse")}</Label>
                <DisplayTokens
                  tags={tags}
                  classNames="col-6 mb-0 tags-container"
                />
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" type="submit">
              {t("save")}
            </Button>
            <Button color="light" onClick={toggleModal} type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default translate(EditMessageTemplate);
